

export class saveEmployeeRq
{

    public id?:any;
    public fullName?:any;
    public department?:any;
    public role?:string;
    public doj?:any;
    public createdDt?:any;
    public updatedDt?:any;
    public supervisorId?:any;
    public createdBy?:any;
    public emailId?:any;   
    public userName?:any;
    public password?:any;
    public supervisorID?:any;
    public departmentId :any;
    departmentID? :any;
}
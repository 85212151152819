import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { constants } from '../common/constants';
import Swal from 'sweetalert2';
import { SupervisorActionService } from '../services/supervisorAction.service';
import { TimeEntryService } from '../services/timeentry.service';
import { supervisorActionRequest } from '../businessObjects/supervisorActionRequest';
import { supervisorActionResponse } from '../businessObjects/supervisorActionResponse';
import { EmployeeService } from '../services/employee.service';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
export interface Post {
  id: number;
  title: string;
  body: string;
  userId: number;
}
@Component({
  selector: 'app-timesheet-supervisor',
  templateUrl: './timesheet-supervisor.component.html',
  styleUrls: ['./timesheet-supervisor.component.css']
})

export class TimesheetSupervisorComponent implements OnInit {
  
  public startDate:any;
  public suprVRes: superVisorRes[];
  displayedColumns: string[] = ['empid', 'name', 'project','task','sun','mon','tue','wed','thu','fri','sat'];
  dataSource = new MatTableDataSource<Post>([]);
  public envConstants= environment;
  public appConstants = constants;
  public getTimeSheetRes: getTimeSheetEntryResponse[];
  public selectedWeek:any;
  public dateStart:any;
  public dateEnd:any;
  public recordsExist:boolean;
  public toastRef:any;
  public eventhover:any;
  public eventid:any;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor(private http: HttpClient,private supervisorActionService: SupervisorActionService,private timeEntryService:TimeEntryService, private emplservice: EmployeeService,
    private toastr: ToastrService ) { }
  ngOnInit(): void {
    $( ".hamburger" ).click();
    var startDate,
    endDate;
    var startDt:'';
    var endDt:'';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getEmployees();
    this.getTimeSheetRes=[

    ];

    var startDate,
    endDate;
    $('#weekpicker1').datepicker({
      autoclose: true,
      format :'mm/dd/yyyy',
      forceParse :false
  }).on("changeDate", (e:any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+6);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      this.recordsExist=false;
      this.getDailyTimeSheetEntries();
      
  });


  }
  handleMouseOver(event:any,day:number)
  {
   if(!(this.eventhover==event && this.eventid==day))
   {
    if(this.toastRef)
    {
      this.toastr.clear(this.toastRef.ToastId);
    }
    let value:any=event.remarksList[day];
    if(value)
    {
      this.toastRef=this.toastr.info(value, ' Comments',{ timeOut: 2000});
     $("#toast-container").css( {position:"absolute", top:'17%', right: '29%'});
    }
     
    
  }
    this.eventhover=event;
    this.eventid=day;
  }
  setDefaultWeek() {
     let dateToday = new Date();
     let startDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - dateToday.getDay());
     let  endDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - dateToday.getDay()+6);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      this.getDailyTimeSheetEntries();
      
  }
  ngViewAfterInit(): void {
    
    var startDate,
    endDate;
    $('#weekpicker1').datepicker({
      autoclose: true,
      format :'mm/dd/yyyy',
      forceParse :false
  }).on("changeDate", (e:any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+6);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      this.getDailyTimeSheetEntries();
  });
//test to intialize subtassks

  }
  public approveOrRejectRes:supervisorActionResponse=
  {
    errorCode:'',
    isSuccess:false,
    message:''
  }
  public approveOrRejectReq:supervisorActionRequest=
  {
    employeeID:0,
    endDate:'',
    startDate:'',
    remarks:'',
    actionStatus:0
  }
  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID:'',
    endDate:'',
    startDate:'',
    status:'',
    manangerID:''
  }

  public getTimeEntryObject:getTimeSheetEntryResponse={
    employeeId:0,
    endDate:'',
    taskName:'',
    id:0,
    message:'',
    projectId:0,
    remarks:'',
    errorCode:'',
    isSuccess:false,
    startDate:'',
    status:0,
    subTaskId:0,
    tasks:0,
    employeeName:'',
    projectName:'',
    dayList:[],
    remarksList:[],
    costCenter:''
  }
  
  fetchPosts(): void {
    this.http.get('https://jsonplaceholder.typicode.com/posts').subscribe((data) => {
      this.dataSource.data = data as Post[];
    })

    
   

    
    var startDate,
        endDate;
        
      $('#weekpicker').datepicker({
        autoclose: true,
        format :'mm/dd/yyyy',
        forceParse :false
    }).on("changeDate", function(e:any) {
        var date = e.date;
        startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
        endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+6);
        $('#weekpicker').datepicker('update', startDate);
        $('#weekpicker').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
        
    });
    
        
        
        //new

  }

  
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  next(val:any)
{
  if(val==1)
  {
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
     startDate.setDate(startDate.getDate()-7)
     endDate.setDate(endDate.getDate()-7)
     //$('#weekpicker').datepicker('update', startDate); 
     $('#weekpicker').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
     this.getDailyTimeSheetEntries();
  }
  if(val==2)
  {
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
     startDate.setDate(startDate.getDate()+7)
     endDate.setDate(endDate.getDate()+7)
     //$('#weekpicker').datepicker('update', startDate); 
     $('#weekpicker').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
     this.getDailyTimeSheetEntries();
  }

}
  onChangeProject(event:any,id:number)
  {
    const value = event.target.value;

  }
  getDailyTimeSheetEntries() {
    this.getDateValue();
    this.getTimeSheetRes=[];
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
    let errorMessages = this.appConstants.errorMessages;
    //this.getTimeSheetReq.employeeID=98;
    this.getTimeSheetReq.startDate=this.dateStart;
    this.getTimeSheetReq.endDate=this.dateEnd;
    this.getTimeSheetReq.status=constants.status.SentforApproval;
    this.getTimeSheetReq.manangerID=0// get the value from sess
    console.log(`Calling the ${apiUrl} api...`);
    //api call

    this.timeEntryService.getTimeSheetEntries(apiUrl,this.getTimeSheetReq)
    .subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
    this.getTimeSheetRes=(getTimeSheetReponse);
    if(this.getTimeSheetRes.length>0)
    {
      this.recordsExist=true;
    }

  });
}

getDateValue()
{
  this.selectedWeek=$('#weekpicker1').val();
  var startDt:'';
  var endDt:'';
  if(this.selectedWeek)
  {
    const chars = this.selectedWeek.split('-');
    startDt=chars[0];
    let firstDate = new Date(startDt)
    endDt=chars[1];
    let secondDate = new Date(endDt)
    let startMonth=(firstDate.getMonth()+1).toString(),endMonth=(secondDate.getMonth()+1).toString()
    ,startDay=firstDate.getDate().toString(),EndDay=secondDate.getDate().toString()
    if(((firstDate.getMonth().toString()).length)==1)
    {
      startMonth="0"+(firstDate.getMonth()+1).toString()
    }
    if(((secondDate.getMonth().toString()).length)==1)
    {
      endMonth="0"+(secondDate.getMonth()+1).toString()
    }
    if(startDay.length==1)
    {
      startDay="0"+(firstDate.getDate()+1).toString()
    }
    if(EndDay.length==1)
    {
      EndDay="0"+(secondDate.getDate()+1).toString()
    }
    this.dateStart=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
    this.dateEnd=secondDate.getFullYear()+'-'+endMonth+'-'+EndDay;
  }
}

getEmployees() {
    
  let apiUrl = this.envConstants.apiUrl + this.envConstants.getEmployeeBySupervisorId;
  let supervisorID=localStorage.getItem("userID");
  this.emplservice.getSuperVisorList(apiUrl,supervisorID)
        .subscribe((SuprVRes: superVisorRes[]) => {
          this.suprVRes = SuprVRes;      
   });
  //this.setDefaultWeek();
}


approve(value:number)
{
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to approve this!",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, approve it!'
  }).then((result) => {
    if (result.isConfirmed) {
      let apiName = this.envConstants.approveOrRejectTE;
      let apiUrl = this.envConstants.apiUrl + apiName;
      this.approveOrRejectReq.employeeID=93;
      this.approveOrRejectReq.actionStatus=constants.status.Approved;
      this.approveOrRejectReq.weekId=this.getTimeSheetRes[0].weekId;
      let errorMessages = this.appConstants.errorMessages;
  console.log(`Calling the ${apiUrl} api... with payload: ${this.approveOrRejectReq} ` );
    
    //api call
    this.supervisorActionService.updateSupervisorAction(apiUrl, this.approveOrRejectReq)
    .subscribe((supervisorActionResponse) => {  
      if(supervisorActionResponse.isSuccess)
      {
        Swal.fire({
          icon: 'success',
          title: 'Time sheet has been approved successfully',
          showConfirmButton: false,
          timer: 1500
        })
      }
      this.getTimeSheetRes=[];
    },
    (error) => {
   
     console.error("An error has occurred in approve timesheet api call: ", error);
     alert(error.error.errorDesc);
     return 
   }); 
   
    }
  })
  
}
 async reject(value:number)
{
  const { value: text } = await Swal.fire({
    input: 'textarea',
    inputLabel: 'Rejection Comments',
    inputPlaceholder: 'Type your comments here...',
    inputAttributes: {
      'aria-label': 'Type your comments here'
    },
    showCancelButton: true
  })
  
  if (text) {
      let apiName = this.envConstants.approveOrRejectTE;
      let apiUrl = this.envConstants.apiUrl + apiName;
      this.approveOrRejectReq.employeeID=93;
      this.approveOrRejectReq.weekId=this.getTimeSheetRes[0].weekId;
      this.approveOrRejectReq.remarks=text;
      this.approveOrRejectReq.actionStatus=constants.status.Rejected;
      let errorMessages = this.appConstants.errorMessages;
      console.log(`Calling the ${apiUrl} api... with payload: ${this.approveOrRejectReq} ` );
        
        //api call
        this.supervisorActionService.updateSupervisorAction(apiUrl, this.approveOrRejectReq)
        .subscribe((supervisorActionResponse) => {  
          if(supervisorActionResponse.isSuccess)
          {
            Swal.fire({
              icon: 'success',
              title: 'Time sheet has been rejected sucessfully',
              showConfirmButton: false,
              timer: 1500
            })
            this.getTimeSheetRes=[];
          }
        },
        (error) => {
       
         console.error("An error has occurred in rejection timesheet api call: ", error);
         alert(error.error.errorDesc);
         return 
       }); 
    }
  }
}



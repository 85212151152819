<div class="container" style="max-width: 100%;">   
   <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Approval Request List History</h3>
      <div class="row">
        <div class="col-md-3">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. esse" #input>
    </mat-form-field>
        </div>
        <div class="col-md-4">          
            <span class="icon-block ">
              <label class="control-label" for="weekpicker3">Select Week: <span>&nbsp;</span></label>
              <input type="text"  id="weekpicker3" class="md-form md-outline input-with-post-icon datepicker">
              <span class="icon-date"></span>
            </span>
            <div class="week-controls col-md-2"></div>
      </div>
     </div>
     <div class="row" style="width: 100%;">  
    <table mat-table [dataSource]="getTimeSheetRes" class="mat-elevation-z12" matSort style="width: 100%;">
    
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Project</b></th>
        <td mat-cell *matCellDef="let element"> {{element.projectName | slice: 0:30}} </td>
      </ng-container>
    
      <!-- Body Column -->
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef> <b>Task</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.taskName | slice: 0:50}} </td>
      </ng-container>
    
      <!-- UserId Column -->
      <ng-container matColumnDef="mon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Mon</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[0]}} </td>
      </ng-container>

       <!-- UserId Column -->
       <ng-container matColumnDef="tue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Tue</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[1]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="wed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Wed</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[2]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="thu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Thu</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[3]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="fri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Fri</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[4]}} </td>
      </ng-container>
         <!-- UserId Column -->
         <ng-container matColumnDef="sat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Sat</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.dayList[5]}} </td>
          </ng-container>
             <!-- UserId Column -->
       <ng-container matColumnDef="sun">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Sun</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[6]}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > <b>Status</b> </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.status == 3 ||  element.status == 4" [ngStyle]="{'background-color': element.status == 3 ? 'green':'red'}">
          {{element.status==3 ? 'Approved':'Rejected'}} </span>
          <span *ngIf="element.status == 1 ||  element.status == 2" [ngStyle]="{'background-color': element.status == 1 ? 'orange':'purple'}">
            {{element.status==1 ? 'Saved':'Sent for Approvals'}} </span>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
     </div>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
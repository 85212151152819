export const constants = {
    timeOutMin: 24000000,
    status:{
        New:0,
        SaveasDraft:1,
        SentforApproval:2,
        Approved:3,
        Rejected:4
    },
    roles:{
        Manager:"Manager",
        Supervisor:"Supervisor",
        Admin:"Admin",
        User:"User"
    }, 
     MsalLoginValues : {
      clientId:'0db55ba9-ad8b-412d-9905-24401994ff76',
      redirectUri:'https://timesheet.purecs.ae/',
      authority:'https://login.microsoftonline.com/4bcf00c4-82e4-4368-8fb8-443737544098',
      postLogoutRedirectUri:'https://timesheet.purecs.ae/',
      scopes: [
        "user.read" 
    ],
    api:'https://timesheetapi.purecs.ae/api/'

    },
    errorCodes: {
   
     enteredOTPReq: '',
     unableToVerifyOTP: '',
     unableToFetchEmiratesIdDetails: '',
     unExpectedError: 'An unexpected error has occurred',
     endUserTechnicalError: '',
     invalidApiKey: -99,
     noKeySupplied: -98
    },
 
    errorMessages: { 

    },
 
    httpStatusCodes: {
       unAuhorized: 401
    },
 
   };
 
   export const Constants = {
     
    errorCodes: {
        endUserTechnicalError: ''
       },
    
       errorMessages: { 
        endUserTechnicalError: '.',
        invalidLogin: 'Invalid login. Please enter valid username or password',
        noRecordsFound: 'No Records found'
       },
       successMessage: { 
        invalidLogin: 'Invalid login. Please enter valid username or password',      
       },
       httpStatusCodes: {
        unAuhorized: 401
     },
     msgPreventLogin: 'An another user has logged-in with the same user credentials. Please login again if you require access.',
 }
<br/><br/><br/><br/> 
<h3 class="card-header text-center font-weight-bold text-uppercase py-4">Employee Allocation</h3>

<div class="container">
  <form [formGroup]="projectForm"  >
    
    <div class="form-row ">


      <div class="form-group col-md-5  ">
          <label for="projectId">Project Id</label>
          <select id="projectId" class="form-control" formControlName="projectList" [(ngModel)]="emplAllocationReq.projectId " (change)="getid()"
            >
            <option value="" class="text-primary font-weight-bolder">
              --- Select project ---
            </option>
            <option *ngFor="let sup of PrjList" value={{sup.id}}>
              {{sup.name}}
          </select>

          <!-- <span  class="validation-message" style="color:red" *ngIf="projectList &&!projectForm.controls['projectList'].valid && projectForm.controls['projectList'].touched">
            This Field is Required
          </span>   -->
    
      </div>
    </div>
        <div class="form-row align-items-center">

      <div class="form-group col-md-5  ">

        
        <select  class="form-control" [(ngModel)]="selectedToAdd" multiple name="myselecttsms2" style="height: 300px;" formControlName="unallocatedDropDown">
            <option *ngFor="let item of groupsArray | order_by:'fullName'" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;" [ngValue]="item">{{item.fullName | titlecase}}</option>
           </select>
           <!-- <span  class="validation-message" style="color:red" *ngIf="unallocatedDropDown &&!projectForm.controls['unallocatedDropDown'].valid && projectForm.controls['unallocatedDropDown'].touched">
            This Field is Required
          </span>   -->
    
      </div>

      <div class="form-group form-group col-md-1 offset-1   ">
        
        
        <div class="row mt-2">
            <button (click)="assign()" class="btn  btn-primary" style="margin-bottom: 3px;width: 69px;height: 39px;background: #28a745;"><i>>></i></button>
        

        </div>

         <div class="row mt-3">

            <button (click)="unassign()" class="btn  btn-primary" style="margin-bottom: 3px;width: 69px;height: 39px;background: #28a745;"><i><<</i></button>
     
        </div>
        
       
    </div>
      
      <div class="form-group form-group col-md-1   ">
       
        
      

      </div>
      <div class="form-group form-group col-md-3   ">
        
        <select class="form-control" [(ngModel)]="selectedToRemove" multiple name="myselecttsms" style="height: 300px;"  formControlName="allocatedDropDown" >
            <option *ngFor="let item of selectedItems" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;" [ngValue]="item">{{item.fullName | titlecase}}</option>
           </select>
           <!-- <span  class="validation-message" style="color:red" *ngIf="allocatedDropDown &&!projectForm.controls['allocatedDropDown'].valid && projectForm.controls['allocatedDropDown'].touched">
            This Field is Required
          </span>  -->
      </div>
    </div>

    <div class="row mt-4"></div>
    <div class="form-row ">
        <div class="col-9"></div>

        <div class="col-1 ">
        <button  (click)="submit()" class="btn btn-success ">Save</button>
        </div>
        <div class="col-1 ">
          <button type="button" (click) = "reset()" class="btn btn-danger ">Cancel</button>
        </div>
        </div>
  </form>
  <div class="above-footer"></div>
</div>


import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { saveEmployeeTimeSheetReq } from '../businessObjects/saveEmployeeTimeSheetReq';
import { saveEmployeeTimeSheetResponse } from '../businessObjects/saveEmployeeTimeSheetResponse';
import { getTimeSheetEntryRequest, getTimesheetEntriesSupervisorReq } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService {

  constructor(
    private httpClient: HttpClient
    ) { }
      
      /**
       * postTimeEntryDetails to the server
       * @param TimeSheetObject 
       * @returns 
       */
      saveTimeSheetEntry(saveTimeSheetAPI: string, 
        timeSheetDetails: saveEmployeeTimeSheetReq[]): Observable<saveEmployeeTimeSheetResponse>{
      
        return this.httpClient.post<saveEmployeeTimeSheetResponse>(saveTimeSheetAPI,
            timeSheetDetails) 
      }
      
            /**
       * postTimeEntryDetails to the server
       * @param TimeSheetObject 
       * @returns 
       */
             updateTimeSheetEntry(saveTimeSheetAPI: string, 
              timeSheetDetails: saveEmployeeTimeSheetReq[]): Observable<saveEmployeeTimeSheetResponse>{
            
              return this.httpClient.put<saveEmployeeTimeSheetResponse>(saveTimeSheetAPI,
                  timeSheetDetails) 
            }
      
       /**
       * GetsTimeEntryDetails from the server
       * @param DateAndEID 
       * @returns 
       */
        getTimeSheetEntries(getTimeSheetEntries:string,getTimeSheetEntryList:getTimeSheetEntryRequest) {
          // now returns an Observable of Config
          return this.httpClient.get<getTimeSheetEntryResponse[]>(getTimeSheetEntries+'?EmployeeID='+getTimeSheetEntryList.employeeID+'&StartDate='+getTimeSheetEntryList.startDate+'&EndDate='+getTimeSheetEntryList.endDate+'&ManagerID='+getTimeSheetEntryList.manangerID
            );
        }

         /**
       * Deletes time entry
       * @param weekID 
       * @returns 
       */
      DeleteTimeEntry(deleteTimeSheet:string,weekID:string)
      {
        return this.httpClient.delete<string>(deleteTimeSheet+'/'+weekID);        
      }

      getTimesheetEntriesSupervisor(timesheetentryUrl :string, getTimesheetEntryList :getTimesheetEntriesSupervisorReq){
        return this.httpClient.get<getTimeSheetEntryResponse[]>(timesheetentryUrl+'?EmployeeID='+getTimesheetEntryList.employeeID+'&StartDate='+getTimesheetEntryList.startDate+'&EndDate='+getTimesheetEntryList.endDate+'&ManagerID='+getTimesheetEntryList.manangerID+'&SupervisorID='+getTimesheetEntryList.supervisorID);
      }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { parse } from 'path';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { employeeAllocationReq } from '../businessObjects/employeeAllocationReq';
import { employeeAllocationRes } from '../businessObjects/employeeAllocationResp';
import { projectList } from '../businessObjects/projectList';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeAllocationService } from '../services/employee-allocation.service';
import { EmployeeService } from '../services/employee.service';
import { projectService } from '../services/project.service';
declare var $: any;
@Component({
  selector: 'app-employee-project-allocation',
  templateUrl: './employee-project-allocation.component.html',
  styleUrls: ['./employee-project-allocation.component.css']
})
export class EmployeeProjectAllocationComponent implements OnInit {

  public suprVRes: superVisorRes[];
  PrjList: projectList[];
  selectedToAdd?: any;
  selectedToRemove?: any;
  selectedItems?: any = [];
  groupsArray?: any = [];
  public  num?:any=[];
  
  // public Prj: projectList = {
  //   id: '',
  //   name: '',
  //   description: '',
  //   managerID: 0,
  //   startDate: '',
  //   CostCenter: '',
  //   ProgramID: 0
  // }
  public employeeAllocationRes:employeeAllocationRes={

    isSuccess:'',
    message:''
  }
 public emplAllocationReq:employeeAllocationReq=
  {
    EmployeeId:[],
projectId:'',
LastUpdatedBy:0

  }
  public projectId: any;
  constructor(private emplservice: EmployeeService,private projListService: projectService,private Pf: FormBuilder,private employeeAllocationServ:EmployeeAllocationService) {
    
  }
  envConstants=environment;
  

  public projectForm = new FormGroup({
    allocatedDropDown: new FormControl('',[Validators.required]),
    projectList:new FormControl('',[Validators.required]),
    unallocatedDropDown:new FormControl('',[Validators.required]),
    empName:new FormControl('',[Validators.required])
        
  
  });


  ngOnInit(): void {
    $( ".hamburger" ).click();
    this.getAllEmployees();
    this.getProjectIdList();
    
  
    
  }
  submit() {
    this.emplAllocationReq.EmployeeId=[];
    for (var _i = 0; _i < this.selectedItems.length; _i++) {
      this.selectedItems[_i].id;
        

    this.emplAllocationReq.EmployeeId[_i]=this.selectedItems[_i].id;
     
 
    }
   
    this.saveEmployeeAllocation();  

    
  }
  getid() {
    this.selectedItems=[];
    this.groupsArray=[];
    this.suprVRes=[];
    let apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;
    this.emplservice.getManagers(apiUrl).subscribe((SuprVRes: superVisorRes[]) => {
      this.suprVRes = SuprVRes;
      this.groupsArray = this.suprVRes;
      apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;

    this.emplservice.getEmployeeListByProjectId(apiUrl, this.projectForm.controls['projectList'].value).subscribe((data:superVisorRes[]) => {

      this.suprVRes = data;

      this.selectedItems=this.suprVRes
      this.selectedItems.forEach((element: { id: any; }) => {
        this.groupsArray = this.groupsArray.filter((user:any) => {
          return user.id !== element.id;
      })
      });
     
    }

    );
    }
    );
   

  }
  saveEmployeeAllocation() {
   
    let apiUrl = this.envConstants.apiUrl+this.envConstants.AddOrRemoveEmployeeToProject;
    this.emplAllocationReq.projectId=parseInt( this.emplAllocationReq.projectId);
    let user:any=localStorage.getItem("userID");
    this.emplAllocationReq.LastUpdatedBy = parseInt(user);
    if(this.emplAllocationReq.projectId=='' || this.emplAllocationReq.projectId==null 
    || this.emplAllocationReq.projectId.toString()==NaN.toString() ||this.emplAllocationReq.projectId==undefined)
    {
      Swal.fire({
        icon: 'error',
        title: 'Please select the project first',
        showConfirmButton: false,
        timer: 1500
      })
      return;
    }
    this.employeeAllocationServ.saveEmployeeAllocation(apiUrl,this.emplAllocationReq ).subscribe((EmployeeAllocationRes: employeeAllocationRes) => {
    this.employeeAllocationRes = EmployeeAllocationRes;

      Swal.fire({
        icon: 'success',
        title: 'Employee(s) have been allocated/deallocated to project sucessfully',
        showConfirmButton: false,
        timer: 1500
      })

  });

 
}


  // getProjectIdList() {
    
  //   let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectListUrl;
  //   this.projListService.getProjectIdList(apiUrl).subscribe((prjList: projectList[]) => {
  //     this.PrjList = prjList;
  //   }
  //   );
  // }

  getProjectIdList() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectByManager;
    let managerID = localStorage.getItem("userID");
    this.projListService.getProjetListByManager(apiUrl, managerID).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
    });
  }

  getAllEmployees() {
  
    let apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;
    this.emplservice.getManagers(apiUrl).subscribe((SuprVRes: superVisorRes[]) => {
      this.suprVRes = SuprVRes;
      this.groupsArray = this.suprVRes;
    
    }
    );
  }

  reset(){
    this.projectForm.reset();
    this.getid();
  }

  assign() {
    this.selectedItems = this.selectedItems.concat(this.selectedToAdd);
    this.groupsArray = this.groupsArray.filter((selectedData: any) => {
      
    return this.selectedItems.indexOf(selectedData) < 0;
    });
    this.selectedToAdd = [];
     }
   
     unassign() {
    this.groupsArray = this.groupsArray.concat(this.selectedToRemove);
    this.selectedItems = this.selectedItems.filter((selectedData: any) => {
       return this.groupsArray.indexOf(selectedData) < 0;
    });
    this.selectedToRemove = [];
     }

    //  get projectList()
    //  {
    //    return this.projectForm.get('projectList');
    //  }

    //  get allocatedDropDown()
    //  {
    //    return this.projectForm.get('allocatedDropDown');
    //  }
    //  get unallocatedDropDown()
    //  {
    //    return this.projectForm.get('unallocatedDropDown');
    //  }



}

import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Constants, constants } from './common/constants';
import { SharedService } from './services/shared.service';
import { LoaderService } from './loader/loader.service';
declare var $: any; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TimesheetEntry';
  public isManager:boolean=false;
  public isSupervisor:boolean=false;
  public isAdmin:boolean=false;
  public userName:any='';
  public timeoutId:any;
  constructor( private router: Router,public sharedService:SharedService,private msalService: MsalService
    ,public loaderService:LoaderService) {
      this.sharedService.onLogin.subscribe(response => {
        let userRole:any=null;
        let uName :any = '';
        uName = localStorage.getItem("userName")?.toString().replace('@purecs.com','');
        this.userName = uName.replace(/\.(.*)/g,"");
        userRole= localStorage.getItem("roles")?.toString();
        let roleSet:any;
        if(userRole && userRole.toString().indexOf(',')>1)
        {
          roleSet=userRole.split(",");
          console.log(roleSet);
        }
        else
        {
          roleSet=[];
          roleSet.push(userRole);
        }
        this.isManager=false;
        this.isSupervisor=false;
        this.isAdmin=false;
         roleSet.forEach((element: any) => {
          {
           if(element==constants.roles.Supervisor)
           {
            this.isSupervisor=true;
           }
         }
        })
       
       roleSet.forEach((element: any) => {
          {
           if(element==constants.roles.Manager)
           {
            this.isManager=true;
           }
         }
        })
        roleSet.forEach((element: any) => {
          {
           if(element==constants.roles.Admin)
           {
            this.isAdmin=true;
           }
         }
        })
      })};
      
 //#region handle user is iddle
 checkTimeOut() { 
  this.timeoutId = setTimeout(
    () => {       
      this.handleUserIddle()
    },
    constants.timeOutMin
  );    
}
@HostListener('window:keydown')
@HostListener('window:mousedown')
 checkUserActivity() {
  clearTimeout(this.timeoutId);
  this.checkTimeOut();
}
handleUserIddle(){
  this.msalService.logout();
  localStorage.clear();
  this.router.navigate(['logins']);
}
logout()
{
 this.msalService.logoutPopup();
 this.userName=false;
  localStorage.clear();
  this.router.navigate(['logins']);
  $( ".hamburger" ).hide();
  $( ".hamburger" ).click(); 
 

}
  ngOnInit(): void {

    $(document).on('click',"#overlay",function () {
      let overlay = $('.overlay');
      // Some code here
      overlay.hide();
      $( ".hamburger" ).click(); 
  });
    let userRole:any=null;
    userRole= localStorage.getItem("roles")?.toString();
    let uName :any = '';
    uName = localStorage.getItem("userName")?.toString().replace('@purecs.com','');
    this.userName = uName.replace(/\.(.*)/g,"");
    let roleSet:any;
    if(userRole && userRole.toString().indexOf(',')>1)
    {
      roleSet=userRole.split(",");
      console.log(roleSet);
    }
    else
    {
      roleSet=[];
      roleSet.push(userRole);
    }
    this.isManager=false;
    this.isSupervisor=false;
    this.isAdmin=false;
     roleSet.forEach((element: any) => {
      {
       if(element==constants.roles.Supervisor)
       {
        this.isSupervisor=true;
       }
     }
    })
   
   roleSet.forEach((element: any) => {
      {
       if(element==constants.roles.Manager)
       {
        this.isManager=true;
       }
     }
    })
    roleSet.forEach((element: any) => {
      {
       if(element==constants.roles.Admin)
       {
        this.isAdmin=true;
       }
     }
    })
}}

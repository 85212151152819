import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { supervisorActionRequest } from '../businessObjects/supervisorActionRequest';
import { supervisorActionResponse } from '../businessObjects/supervisorActionResponse';
import { supervisorDashboardValuesReponse } from '../businessObjects/supervisorDashboardResponse';
import { supDashboardValuesReponse } from '../businessObjects/dashBoardValues';

@Injectable({
  providedIn: 'root'
})
export class SupervisorActionService {

  constructor(
    private httpClient: HttpClient
    ) { }
      
      /**
       * update the supervisor action to the server
       * @param TimeSheetObject 
       * @returns 
       */
      updateSupervisorAction(updateSupervisorAction: string, 
        supervisorActionUpdate: supervisorActionRequest): Observable<supervisorActionResponse>{
      
        return this.httpClient.put<supervisorActionResponse>(updateSupervisorAction,
            supervisorActionUpdate) 
      }

            /**
       * GetsTaskList from the server
       * @param ProjID 
       * @returns 
       */
            getSupervisorDashboardValues(getTaskList:string,userID:any,role:any,startDate:any,endDate:any,employeeID:any)
            :Observable<supDashboardValuesReponse>{
             let httpParams = new HttpParams()
             .set('userID', userID)
             .set('role', role)
             .set('startDate',startDate)
             .set('endDate',endDate)
             .set('employeeID',employeeID)
              let url = `${environment.apiUrl}${environment.getSupervisorDashboard}`;
              return this.httpClient.get<supDashboardValuesReponse>(url, { params: httpParams });  
           }

           getManagerDashboardValues(getTaskList:string,userID:any,role:any,startDate:any,endDate:any,projectID:any)
            :Observable<supDashboardValuesReponse>{
             let httpParams = new HttpParams()
             .set('userID', userID)
             .set('role', role)
             .set('startDate',startDate)
             .set('endDate',endDate)
             .set('projectID',projectID)
              let url = `${environment.apiUrl}${environment.getManagerDashboard}`;
              return this.httpClient.get<supDashboardValuesReponse>(url, { params: httpParams });  
           }

           getUserDashboardValues(getTaskList:string,userID:any,role:any,startDate:any,endDate:any)
           :Observable<supDashboardValuesReponse>{
            let httpParams = new HttpParams()
            .set('userID', userID)
            .set('role', role)
            .set('startDate',startDate)
            .set('endDate',endDate)
             let url = `${environment.apiUrl}${environment.getUserDashboard}`;
             return this.httpClient.get<supDashboardValuesReponse>(url, { params: httpParams });  
          }
    }
import { Component, OnInit } from '@angular/core';
import { daysObject } from '../businessObjects/daysObject';
import { saveEmployeeTimeSheetReq,taskList } from '../businessObjects/saveEmployeeTimeSheetReq';
import { FormsModule } from '@angular/forms';
import { environment } from "../../environments/environment";
import { Constants, constants } from "../common/constants";
import { TimeEntryService } from '../services/timeentry.service';
import Swal from 'sweetalert2';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { ProjectService } from '../services/projects.service';
import { projectService } from '../services/project.service';
import { projectByEmpResponse, subTaskByProjIDResponse } from '../businessObjects/projectRequest';
import { EmployeeService } from '../services/employee.service';
import { AppComponent } from '../../app/app.component';
import { element } from 'protractor';
import { HttpErrorResponse } from '@angular/common/http';
import { projectList } from '../businessObjects/projectList';
declare var $: any;

@Component({
  selector: 'table-editable',
  templateUrl: './timesheet-entry.component.html',
  styleUrls: ['./timesheet-entry.component.scss']
})
export class TimesheetEntryComponent implements OnInit {
  public envConstants= environment;
  public appConstants = constants;
  public idNumber:any;
  public selectedWeek:any;
  public totalSun:any;totalMon:any;totalTue:any;
  public getTimeSheetRes: getTimeSheetEntryResponse[];
  public commentsDone:any;
  totalWed:any;totalThu:any;totalFri:any;totalSat:any;
  public dateStart:any;
  public dateEnd:any;
  public totalNumVal:any;
  public isDisabled:boolean;
  public showRecall :boolean;
  public showSave :boolean;
  public showSend :boolean;
  public isAnySaved :boolean;
  public isAnyRejected :boolean= false;
  public isProjectEditable :boolean[]=[];
  public isGet:boolean=false;
  private commentsArray: boolean[][]=[]; 
  public rowStatus:boolean=false;
  public isSentForApproval:boolean=true;
  public commentRowID?:any=0;
  public commentIndex?:any=0;
  public isFullApproved:any=false;
  public showManagerComments :boolean = false;
  public selectedTaskID:number;
  public empDoj: any;
  public constructor(private timeEntryService: TimeEntryService, private projectService:ProjectService, 
    private emplService: EmployeeService, private projService: projectService)
  {

  }
  ngOnInit(): void {
    $( ".hamburger" ).show();
    $('#weekpicker').datepicker('update', startDate);
    $( ".LoggedInUsername" ).show();
    document.addEventListener("keydown", function(e) {
      if(e.keyCode==13){
        let nextTr = $(e.target).closest('td').nextAll('td:not(.group)').first();
        nextTr.find('input').focus();
      } 
    });
    this.isGet=false;
    this.getProjectsForEmp();
    this.getEmpDojDetails();
 
    var startDate,
    endDate;
    if(!startDate){
      this.setDefaultWeek();
    }
    
    $('#weekpicker').datepicker({
      autoclose: true,
      format :'mm/dd/yyyy',
      forceParse :false
    }).on("changeDate", (e:any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+7);
      $('#weekpicker').datepicker('update', startDate);
      $('#weekpicker').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    });
  }
  
  validateTotalCount():boolean {
    if((this.totalSun!=8 && this.totalSun!=0) || (this.totalMon!=8 && this.totalMon!=0) || (this.totalTue!=8 && this.totalTue!=0) || (this.totalWed!=8 && this.totalWed!=0) || 
        (this.totalThu!=8 && this.totalThu !=0) || (this.totalFri!=8 && this.totalFri !=0) || (this.totalSat!=8 && this.totalSat !=0)){
      Swal.fire({
        icon: 'warning',
        title: 'You need to fill 40 hours a week, 8 hours a day.',
        showConfirmButton: false,
        timer: 3000
      });
      return false;
    }
    if(this.totalNumVal != 40){
      Swal.fire({
        icon: 'warning',
        title: 'You need to fill 40 hours a week, 8 hours a day.',
        showConfirmButton: false,
        timer: 2000
      });
      return false;
    }
    for(let i=0; i<this.totalDaysHoriz.length;i++){
      if(this.totalDaysHoriz[i] === 0){
        Swal.fire({
          icon: 'warning',
          title: 'You cannot save/send 0 hours in a timesheet.',
          showConfirmButton: false,
          timer: 2000
        });
        return false;
      }
    }
    return true;
  }
    
  next(val:any){
    if(val==1){
      let startDate = new Date(this.dateStart);
      let  endDate =new Date(this.dateEnd);
      startDate.setDate(startDate.getDate()-7);
      endDate.setDate(endDate.getDate()-7);
      $('#weekpicker').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    }
    if(val==2){
      let startDate = new Date(this.dateStart);
      let  endDate =new Date(this.dateEnd);
      startDate.setDate(startDate.getDate()+7);
      endDate.setDate(endDate.getDate()+7);
      $('#weekpicker').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    }
  }

  keytab(event:any){
    let element = event.srcElement.nextElementSibling; // get the sibling element
    if(element == null)  // check if its null
      return;
    else
      element.focus();   // focus if not null
  }

  setDefaultWeek() {
    let dateToday = new Date();
    let startDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - (dateToday.getDay()-1));
    let endDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - dateToday.getDay()+7);
    $('#weekpicker').datepicker('update', startDate);
    $('#weekpicker').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
    this.getDailyTimeSheetEntries();   
  }

  ngViewAfterInit(): void { 
    var startDate,
    endDate;
    $('#weekpicker').datepicker({
      autoclose: true,
      format :'mm/dd/yyyy',
      forceParse :false
    }).on("changeDate", (e:any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+7);
      $('#weekpicker').datepicker('update', startDate);
      $('#weekpicker').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      
      this.getDailyTimeSheetEntries();
    });
    //test to intialize subtassks
    this.changeSubTask(0,1);
  }
  
  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID:'',
    endDate:'',
    startDate:''
  }
 
  daysObjectObjInt: daysObject = {
    sun: 0,
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0
  }
      
  timeEntryList: Array<saveEmployeeTimeSheetReq> = [
      { id: 0, remarks:'',startDate:'',days:this.daysObjectObjInt,endDate:'',projectId:0,status:0,subTaskId:0,employeeId:1,tasks:[],isLocked:false,remarksList:[], costCenter:'',projectName:'',subTaskname:'',projectStartDT:'',projectCompletedDT:''},
  ];

  projects = [
    {
      project_Id:0 ,project_Name:'--Select Project--',startDate:'',completedDt:''
    }
  ];


  public totalDaysHoriz:any[]=[{}];
  public subTasks:taskList[] = [
    {
      name: '--Select Task--',id:0
    }
  ];
  
  languageChange(event: any){

  }
  
  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
  }
    
  onChangeProject(event:any,id:number){
    const value = event.target.value;
    this.getTaskForProj(id,value);
    this.timeEntryList[id].projectId=value;
    this.getProjectDetails(id,value);
    this.timeEntryList[id].projectStartDT=(this.projects.filter(
      project => project.project_Id === parseInt(value)))[0].startDate;
    this.timeEntryList[id].projectCompletedDT=(this.projects.filter(
      project => project.project_Id === parseInt(value)))[0].completedDt;
  }
    
  changeSubTask(id:number,projectID:number){
    var subTasks:taskList = {
      name: '--Select Task--',id:0
    }
    this.timeEntryList[id].tasks?.push(subTasks)
  }

  getProjectDetails(id:number,projID:number){
    let apiName = this.envConstants.getProjectById;
    let apiUrl = this.envConstants.apiUrl + apiName;
    let projId=projID;
    this.timeEntryList[id].costCenter='';
    this.projService.getProjetListById(apiUrl,projId).subscribe((projDetails :projectList)=>{
      this.timeEntryList[id].costCenter = projDetails.costCenter;
    });
  }
    
  onRightClick(e:any,id:any,count:any){
    $('#dose2-findModal').modal();
    $('.modal-backdrop').remove();
    this.commentRowID=id;
    this.commentIndex=count;
    let remarks=this.timeEntryList[id].remarksList[count-1];
    if(remarks){
      this.commentsDone=remarks;
    }
    else{
      this.commentsDone='';
    }  
      e.preventDefault();
  }

  onChangeTask(event:any,id:number){
    const value = event.target.value;
    this.timeEntryList[id].subTaskId=value;
    this.selectedTaskID=value;
  }
    
  remove(id: any,weekID:any) {
    Swal.fire({
      title: 'Do you want to remove the entry?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if(this.timeEntryList[id].status==1 ||this.timeEntryList[id].status==4){
          this.deleteTimeEntry(weekID);
          this.isProjectEditable.splice(id,1);
          if(this.timeEntryList.length>1){
            this.totalSun -= this.timeEntryList[id].days.sun; this.totalMon -= this.timeEntryList[id].days.mon; 
            this.totalTue -= this.timeEntryList[id].days.tue; this.totalWed -= this.timeEntryList[id].days.wed; 
            this.totalThu -= this.timeEntryList[id].days.thu; this.totalFri -= this.timeEntryList[id].days.fri; this.totalSat -= this.timeEntryList[id].days.sat;
            this.timeEntryList.splice(id, 1);
            this.totalDaysHoriz.splice(id,1);
          }
          else{
            this.timeEntryList.splice(id, 1);
            this.add();
            this.totalNumVal=0;
            this.totalDaysHoriz[0]=0;
            this.totalSun=this.totalMon=this.totalTue=this.totalWed=0;
            this.totalThu=this.totalFri=this.totalSat=0;
          }
        }
        if(this.timeEntryList[id].status==0){
          if(this.timeEntryList.length<=1){
            this.timeEntryList.splice(id, 1);
            this.isProjectEditable.splice(id,1);
            this.add();
            this.totalNumVal=0;
            this.totalDaysHoriz[0]=0;
            this.totalSun=this.totalMon=this.totalTue=this.totalWed=0;
            this.totalThu=this.totalFri=this.totalSat=0;
          }
          else{
            this.timeEntryList.splice(id, 1);
            this.isProjectEditable.splice(id,1);
            this.totalDaysHoriz.splice(id,1);
          }    
        }
        this.onChangeValue('mon');this.onChangeValue('tue');
        this.onChangeValue('wed');this.onChangeValue('thu');this.onChangeValue('fri');
        this.onChangeValue('sat');this.onChangeValue('sun');
        Swal.fire('Deleted!', '', 'success');
      } 
      else if (result.isDenied) {
          
      }
    }); 
  }
    
  add(){
    let  daysObjectObj: daysObject = {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0
    }
    let requestObject: saveEmployeeTimeSheetReq ={
      remarks:'',
      days:daysObjectObj,
      endDate:'',
      id:0,
      projectId:0,
      startDate:'',
      status:'',
      costCenter:'',
      subTaskId:0,
      tasks:[],
      employeeId:0,
      remarksList:[],
      isLocked:false
    };          
    this.timeEntryList.push(requestObject);
    this.isProjectEditable.push(true);      
  }

  onChangeValue(name:any){
    switch(name){
      //calculate sum of days vertically
      case 'sun': let totalSunday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalSunday=totalSunday+this.timeEntryList[i].days.sun;}this.totalSun=totalSunday;break;
      case 'mon': let totalMonday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalMonday=totalMonday+this.timeEntryList[i].days.mon;}this.totalMon=totalMonday;break;
      case 'tue': let totalTuesday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalTuesday=totalTuesday+this.timeEntryList[i].days.tue;}this.totalTue=totalTuesday; break;
      case 'wed': let totalWednesday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalWednesday=totalWednesday+this.timeEntryList[i].days.wed;}this.totalWed=totalWednesday; break;
      case 'thu': let totalThursday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalThursday=totalThursday+this.timeEntryList[i].days.thu;}this.totalThu=totalThursday;break;
      case 'fri': let totalFriday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalFriday=totalFriday+this.timeEntryList[i].days.fri;}this.totalFri=totalFriday;break;
      case 'sat': let totalSaturday:number=0; for(let i=0;i<this.timeEntryList.length;i++){totalSaturday=totalSaturday+this.timeEntryList[i].days.sat;}this.totalSat=totalSaturday;break;
    }
    
    let totalCount:number=0;
    //calculate the sum od days horizontally
    for(let i=0;i<this.timeEntryList.length;i++){
      this.totalDaysHoriz[i]=this.timeEntryList[i].days.sun+this.timeEntryList[i].days.mon+this.timeEntryList[i].days.tue+this.timeEntryList[i].days.wed+
      this.timeEntryList[i].days.thu+this.timeEntryList[i].days.fri+this.timeEntryList[i].days.sat;
      totalCount=totalCount+this.totalDaysHoriz[i];
      if(this.timeEntryList[i].days.sun==null){this.timeEntryList[i].days.sun=0;}; if(this.timeEntryList[i].days.mon==null){this.timeEntryList[i].days.mon=0}; if(this.timeEntryList[i].days.tue==null){this.timeEntryList[i].days.tue=0};
      if(this.timeEntryList[i].days.wed==null){this.timeEntryList[i].days.wed=0}; if(this.timeEntryList[i].days.thu==null){this.timeEntryList[i].days.thu=0}; if(this.timeEntryList[i].days.fri==null){this.timeEntryList[i].days.fri=0};
      if(this.timeEntryList[i].days.sat==null){this.timeEntryList[i].days.sat=0};
    }
    this.totalNumVal=totalCount;
  }
    
  changeValue(event: any,number:number) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 13) {
      if (event.keyCode == 13) {
        let element = event.srcElement.nextElementSibling; // get the sibling element
        if(element == null)  // check if its null
          return;
        else
          element.focus();  
      }
    } 
    else {
      console.log(event);
    }
    return false;
  }
    
  getDates(){
    this.selectedWeek=$('#weekpicker').val();
    var startDt:'';
    var endDt:'';
    if(this.selectedWeek){
      const chars = this.selectedWeek.split('-');
      startDt=chars[0];
      let firstDate = new Date(startDt);
      endDt=chars[1];
      let secondDate = new Date(endDt);
      let startMonth=(firstDate.getMonth()+1).toString(),endMonth=(secondDate.getMonth()+1).toString(),
      startDay=firstDate.getDate().toString(),EndDay=secondDate.getDate().toString();

      if((startMonth.length)==1){
        startMonth="0"+startMonth;
      }
      if((endMonth.length)==1){
          endMonth="0"+endMonth;
      }
      if(startDay.length==1){
        startDay="0"+startDay;
      }
      if(EndDay.length==1){
        EndDay="0"+EndDay;
      }
        
      this.dateStart=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
      this.dateEnd=secondDate.getFullYear()+'-'+endMonth+'-'+EndDay;
    }
      // this.totalSun=0;this.totalMon=0;this.totalTue=0;this.totalWed=0;
      // this.totalThu=0;this.totalFri=0;this.totalSat=0;this.totalDaysHoriz=[];
      // this.totalNumVal=0;
  }

  getTaskForProj(id:number,projID:number){
    let apiName = this.envConstants.getTaskByProjID;
    let apiUrl = this.envConstants.apiUrl + apiName;
    let projId=projID;
    this.timeEntryList[id].tasks=[];
    this.projectService.getTaskListByProjID(apiUrl,projId)
    .subscribe((getTaskListReponse: subTaskByProjIDResponse[]) => {
      getTaskListReponse.forEach(element => {
        var subTasks1:taskList = {
          name: '--Select Task--',id:0
        }
        this.timeEntryList[id].tasks?.push(element)
      });      
    });
  }

  getProjectsForEmp(){
    let apiName = this.envConstants.getProjectByEmpID;
    let apiUrl = this.envConstants.apiUrl + apiName;
    let empId=localStorage.getItem("userID");
    this.projectService.getProjectByEmpID(apiUrl,empId)
    .subscribe((getProjectListReponse: projectByEmpResponse[]) => {
      getProjectListReponse.forEach(element => {
        this.projects.push(element);
      });   
    });
  }

  getEmpDojDetails(){
    let api = this.envConstants.getEmployeeById;
    let apiUrl = this.envConstants.apiUrl + api;
    let empId = localStorage.getItem("userID");
    this.emplService.getEmployeeListById(apiUrl, empId).subscribe(resp => this.empDoj = new Date(resp.doj));
  }

  adjustTimezone(date: Date):Date{
    let dt = new Date(date);
    const timeZoneOffset = dt.getTimezoneOffset();
    if(timeZoneOffset >= 0)
      dt = new Date( dt.getTime() + timeZoneOffset * 60000 );
    else
      dt = new Date( dt.getTime() - timeZoneOffset * 60000 );
    return dt;
  }

  saveDailyEntry(approvalStatus:number){
    let timeEntryListArray:saveEmployeeTimeSheetReq[]=[];
    this.getDates();
    this.timeEntryList.forEach(element => {
      let val:any=0;
      let empID:any=(localStorage.getItem('userID'));
      var days:number[] = [element.days.mon,element.days.tue,element.days.wed,
        element.days.thu,element.days.fri,element.days.sat,element.days.sun];
      var remarksLst:string[] = element.remarksList;
      let  requestObject: saveEmployeeTimeSheetReq = {
        id:0,
        remarks:element.remarks,
        employeeId:parseInt(empID),
        projectId:parseInt(element.projectId),
        subTaskId:parseInt(element.subTaskId),
        startDate:this.dateStart.trim(),
        endDate:this.dateEnd.trim(),
        status:approvalStatus,
        days:element.days,
        dayList:days,
        remarksList:remarksLst,
        isLocked:false,
        projectStartDT:element.projectStartDT,
        projectCompletedDT:element.projectCompletedDT,
        costCenter:element.costCenter
      }   
      timeEntryListArray.push(requestObject);
    });
    this.onChangeValue("Save");
    let validate:boolean=true;
    timeEntryListArray.forEach(element => {
      if(element.projectId==0 || element.subTaskId==0){
        validate=false;
      }
    });
    let unique = [...new Set(timeEntryListArray.map(propYoureChecking => propYoureChecking.projectId && propYoureChecking.subTaskId))];
    if (unique.length !== timeEntryListArray.length) {
      Swal.fire({
        icon: 'error',
        title: 'Task duplications...',
        text: 'You cannot save duplicate entries for project and task name',
      });
      return;
    }
       
    let validateProjectDate=true;
    let validateDOJ :boolean = true;
    let validateDuplicate=false;
    let projectName:any;
    timeEntryListArray.forEach(element => {
      let timesheetStartDate = new Date(element.startDate);
      let timesheetEndDate = new Date(element.endDate);
      let projectStartDate = this.adjustTimezone(new Date(element.projectStartDT));
      let projectEndDate = this.adjustTimezone(new Date(element.projectCompletedDT));
      if((timesheetStartDate.getTime() < projectStartDate.getTime() || timesheetEndDate.getTime() > projectEndDate.getTime())&&(element.isLocked==false)){
        validateProjectDate=false;
        projectName=element.projectName;
      }  
      const endDt = new Date(element.projectCompletedDT);
      if(this.empDoj.getTime() > endDt.getTime()){
        validateDOJ = false;
      }
    });
    
    if(!validateProjectDate){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period isn't matching with your timesheet",
      });
      return;
    }
    if(!validateDOJ){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period is before your DOJ",
      });
      return;
    }
    if(!validate){
      Swal.fire({
        icon: 'error',
        title: 'Field validations...',
        text: 'You cannot save keeping Project and Task empty',
      });
      return;
    }
    if(!this.validateTotalCount()){
      return;
    }
    timeEntryListArray.forEach(element => {
      if(element.days.sun==null){element.days.sun=0}; if(element.days.mon==null){element.days.mon=0}; if(element.days.tue==null){element.days.tue=0};
      if(element.days.wed==null){element.days.wed=0}; if(element.days.thu==null){element.days.thu=0}; if(element.days.fri==null){element.days.fri=0};
      if(element.days.sat==null){element.days.sat=0};
    });
    let apiName = this.envConstants.postTimeEntry;
    let apiUrl = this.envConstants.apiUrl + apiName;
      
    let errorMessages = this.appConstants.errorMessages;

    //api call
    this.timeEntryService.saveTimeSheetEntry(apiUrl, timeEntryListArray)
    .subscribe((saveEmployeeTimeSheetResponse: { isSuccess: any; }) => {
      if(saveEmployeeTimeSheetResponse.isSuccess){
        Swal.fire({
          icon: 'success',
          title: 'Time sheet saved successfully',
          showConfirmButton: false,
          timer: 1500
        });
      }
      else{
        alert('failure');
      }
      this.getDailyTimeSheetEntries();
    },
    (error: { error: { errorMessage: any; }; }) => {
      console.error("An error has occurred in Save Daily timesheet api call: ", error);
      if(error.error.errorMessage='Unique constraint violation'){
        Swal.fire({
          icon: 'error',
          title: 'You have already saved this task of the same Project',
          showConfirmButton: false,
          timer: 1500
        });
      }
      else{
        alert(error.error.errorMessage);
        return;
      }
    }); 
  }
      
  recallDailyEntry(approvalStatus :number){
    let timeEntryListArray:saveEmployeeTimeSheetReq[]=[];
    this.getDates();
    this.timeEntryList.forEach(element => {
      let val:any=0;
      let empID:any=(localStorage.getItem('userID'));
      let weekID='';
      if(element.weekId!=''){
        weekID=element.weekId;
      }
      var days:number[] = [element.days.mon,element.days.tue,element.days.wed,
        element.days.thu,element.days.fri,element.days.sat,element.days.sun] ;
      var remarksLst:string[] = element.remarksList;
      let  requestObject: saveEmployeeTimeSheetReq = {
        id:0,
        remarks:element.remarks,
        employeeId:parseInt(empID),
        projectId:parseInt(element.projectId),
        subTaskId:parseInt(element.subTaskId),
        startDate:this.dateStart.trim(),
        endDate:this.dateEnd.trim(),
        status:element.status,
        days:element.days,
        dayList:days,
        weekId:weekID,
        remarksList:remarksLst,
        isLocked:false,
        isRemovedFromProject:element.isRemovedFromProject,
        projectStartDT:element.projectStartDT,
        projectCompletedDT:element.projectCompletedDT,
        costCenter:element.costCenter
      }     
      timeEntryListArray.push(requestObject);
    });      
    this.onChangeValue("Recall");
    let validate:boolean=true;
      
    timeEntryListArray.forEach(element => {
      if(element.projectId==0 || element.subTaskId==0){
        validate=false;
      }  
    });
      
    if(!validate){
      Swal.fire({
        icon: 'error',
        title: 'Field validations...',
        text: 'You cannot save keeping Project and Task empty',
      });
      return;
    }
      
    // if(!this.validateTotalCount()){
    //   return;
    // }
      
    timeEntryListArray.forEach(element => {
      if(element.days.sun==null){element.days.sun=0;}; if(element.days.mon==null){element.days.mon=0}; if(element.days.tue==null){element.days.tue=0};
      if(element.days.wed==null){element.days.wed=0}; if(element.days.thu==null){element.days.thu=0}; if(element.days.fri==null){element.days.fri=0};
      if(element.days.sat==null){element.days.sat=0};
    });
        
    let apiName = this.envConstants.postTimeEntry;
    let apiUrl = this.envConstants.apiUrl + apiName;
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.status === 2 ;
    });
          
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.isRemovedFromProject!=true;
    });
      
    timeEntryListArray.forEach((element, index) =>{
      timeEntryListArray[index].status = approvalStatus;  
    });
      
    let errorMessages = this.appConstants.errorMessages;

    //api call
    this.timeEntryService.updateTimeSheetEntry(apiUrl, timeEntryListArray).subscribe((saveEmployeeTimeSheetResponse: { isSuccess: any; }) => {  
      if(saveEmployeeTimeSheetResponse.isSuccess){
          Swal.fire({
          icon: 'success',
          title: 'Time sheet recalled successfully',
          showConfirmButton: false,
          timer: 1500
        });
        this.getDailyTimeSheetEntries();
      }
      else{
        alert('failure');
      }   
    },
    (error) => {
      console.error("An error has occurred in Save Daily timesheet api call: ", error);
      if(error.error.errorMessage=='Unique constraint violation'){
        Swal.fire({
          icon: 'error',
          title: 'You have already saved this task of the same Project',
          showConfirmButton: false,
          timer: 2000
        });
        return;
      }
      if(error.error.errorMessage=='All the entries have been approved, so cannot be recalled/Updated'){
        Swal.fire({
          icon: 'error',
          title: 'All the entries have been approved, so cannot be recalled/Updated',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else{
        alert(error.error.errorDesc);
      }
      return;
    });
  }

  sendDailyEntry(approvalStatus :number){
    let timeEntryListArray:saveEmployeeTimeSheetReq[]=[];
    this.getDates();
    this.timeEntryList.forEach(element => {
      let val:any=0;
      let empID:any=(localStorage.getItem('userID'));
      let weekID='';
      if(element.weekId!=''){
        weekID=element.weekId;
      }
      var days:number[] = [element.days.mon,element.days.tue,element.days.wed,
        element.days.thu,element.days.fri,element.days.sat,element.days.sun] ;
      var remarksLst:string[] = element.remarksList;
      let  requestObject: saveEmployeeTimeSheetReq ={
        id:0,
        remarks:element.remarks,
        employeeId:parseInt(empID),
        projectId:parseInt(element.projectId),
        subTaskId:parseInt(element.subTaskId),
        startDate:this.dateStart.trim(),
        endDate:this.dateEnd.trim(),
        status:element.status,
        days:element.days,
        dayList:days,
        weekId:weekID,
        remarksList:remarksLst,
        isLocked:false,
        isRemovedFromProject:element.isRemovedFromProject,
        projectStartDT:element.projectStartDT,
        projectCompletedDT:element.projectCompletedDT,
        costCenter:element.costCenter
      }
      if(!requestObject.status){
        requestObject.status = 1;
      }
      timeEntryListArray.push(requestObject);
    });
    this.onChangeValue("Send");
      
    let validateProjectDate=true;
    let validateDOJ :boolean = true;
    let projectName:any;
    
    timeEntryListArray.forEach(element => {
      let timesheetStartDate = new Date(element.startDate);
      let timesheetEndDate = new Date(element.endDate);
      let projectStartDate = this.adjustTimezone(new Date(element.projectStartDT));
      let projectEndDate = this.adjustTimezone(new Date(element.projectCompletedDT));

      if((timesheetStartDate.getTime() < projectStartDate.getTime() || timesheetEndDate.getTime() > projectEndDate.getTime())&&(element.isLocked==false)){
        validateProjectDate=false;
        projectName=element.projectName;
      }
      const endDt = new Date(element.projectCompletedDT);
      if(this.empDoj.getTime() > endDt.getTime()){
        validateDOJ = false;
      }
    });

    let validate:boolean=true;
      
    timeEntryListArray.forEach(element => {
      if(element.projectId==0 || element.subTaskId==0){
        validate=false;
      }  
    });
      
    if(!validate){
      Swal.fire({
        icon: 'error',
        title: 'Field validations...',
        text: 'You cannot save keeping Project and Task empty',
      });
      return;
    }
      
    if(!this.validateTotalCount()){
      return;
    }
      
    if(!validateProjectDate){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period isn't matching with your timesheet",
      });
      return;
    }

    if(!validateDOJ){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period is before your DOJ",
      });
      return;
    }
      
    timeEntryListArray.forEach(element => {
      if(element.days.sun==null){element.days.sun=0;}; if(element.days.mon==null){element.days.mon=0}; if(element.days.tue==null){element.days.tue=0};
      if(element.days.wed==null){element.days.wed=0}; if(element.days.thu==null){element.days.thu=0}; if(element.days.fri==null){element.days.fri=0};
      if(element.days.sat==null){element.days.sat=0};
    });
        
    let apiName = this.envConstants.postTimeEntry;
    let apiUrl = this.envConstants.apiUrl + apiName;
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.status === 1 || selectedData.status === 4;
    });
          
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.isRemovedFromProject!=true;
    });
      
    timeEntryListArray.forEach((element, index) =>{
      timeEntryListArray[index].status = approvalStatus;  
    });
      
    let errorMessages = this.appConstants.errorMessages;
      
    //api call
    this.timeEntryService.updateTimeSheetEntry(apiUrl, timeEntryListArray).subscribe((saveEmployeeTimeSheetResponse: { isSuccess: any; }) => {  
      if(saveEmployeeTimeSheetResponse.isSuccess){
        Swal.fire({
          icon: 'success',
          title: 'Time sheet has been sent for the approvals',
          showConfirmButton: false,
          timer: 1500
        });
        this.getDailyTimeSheetEntries();
      }
      else{
        alert('failure');
      }
    },
    (error) => {
      console.error("An error has occurred in Save Daily timesheet api call: ", error);
      if(error.error.errorMessage=='Unique constraint violation'){
        Swal.fire({
          icon: 'error',
          title: 'You have already saved this task of the same Project',
          showConfirmButton: false,
          timer: 2000
        });
        return;
      }
      else{
        alert(error.error.errorDesc);
      }
      return;
    });
  }

  updateDailyEntry(approvalStatus:number){
    let timeEntryListArray:saveEmployeeTimeSheetReq[]=[];
    this.getDates();
    this.timeEntryList.forEach(element => {
      let val:any=0;
      let empID:any=(localStorage.getItem('userID'));
      let weekID='';
      if(element.weekId!=''){
        weekID=element.weekId;
      }
      var days:number[] = [element.days.mon,element.days.tue,element.days.wed,
      element.days.thu,element.days.fri,element.days.sat,element.days.sun] ;
      var remarksLst:string[] = element.remarksList;
      let  requestObject: saveEmployeeTimeSheetReq ={
        id:0,
        remarks:element.remarks,
        employeeId:parseInt(empID),
        projectId:parseInt(element.projectId),
        subTaskId:parseInt(element.subTaskId),
        startDate:this.dateStart.trim(),
        endDate:this.dateEnd.trim(),
        status:element.status,
        days:element.days,
        dayList:days,
        weekId:weekID,
        remarksList:remarksLst,
        isLocked:false,
        isRemovedFromProject:element.isRemovedFromProject,
        projectStartDT:element.projectStartDT,
        projectCompletedDT:element.projectCompletedDT,
        costCenter:element.costCenter
      }
      if(!requestObject.status){
        requestObject.status = approvalStatus;
      }
      timeEntryListArray.push(requestObject);
    });
    this.onChangeValue("Update");

    let validateProjectDate=true;
    let validateDOJ :boolean = true; 
    let projectName:any;
    timeEntryListArray.forEach(element => {
      let timesheetStartDate = new Date(element.startDate);
      let timesheetEndDate = new Date(element.endDate);
      let projectStartDate = this.adjustTimezone(new Date(element.projectStartDT));
      let projectEndDate = this.adjustTimezone(new Date(element.projectCompletedDT));
      if((timesheetStartDate.getTime() < projectStartDate.getTime() || timesheetEndDate.getTime() > projectEndDate.getTime())&&(element.isLocked==false)){
        validateProjectDate=false;
        projectName=element.projectName;
      }
      const endDt = new Date(element.projectCompletedDT);
      if(this.empDoj.getTime() > endDt.getTime()){
        validateDOJ = false;
      }
    });

    let validate:boolean=true;
    timeEntryListArray.forEach(element => {
      if(element.projectId==0 || element.subTaskId==0){
        validate=false;
      }  
    });
       
    if(!validate){
      Swal.fire({
        icon: 'error',
        title: 'Field validations...',
        text: 'You cannot save keeping Project and Task empty',
      });
      return;
    }
       
    if(!this.validateTotalCount()){
      return;
    }
       
    if(!validateProjectDate){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period isn't matching with your timesheet",
      });
      return;
    }

    if(!validateDOJ){
      Swal.fire({
        icon: 'error',
        title: 'Project date validations...',
        text: "You cannot save this entry(s), as the project(s) time period is before your DOJ",
      });
      return;
    }
       
    timeEntryListArray.forEach(element => {
      if(element.days.sun==null){element.days.sun=0;}; if(element.days.mon==null){element.days.mon=0}; if(element.days.tue==null){element.days.tue=0};
      if(element.days.wed==null){element.days.wed=0}; if(element.days.thu==null){element.days.thu=0}; if(element.days.fri==null){element.days.fri=0};
      if(element.days.sat==null){element.days.sat=0};
    });
        
    let apiName = this.envConstants.postTimeEntry;
    let apiUrl = this.envConstants.apiUrl + apiName;
        
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.status === 1 || selectedData.status === 4 || selectedData.status === 0;
    });
          
    timeEntryListArray=timeEntryListArray.filter((selectedData: any) => {
      return selectedData.isRemovedFromProject!=true;
    });

    timeEntryListArray.forEach((element, index) =>{
      timeEntryListArray[index].status = approvalStatus;  
    });
        
    let errorMessages = this.appConstants.errorMessages;

    //api call
    this.timeEntryService.updateTimeSheetEntry(apiUrl, timeEntryListArray).subscribe((saveEmployeeTimeSheetResponse: { isSuccess: any; }) => {  
      if(saveEmployeeTimeSheetResponse.isSuccess){
        Swal.fire({
          icon: 'success',
          title: 'Time sheet updated successfully',
          showConfirmButton: false,
          timer: 1500
        });
        this.getDailyTimeSheetEntries();
      }
      else{
        alert('failure');
      }     
    },
    (error) => {
      console.error("An error has occurred in Save Daily timesheet api call: ", error);
      if(error.error.errorMessage=='Unique constraint violation'){
        Swal.fire({
          icon: 'error',
          title: 'You have already saved this task of the same Project',
          showConfirmButton: false,
          timer: 2000
        });
        return;
      }
      if(error.error.errorMessage=='All the entries have been approved, so cannot be recalled/Updated'){
        Swal.fire({
          icon: 'error',
          title: 'All the entries have been approved, so cannot be Updated',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else{
        alert(error.error.errorDesc);
      }
      return;
    });  
  }

  onFocusOutEvent(){
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
    $('#weekpicker').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
  }
        
  checkIfAllApproved(getTimeSheetReponse: getTimeSheetEntryResponse[]){
    let flag = true;
    for(let i=0;i<this.getTimeSheetRes.length;i++){
      if(getTimeSheetReponse[i].status != 3){
        flag = false;
      }
    }
    return flag;
  }
        
  checkStatus(getTimeSheetReponse :getTimeSheetEntryResponse[], status :number){
    let flag = false;
    for(let i=0;i<this.getTimeSheetRes.length;i++){
      if(getTimeSheetReponse[i].status == status){
        flag = true;
      }
    }
    return flag;
  }
      
  getDailyTimeSheetEntries() {
    this.showManagerComments = false;
    this.getDates();
    this.showRecall = false;
    this.isAnySaved = false;
    this.showSave = false;
    this.showSend = false;
    this.totalMon=0;this.totalTue=0;this.totalWed=0;this.totalThu=0;
    this.totalFri=0;this.totalSat=0;this.totalSun=0;this.totalNumVal=0;
    this.totalDaysHoriz = [];
    //this.totalDaysHoriz.forEach((element, index)=> this.totalDaysHoriz[index] = 0);
    while(this.isProjectEditable.length)
      this.isProjectEditable.pop();
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
    let errorMessages = this.appConstants.errorMessages;
    this.getTimeSheetReq.employeeID=localStorage.getItem('userID');
    this.getTimeSheetReq.startDate=this.dateStart.trim();
    this.getTimeSheetReq.endDate=this.dateEnd.trim();
    this.isGet=false;
    this.timeEntryList= [
      { id: 0, remarks:'',startDate:'',days:this.daysObjectObjInt,endDate:'',projectId:0,status:0,costCenter:'',subTaskId:0,employeeId:1,tasks:[],isLocked:false,remarksList:[],statusName:'New'},
    ];
    this.isDisabled=false;
    this.isProjectEditable[0] = true;
    //api call
    this.getTimeSheetReq.manangerID=0;
    this.timeEntryService.getTimeSheetEntries(apiUrl,this.getTimeSheetReq)
    .subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
      this.getTimeSheetRes=(getTimeSheetReponse);
      let arrNum:number[]=[];
      this.isSentForApproval=true;
      this.isFullApproved = this.checkIfAllApproved(getTimeSheetReponse);
      this.isAnyRejected = this.checkStatus(getTimeSheetReponse, 4);
      this.isAnySaved = this.checkStatus(getTimeSheetReponse, 1);
      if(!this.getTimeSheetRes.length){
        this.timeEntryList[0].days.sun=this.timeEntryList[0].days.mon=this.timeEntryList[0].days.tue=0;
        this.timeEntryList[0].days.wed=this.timeEntryList[0].days.thu=this.timeEntryList[0].days.fri=0;
        this.timeEntryList[0].days.sat=0;
      }
      for(let i=0;i<this.getTimeSheetRes.length;i++){
        this.isProjectEditable.length = this.getTimeSheetRes.length;
        this.isProjectEditable[i] = true;
        this.isGet=true;
        this.timeEntryList[i]=new saveEmployeeTimeSheetReq();
        this.timeEntryList[i].days=new daysObject();
        this.timeEntryList[i].employeeId=getTimeSheetReponse[i].employeeId;
        this.timeEntryList[i].projectId=getTimeSheetReponse[i].projectId;
        this.timeEntryList[i].projectName=getTimeSheetReponse[i].projectName;
        this.timeEntryList[i].projectStartDT=getTimeSheetReponse[i].projectStartDT;
        this.timeEntryList[i].projectCompletedDT=getTimeSheetReponse[i].projectCompletedDT;
        this.timeEntryList[i].subTaskname=getTimeSheetReponse[i].subTaskName;
        this.timeEntryList[i].isRemovedFromProject=getTimeSheetReponse[i].isRemovedFromProject;
        if(this.timeEntryList[i].projectId){
          this.getTaskForProj(i,this.timeEntryList[i].projectId);
        }
        this.timeEntryList[i].subTaskId=getTimeSheetReponse[i].subTaskId;
        this.timeEntryList[i].status=getTimeSheetReponse[i].status;
        if(this.timeEntryList[i].status){
          if(getTimeSheetReponse[i].status==0) this.timeEntryList[i].statusName="New";
          if(getTimeSheetReponse[i].status==1) this.timeEntryList[i].statusName="Saved";
          if(getTimeSheetReponse[i].status==2) this.timeEntryList[i].statusName="On Approvals";
          if(getTimeSheetReponse[i].status==3) this.timeEntryList[i].statusName="Approved";
          if(getTimeSheetReponse[i].status==4) this.timeEntryList[i].statusName="Rejected";
          if(getTimeSheetReponse[i].status>1){
            this.timeEntryList[i].rowStatus=true;
          } 
          if(getTimeSheetReponse[i].status==2){
            this.isSentForApproval=true;
            this.timeEntryList[i].isLocked=true;
            this.showRecall = true;
            this.isProjectEditable[i] = false;
            if(!this.isAnyRejected || !this.isAnySaved ){
              this.isDisabled = true;
            }
          }
        }
        else{
          this.timeEntryList[i].statusName=constants.status.New;
        }
          
        if(getTimeSheetReponse[i].remarks){
          this.showManagerComments = true;
        }

        this.timeEntryList[i].remarks=getTimeSheetReponse[i].remarks;
        this.timeEntryList[i].dayList=getTimeSheetReponse[i].dayList;
        this.timeEntryList[i].weekId=getTimeSheetReponse[i].weekId;
        this.timeEntryList[i].remarksList=getTimeSheetReponse[i].remarksList;
        this.timeEntryList[i].costCenter=getTimeSheetReponse[i].costCenter;
        this.changeSubTask(i,this.timeEntryList[i].projectId);

        if(this.timeEntryList[i].status==constants.status.Approved || this.timeEntryList[i].isRemovedFromProject){
          this.timeEntryList[i].isLocked=true;
          this.isProjectEditable[i] = false;
          if(this.isFullApproved==true){
            this.isDisabled=true;
          }
        }
        if(this.timeEntryList[i].status==constants.status.Rejected){
          this.timeEntryList[i].isRejected = true;
          this.timeEntryList[i].isLocked = false;
          this.isDisabled = false;
          this.showSave = true;
          this.showSend = true;
          this.isProjectEditable[i] = false;
        }
        if(this.timeEntryList[i].status == constants.status.SentforApproval){
          this.showRecall = true;
          this.isProjectEditable[i] = false;
        }
        if(this.timeEntryList[i].status == constants.status.SaveasDraft){
          this.isDisabled =false;
          this.showSave = true;
          this.showSend = true;
          this.isProjectEditable[i] = false;
        }

        this.timeEntryList[i].dayList?.forEach(element => {
          arrNum.push(element);
        }); 

        let j:number=0;
        if(i>0)j=i*7;
        
        this.timeEntryList[i].days.mon=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.tue=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.wed=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.thu=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.fri=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.sat=arrNum[j]!=null?arrNum[j++]:0;
        this.timeEntryList[i].days.sun=arrNum[j]!=null?arrNum[j++]:0;
    
        this.onChangeValue('mon');this.onChangeValue('tue');
        this.onChangeValue('wed');this.onChangeValue('thu');this.onChangeValue('fri');
        this.onChangeValue('sat');this.onChangeValue('sun');
      }
    },
    (error:any) => {
      if(error.status===400 && error.error.errorCode == "REC0006"){  
        console.error('No Records Found for this week');
      }
      this.totalDaysHoriz = [];
      // this.totalDaysHoriz[0]=0;this.totalDaysHoriz[1]=0;
      // this.totalDaysHoriz[2]=0; this.totalDaysHoriz[3]=0;
      this.timeEntryList[0].days.sun=this.timeEntryList[0].days.mon=this.timeEntryList[0].days.tue=0;
      this.timeEntryList[0].days.wed=this.timeEntryList[0].days.thu=this.timeEntryList[0].days.fri=0;
      this.timeEntryList[0].days.sat=0;
    });
  }
  
  deleteTimeEntry(weekID:string){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.deleteTimeSheetEntry;
    this.timeEntryService.DeleteTimeEntry(apiUrl,weekID).subscribe((response:string) => {
      Swal.fire({
        icon: 'error',
        title: response,
        showConfirmButton: false,
        timer: 2000
      });
    })
  }
    
  updateComments(){
    this.timeEntryList[this.commentRowID].remarksList[this.commentIndex-1]=this.commentsDone;
    this.getBoolVal(this.commentRowID,this.commentIndex-1);
  }

  getBoolVal(row:number,column:number){
    if(this.timeEntryList.length>0){
      if(this.timeEntryList[row].remarksList){
        if(this.timeEntryList[row].remarksList.length>0){
          if(this.timeEntryList[row].remarksList[column]){
            return true;
          }
        }
      }
    }
    return false;
  } 
    
}


    
     <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">New Project</h3>

    <div class="container">
    <form [formGroup]="projectForm" (ngSubmit)="submit()">
        <div class="form-row ">
          <div class="form-group col-md-5">

            <label for="ProjectName">Project Name</label>
            <input type="text" class="form-control" id="projectName" placeholder="Project Name" formControlName="projectName"
            [(ngModel)]="prjSReq.name" required>
          <span  class="validation-message" style="color:red" *ngIf="projectName &&!projectForm.controls['projectName'].valid && projectForm.controls['projectName'].touched">
            This Field is Required
          </span>
          </div>
          <div class="form-group form-group col-md-5  offset-2 ">
            <label for="description">Description</label>
            <input type="text" class="form-control" id="description" placeholder="Description" maxlength="250" [(ngModel)]="prjSReq.description" formControlName="description" required>
            <span class="validation-message"  style="color:red" *ngIf="description && !projectForm.controls['description'].valid && projectForm.controls['description'].touched">
              This Field is Required
            </span>
          </div>
        </div>

        <div class="form-row " style="margin-top:30px">
          <div class="form-group col-md-5" >
            <label for="StartDate">Start Date</label>
            <input type="date" class="form-control" id="startDate"  placeholder="YYYY/MM/DD" [(ngModel)]="prjSReq.startDate" formControlName="startDate" min="2022-01-03"step="7" required>
            <span class="validation-message"  style="color:red"  *ngIf="startDate && !projectForm.controls['startDate'].valid && projectForm.controls['startDate'].touched">
              This Field is Required
            </span>
          </div>
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-5"  offset-3>
            <label for="completedDt">Completion Date</label>
            <input type="date" class="form-control" id="completedDt"  placeholder="YYYY/MM/DD" [(ngModel)]="prjSReq.completedDt" formControlName="completedDt" min="2022-01-09"step="7" required>
            <span class="validation-message"  style="color:red"  *ngIf="completedDt && !projectForm.controls['completedDt'].valid && projectForm.controls['completedDt'].touched">
              This Field is Required
            </span>
          </div>
        </div>
      
      <div class="form-row " style="margin-top:30px">
        <div class="form-group col-md-5" >
            <label for="managerId">Manager </label>
            <select id="managerId" class="form-control" Name="supervisor" formControlName="managerId"  [(ngModel)]="prjSReq.managerId" required>
              <option value="" class="text-primary font-weight-bolder">
                --- Select Manager ---
              </option>
              <option *ngFor="let sup of suprVRes" value={{sup.id}} >
                {{sup.fullName | titlecase}}
            </select>
            <span class="validation-message"  style="color:red" *ngIf="managerId && !projectForm.controls['managerId'].valid && projectForm.controls['managerId'].touched">
              This Field is Required
            </span>
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5" >
          <label for="program">Program </label>
          <select id="program" class="form-control" Name="prgrm" formControlName="programID"  [(ngModel)]="prjSReq.ProgramID" (ngModelChange)="getCostCenter()">
            <option value="" class="text-primary font-weight-bolder">
              --- Select Program ---
            </option>
            <option *ngFor="let prg of programList" value={{prg.programId}} >
                {{prg.name | titlecase}}
            </select>
          </div>
      </div>

      <div class="form-row " style="margin-top:30px">
        <div class="form-group col-md-5">
          <label for="costcenter">Project Cost Center</label>
          <input type="text" class="form-control" id="costcenter" placeholder="Cost Center" maxlength="250" [(ngModel)]="prjSReq.CostCenter" formControlName="costcenter" required>
          <span class="validation-message"  style="color:red" *ngIf="costcenter && !projectForm.controls['costcenter'].valid && projectForm.controls['costcenter'].touched">
            This Field is Required
          </span>
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5" style="margin-top: 10px;">
          <div class="row" style="margin-left: 4px;"><label id="billable-radio-group" >Billablity</label></div>
          <div class="row" style="margin-left: 4px;"> 
          <mat-radio-group aria-labelledby="billable-radio-group" class="bill-radio-group" formControlName="billablity" [(ngModel)]="billable">
            <mat-radio-button class="billable-radio-button" [value]="true" checked>
              Billable
            </mat-radio-button>
            <mat-radio-button class="billable-radio-button" [value]="false" style="margin-left: 3em;">
              Non-Billable
            </mat-radio-button>
          </mat-radio-group>
          </div> 
        </div>
      </div>

    <div class="container" style="margin-top:30px">
      <div class="row">
        <div class="col-10"></div>

        <div class="col-1 ">
          <button type="submit" [disabled]="projectForm.invalid" class="btn btn-success ">Save</button>
        </div>
        <div class="col-1 ">
          <button type="button" class="btn btn-danger " onclick="reset()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
  <div class="above-footer"></div>
</div>

export class projectList
{
    public id:any;
    public name:any;
    public description:any;
    public managerID:any;
    public startDate:any;
    public completedDt?:any;
    public CreatedDt? :any;
    public UpdatedDt? :any;
    public costCenter :any;
    public programId: any;
    public billable :any;
}
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { projectList } from '../businessObjects/projectList';
import { constants } from '../common/constants';
import { projectService } from '../services/project.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {
  public startDate:any;
  public projectList:any;
  PrjList: projectList[];
  displayedColumns: string[] = ['id', 'name', 'description','ManagerID','startDate','completedDt','actions'];
  public envConstants= environment;
  public appConstants = constants;
  // public Prj: projectList = {
  //   id: '',
  //   name: '',
  //   description: '',
  //   managerID: 0,
  //   startDate: ''

  // }
  constructor(private projListService: projectService) { }

  ngOnInit(): void {
    this.getProjectIdList();
  }

  
  getProjectIdList() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectListUrl;
    this.projListService.getProjectIdList(apiUrl).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { employeeRoleRes } from '../businessObjects/employeeRolesRes';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { saveEmployeeRs } from '../businessObjects/saveEmployeeRs';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
import{employeeRoles} from '../businessObjects/employeeRoles';
import { validateUserRes } from '../businessObjects/validateUserRes';
import { getDepartment } from '../businessObjects/getAllDepartments';
declare var $: any;
@Component({
  selector: 'app-employee-save',
  templateUrl: './employee-save.component.html',
  styleUrls: ['./employee-save.component.css']
})
export class EmployeeSaveComponent implements OnInit {

  constructor(private emplservice: EmployeeService, private Pf: FormBuilder) { }
  displayMsg: boolean;

  public  validateUser:validateUserRes={
   exists:''

  }

  public allEmpRoles: employeeRoles[];
  public saveEmpreq: saveEmployeeRq = {
    fullName: '',
    supervisorId: '',
    department: '',
    doj: '',
    createdBy: '',
    emailId:'',
    role:'',
    userName:'',
    password:'',
    departmentId :''
  };
  public empRoles: employeeRoleRes[];
  public saveEmpRes: saveEmployeeRs = {
    eid: '',
    errorMessage:''

  }
 

  public employeeForm = new FormGroup({
    fullName: new FormControl('',[Validators.required]),
    department:new FormControl('',[Validators.required]),
    doj:new FormControl('',[Validators.required]),
    supervisorId:new FormControl('',[Validators.required]),
    userName:new FormControl('',[
      Validators.email,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      this.emailDomainValidator
    ]),
  });
  public url: any;
  public suprVRes: superVisorRes[];
  public userId: any;
  envConstants=environment;
  public allDepartments :getDepartment[];

  ngOnInit(): void {

   // this.getAllRoles();
    this.getSupervisors();
  //   $('#doj').datepicker({
  //     autoclose: true,
  //     format :'yyyy/mm/dd',
  //     forceParse :false
  // })
    $( ".hamburger" ).click();
    this.getDepartments();
  }

 
  getSupervisors() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllSuperVisorListDetails;
    this.emplservice.getSupervisors(apiUrl).subscribe((SuprVRes: superVisorRes[]) => {
      this.suprVRes = SuprVRes;
    });
  }

  public valuechangey(event:any)
  {
    alert(event);
  }

  submit() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.saveEmployeeUrl;
    this.saveEmpreq.supervisorId = parseInt(this.saveEmpreq.supervisorId);
    this.saveEmpreq.departmentId = parseInt(this.saveEmpreq.departmentId);
    this.saveEmpreq.role = '';
    this.saveEmpreq.department = '';
    this.saveEmpreq.role=(this.saveEmpreq.role).toString();
    let createdBy:any;
    createdBy=localStorage.getItem("userID")?.toString();
    this.saveEmpreq.createdBy = parseInt(createdBy); //Session UserId
    this.displayMsg = false;

    if(this.saveEmpreq.doj)
    {
      this.saveEmpreq.doj=this.saveEmpreq.doj.toString().replace('/','-');
    }
    this.emplservice.saveEmployee(apiUrl, this.saveEmpreq).subscribe((data) => {
      this.saveEmpRes = data;
      this.saveEmpreq=new saveEmployeeRq();
     
      if (this.saveEmpRes.eid>0){
        Swal.fire({
          icon: 'success',
          title: this.saveEmpRes.errorMessage,
          showConfirmButton: false,
          timer: 3000
        })
        
      }
      else{
        Swal.fire({
          icon: 'error',
          title: this.saveEmpRes.errorMessage,
          timer: 3000
        })
      }
      this.employeeForm.reset();
      this.getSupervisors();
    });
  }

  userNameOnFocusOut(){
    this.displayMsg = true;
  }

  usernameOnFocusIn(){
    this.displayMsg = false;
  }

  emailDomainValidator(control: FormControl) {
    let email = control.value;
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if (domain !== "purecs.com") {
        return {
          emailDomain: {
            parsedDomain: domain
          }
        }
      }
    }
    return null;
  }

  get fullName()
  {
    return this.employeeForm.get('fullName');
  }

  get department()
  {
    return this.employeeForm.get('department');
  }
  get supervisorId()
  {
    return this.employeeForm.get('supervisorId');
  }
  get doj()
  {
    return this.employeeForm.get('doj');
  }
  get emailId()
  {
    return this.employeeForm.get('emailId');
  }
  get role()
  {
    return this.employeeForm.get('role');
  }
  get userName()
  {
    return this.employeeForm.get('userName');
  }
  get password()
  {
    return this.employeeForm.get('password');
  }
  reset()
  {
    this.employeeForm.reset();
  }

  getDepartments(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getDepartments;
    this.emplservice.getAllDepartments(apiUrl).subscribe((depts:getDepartment[])=>{
      console.log(depts);
      this.allDepartments = depts;
    });
  }
//   getAllRoles()
//   {
//     this.allEmpRoles=[{id:1,role:'Manager',isselected:false},
  
//   {id:2,role:'Supervisor', isselected:false},
//   {id:3,role:'Admin', isselected:false},
//   {id:4,role:'User', isselected:false}
  

// ]
//   }
// public roles:any[]
// public roleId:any[]
//   onChange()
//   {
   
   
  
//    this.roles= this.allEmpRoles.filter(x=>x.isselected==true).map(x=>x.role)
//    this.roleId=this.allEmpRoles.filter(x=>x.isselected==true).map(x=>x.id)
 

//   }
}

import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { employeeUpdateRes } from '../businessObjects/employeeUpdateRes';
import { EmployeeService } from '../services/employee.service';
import { getDepartment } from '../businessObjects/getAllDepartments';

@Component({
  selector: 'app-employee-update-dialog',
  templateUrl: './employee-update-dialog.component.html',
  styleUrls: ['./employee-update-dialog.component.css']
})

export class EmployeeUpdateDialogComponent implements OnInit {
  public envConstants = environment;
  employee :saveEmployeeRq;
  supervisors :superVisorRes[];
  public allDepartments :getDepartment[];
  
  constructor(private emplservice: EmployeeService,
    private fb :FormBuilder,
    private dialogRef :MatDialogRef<EmployeeUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data :saveEmployeeRq) {
      this.employee = data;
  }

  employeeUpdateForm = this.fb.group({
    employeeId: new FormControl('',[Validators.required]),
    username: new FormControl('',[Validators.required]),
    employeeName: new FormControl('',[Validators.required]),
    department: new FormControl('',[Validators.required]),
    supervisor: new FormControl('',[Validators.required]),
    DOJ: new FormControl('',[Validators.required])
  });

  ngOnInit() :void {
    this.getSupervisors();
    this.getDepartments();
  }

  getSupervisors() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllSuperVisorListDetails;
    this.emplservice.getSupervisors(apiUrl).subscribe((SuprVRes :superVisorRes[]) => this.supervisors = SuprVRes);
  }

  update(){
    this.updateEmployee().subscribe((updateResp: employeeUpdateRes) => this.dialogRef.close({response: "success"}),
    (error) => this.dialogRef.close({response: "Failed To Update Employee"}));
  }

  updateEmployee(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.updateEmployeeDetailsUrl;
    this.employee.supervisorId = parseInt(this.employee.supervisorId);
    this.employee.role = this.employee.role;
    let createdBy :any;
    createdBy = localStorage.getItem("userID")?.toString();
    this.employee.createdBy = parseInt(createdBy);
    const dtOfJoining = new Date(this.employee.doj);
    const doj = this.adjustTimezone(dtOfJoining);
    this.employee.doj = doj;
    return this.emplservice.updateEmployee(apiUrl, this.employee.id, this.employee);
  }

  getDepartments(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getDepartments;
    this.emplservice.getAllDepartments(apiUrl).subscribe((depts:getDepartment[])=>this.allDepartments = depts);
  }

  close(){
    this.dialogRef.close();
  }

  adjustTimezone(date: Date):Date{
    let dt = new Date(date);
    const timeZoneOffset = dt.getTimezoneOffset();
    if(timeZoneOffset >= 0)
      dt = new Date( dt.getTime() + timeZoneOffset * 60000 );
    else
      dt = new Date( dt.getTime() - timeZoneOffset * 60000 );
    return dt;
  }

}

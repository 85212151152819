import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
declare var $: any; 
@Injectable({
  providedIn: 'root'
})
export class MaslGuard implements CanActivate {
  constructor(private msalService : MsalService,private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.msalService.instance.getActiveAccount() == null){
        $( ".hamburger" ).hide();
        $( ".LoggedInUsername" ).hide();
        this.router.navigate(['login']);
        return false;
      }
    return true;
  }
  
}

<br/><br/><br/><br/> 
<h3 class="card-header text-center font-weight-bold text-uppercase py-4">Update Project</h3>




<div class="container">
  <form [formGroup]="projectForm" (ngSubmit)="submit()">

    <div class="form-row ">
      <div class="form-group col-md-5 ">

        <label for="ProjectName">Project Id</label>
        <select id="projectList" class="form-control" formControlName="projectList" [(ngModel)]="projectId"
          (change)="getid()" required>
          <option value="" class="text-primary font-weight-bolder">
            --- Select project ---
          </option>
          <option *ngFor="let sup of PrjList" value={{sup.id}}>
            {{sup.name}}
        </select>
        <span  class="validation-message" style="color:red" *ngIf="projectList &&!projectForm.controls['projectList'].valid && projectForm.controls['projectList'].touched">
          This Field is Required
        </span>
      </div>

    </div>
    <div class="form-row ">
      <div class="form-group col-md-5 ">

        <label for="ProjectName">Project Name</label>
        <input type="text" class="form-control" id="projectName" placeholder="Project Name"
          formControlName="projectName" [(ngModel)]="Prj.name" readonly="true" required>
          <span  class="validation-message" style="color:red" *ngIf="projectName &&!projectForm.controls['projectName'].valid && projectForm.controls['projectName'].touched">
            This Field is Required
          </span>
      </div>
      <div class="form-group form-group col-md-5  offset-2 ">
        <label for="description">Description</label>
        <input type="text" class="form-control" id="description" readonly="true" placeholder="Description" formControlName="description"
          [(ngModel)]="Prj.description" required>
          <span  class="validation-message" style="color:red" *ngIf="description &&!projectForm.controls['description'].valid && projectForm.controls['description'].touched">
            This Field is Required
          </span>
      </div>
    </div>



    <div class="form-row " style="margin-top:40px">
      <div class="form-group col-md-5">
        <label for="startDate">Start Date</label>
        <input type="date" class="form-control" (ngModelChange)="addValues()" id="startDate" placeholder="YYYY/MM/DD" formControlName="startDate"
          [(ngModel)]="Prj.startDate" required>
          <span  class="validation-message" style="color:red" *ngIf="startDate &&!projectForm.controls['startDate'].valid && projectForm.controls['startDate'].touched">
            This Field is Required
          </span>
      </div>
      <div class="form-group col-md-2"></div>
      <div class="form-group col-md-5">
        <label for="startDate">Completed Date</label>
        <input type="date" class="form-control" (ngModelChange)="addValues()" id="completedDt" placeholder="YYYY/MM/DD" formControlName="completedDt"
          [(ngModel)]="Prj.completedDt" required>
          <span  class="validation-message" style="color:red" *ngIf="completedDt &&!projectForm.controls['completedDt'].valid && projectForm.controls['completedDt'].touched">
            This Field is Required
          </span>
      </div>

      
    </div>
    <div class="form-row " style="margin-top:40px">
      <div class="form-group col-md-5">
          <label for="managerId">Manager </label>
          <select id="managerId" class="form-control" formControlName="managerId" [(ngModel)]="Prj.managerID" required>
            <option value="" class="text-primary font-weight-bolder">
              --- Select Manager ---
            </option>
            <option *ngFor="let sup of suprVRes" value={{sup.id}}>
              {{sup.fullName}}
          </select>
          <span  class="validation-message" style="color:red" *ngIf="managerId &&!projectForm.controls['managerId'].valid && projectForm.controls['managerId'].touched">
            This Field is Required
          </span>
        </div>  
        </div>  

    <div class="container" style="margin-top:30px">

      <div class="row">
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>

        <div class="col-1 ">
          <button type="submit" [disabled]="projectForm.invalid" class="btn btn-success ">Update</button>
        </div>
        <div class="col-1 ">
          <button type="button" class="btn btn-danger " onclick="reset()">Cancel</button>
        </div>
      </div>




    </div>













  </form>




</div>
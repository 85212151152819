<h2 mat-dialog-title>Update Program</h2>

<mat-dialog-content >
    <form [formGroup]="programForm">
        <div>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Program Name</mat-label>
                <input matInput formControlName="programName" [(ngModel)]="updateProgramReq.name" required> 
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Manager</mat-label>
                <input matInput formControlName="manager" [(ngModel)]="updateProgramReq.manager" required> 
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Cost Center</mat-label>
                <input matInput formControlName="costCenter" [(ngModel)]="updateProgramReq.costCenter" required> 
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<div>
    <mat-dialog-actions align="end">
        <button type="button" class="mat-raised-button"(click)="close()">Cancel</button>
        <button type="submit" class="mat-raised-button mat-primary" (click)="update()" [disabled]="programForm.invalid" style=" margin-left: 2em">Update</button>
    </mat-dialog-actions>
</div>
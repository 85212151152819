<div class="container" style="max-width: 100%;">  
    <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Program List</h3>
    <div class="row"> 
        <div class="col-sm-10 pb-2"></div>
        <div class="col-sm-2">
            <div style="display: flex;align-items: center;justify-content: center;">
                <button type="button"  class="btn btn-success" (click)="openAddDialog()" style="width: 220px; margin-bottom: 1em;">ADD</button> &nbsp;
            </div>
        </div>
    </div>
    <div class="row" style="width: 100%;">
        <table mat-table [dataSource]="dataSource" style="background: inherit"  class="mat-elevation-z12" matSort style="width: 100%;">
            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Program ID</th>
                <td mat-cell *matCellDef="let element"> {{element.programId}} </td>
            </ng-container>

            <!-- Program Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            
            <!-- Cost Center Column -->
            <ng-container matColumnDef="costCenter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost Center</th>
                <td mat-cell *matCellDef="let element"> {{element.costCenter}} </td>
            </ng-container>

            <!--Manager Column-->
            <ng-container matColumnDef="manager">
                <th mat-header-cell *matHeaderCellDef > Manager </th>
                <td mat-cell *matCellDef="let element" > {{ element.manager }} </td>
            </ng-container>

            <!-- Action Buttons -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="text-align:center;"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        <button type="button" (click)="openEditDialog(element.programId)" class="btn btn-success">Edit</button> &nbsp;
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <br>
    <div class="paginator">
        <mat-paginator
            [pageSize]="15"
            [hidePageSize]="true"
            showFirstLastButtons aria-label="Select page">
        </mat-paginator>
    </div>
    <div class="above-footer"></div>
</div>
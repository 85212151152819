import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { programSaveReq, programSaveRes } from '../businessObjects/program';
import { MatDialogRef } from '@angular/material/dialog';
import { ProgramService } from '../services/program.service';


@Component({
  selector: 'app-program-add-dialog',
  templateUrl: './program-add-dialog.component.html',
  styleUrls: ['./program-add-dialog.component.css']
})
export class ProgramAddDialogComponent implements OnInit {
  public envConstants = environment;
  constructor(private fb :FormBuilder, private dialogRef :MatDialogRef<ProgramAddDialogComponent>, 
    private progService:ProgramService) { }

  saveProgramReq :programSaveReq={
    name: '',
    manager: '',
    costCenter: ''
  };

  ngOnInit(): void {
  }

  public programForm = this.fb.group({
    programName: new FormControl('',[Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    manager:new FormControl('',[Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    costCenter:new FormControl('',[Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)])
  });

  close(){
    this.dialogRef.close();
  }

  save(){
    this.addProgram().subscribe((saveResp :programSaveRes)=> this.dialogRef.close({response: "success"}),
    error=>this.dialogRef.close({response: "Failed To Update Project"}));
  }
  addProgram(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.saveProgramUrl;
    this.saveProgramReq.name = this.saveProgramReq.name.toString().toLowerCase().trim();
    this.saveProgramReq.manager = this.saveProgramReq.manager.toString().toLowerCase().trim();
    this.saveProgramReq.costCenter = this.saveProgramReq.costCenter.toString().toLowerCase().trim();
    return this.progService.saveProgram(apiUrl,this.saveProgramReq);
  }
}

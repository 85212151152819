import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { projectByEmpResponse, subTaskByProjIDResponse } from '../businessObjects/projectRequest';
@Injectable({
    providedIn: 'root'
  })
  export class ProjectService {
  
    constructor(
      private httpClient: HttpClient
      ) { }
      
        /**
       * GetsProjectList from the server
       * @param EID 
       * @returns 
       */
         getProjectByEmpID(getTimeSheetEntries:string,employeeID:any) {
            // now returns an Observable of Config
            return this.httpClient.get<projectByEmpResponse[]>(getTimeSheetEntries+'/'+employeeID
              );
          }

           /**
       * GetsTaskList from the server
       * @param ProjID 
       * @returns 
       */
         getTaskListByProjID(getTaskList:string,ProjID:any) {
            // now returns an Observable of Config
            return this.httpClient.get<subTaskByProjIDResponse[]>(getTaskList+'/'+ProjID
              );
          }

    }
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeUpdateDialogComponent } from '../employee-update-dialog/employee-update-dialog.component';
import { getDepartment } from '../businessObjects/getAllDepartments';

declare var $: any;

@Component({
  selector: 'app-employee-update-table',
  templateUrl: './employee-update-table.component.html',
  styleUrls: ['./employee-update-table.component.css']
})
export class EmployeeUpdateTableComponent implements OnInit {

  dataSource = new MatTableDataSource<saveEmployeeRq>([]);
  public empList: saveEmployeeRq[];
  envConstants = environment;
  public suprVRes: superVisorRes[];
  public allDepartments :getDepartment[];
  displayedColumns: string[] = ['id', 'fullName', 'department', 'doj', 'supervisorID', 'actions'];

  constructor(private emplservice: EmployeeService, private dialog: MatDialog) { }

  public empListReq: saveEmployeeRq = {
    fullName: '',
    createdBy: '',
    doj: '',
    department: '',
    supervisorId: '',
    userName:'',
    departmentId: ''
  }

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  ngOnInit(): void {
    $( ".hamburger" ).click();
    this.getEmployees();
    this.getSupervisors();
    this.getDepartments();
  }

  getEmployees() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;
    this.emplservice.getAllEmployeesList(apiUrl).subscribe((EmpList: saveEmployeeRq[]) => {
      this.empList = EmpList;
      this.dataSource = new MatTableDataSource<saveEmployeeRq>(this.empList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getSupervisors() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllSuperVisorListDetails;
    this.emplservice.getSupervisors(apiUrl).subscribe((SuprVRes: superVisorRes[]) => this.suprVRes = SuprVRes);
  }

  getDepartments(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getDepartments;
    this.emplservice.getAllDepartments(apiUrl).subscribe((depts:getDepartment[]) => this.allDepartments = depts);
  }

  getEmployeeFromId(id: number){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getEmployeeById;
    return this.emplservice.getEmployeeListById(apiUrl, id); 
  }

  openEditDialog(Id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.getEmployeeFromId(Id).subscribe((EmpListReq: saveEmployeeRq)=>{
      this.empListReq = EmpListReq;
      this.empListReq.supervisorId = EmpListReq.supervisorID;
      this.empListReq.role = EmpListReq.role;
      dialogConfig.data = this.empListReq;
      const dialogRef = this.dialog.open(EmployeeUpdateDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res=>{
        if(res.response === "success"){
          Swal.fire({
            icon: 'success',
            title: 'Employee has been updated successfully',
            showConfirmButton: false,
            timer: 1500
          });
          this.getEmployees();
        } 
        else{
          Swal.fire({
            icon: 'error',
            title: res.response,
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },
    (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Edit Operation Failed',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(err);
    });
  }

  showSupervisor(model: any){
    if(model.supervisorID){
      let found = this.suprVRes.find(supr => supr.id === model.supervisorID);
      return found?.fullName;
    }
    else
      return ""
  }

  showDepartment(model :any){
    if(model.departmentID){
      let found = this.allDepartments.find(dept => dept.departmentId === model.departmentID);
      return found?.name;
    }
    else
      return "";
  }

  showDateOfJoining(model: any){
    const dt = new Date(model.doj);
    return dt.toLocaleDateString();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

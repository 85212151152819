import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { loginResponse } from '../businessObjects/loginResponse';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }
  /**
   * getUserLoginSession
   * @param useLoginSessionApiUrl 
   * @param userId 
   * @returns 
   */
   login(userId: any,password: string)
   :Observable<loginResponse>{
    let httpParams = new HttpParams()
    .set('userName', userId)
    .set('password', password);
     let url = `${environment.apiUrl}${environment.login}`;
     return this.httpClient.get<loginResponse>(url, { params: httpParams });  
  }

  }


<h2 mat-dialog-title>Update Project</h2>

<mat-dialog-content >
    <form [formGroup]="ProjectUpdateForm">
        <div>
            <mat-form-field appearance="fill" >
                <mat-label>Project Id</mat-label>
                <input matInput formControlName="projectId" [(ngModel)]="project.id" readonly="true" required> 
            </mat-form-field>
            
            <mat-form-field appearance="fill" class="project-name">
                <mat-label>Project Name</mat-label>
                <input matInput formControlName="projectName" [(ngModel)]="project.name" readonly="true" required> 
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Description</mat-label>
                <input matInput maxlength="250" formControlName="description" [(ngModel)]="project.description" required> 
            </mat-form-field>
        </div>
        <div>    
            <mat-form-field class="manager-name" appearance="outline">
                <mat-label>Manager</mat-label>
                <mat-select formControlName="manager" [(ngModel)]="project.managerID" required>
                    <mat-option *ngFor="let man of managerList" [value]="man.id">
                        {{man.fullName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field  class="billabe" appearance="outline" >
                <mat-label>Billablity</mat-label>
                <mat-select formControlName="billablity" [(ngModel)]="project.billable" required>
                    <mat-option [value]="true">
                        Billable
                    </mat-option>
                    <mat-option [value]="false">
                        Non-Billable
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Start Date</mat-label>
                <input matInput formControlName="startDate" [matDatepicker]="startDt" [(ngModel)]="project.startDate" [matDatepickerFilter]="startDateFilter" required>
                <mat-datepicker-toggle matSuffix [for]="startDt"></mat-datepicker-toggle>
                <mat-datepicker #startDt [startAt]="project.startDate"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" style=" margin-left: 4em">
                <mat-label>Completion Date</mat-label>
                <input matInput formControlName="endDate" [matDatepicker]="endDt" [(ngModel)]="project.completedDt" [matDatepickerFilter]="endDateFilter" required>
                <mat-datepicker-toggle matSuffix [for]="endDt"></mat-datepicker-toggle>
                <mat-datepicker #endDt [startAt]="project.completedDt"></mat-datepicker>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="program-name">
                <mat-label>Program</mat-label>
                <mat-select formControlName="programID" [(ngModel)]="project.programId" (ngModelChange)="getCostCenter()">
                    <mat-option *ngFor="let prog of programList" [value]="prog.programId">
                        {{prog.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="cost-center">
                <mat-label>Cost Center</mat-label>
                <input matInput maxlength="250" formControlName="costcenter" [(ngModel)]="project.costCenter" required>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<div>
    <mat-dialog-actions align="end">
        <button type="button" class="mat-raised-button"(click)="close()">Cancel</button>
        <button type="submit" class="mat-raised-button mat-primary" [disabled]="ProjectUpdateForm.invalid" (click)="update()"style=" margin-left: 2em">Update</button>
    </mat-dialog-actions>
</div>
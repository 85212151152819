import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { isThursday } from 'date-fns';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { projectList } from '../businessObjects/projectList';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { projectService } from '../services/project.service';
import { projectSaveReq } from '../businessObjects/projectSaveReq';
declare var $: any;
@Component({
  selector: 'app-project-update',
  templateUrl: './project-update.component.html',
  styleUrls: ['./project-update.component.css']
})
export class ProjectUpdateComponent implements OnInit {

  constructor(private projListService: projectService, private Pf: FormBuilder, private emplservice: EmployeeService) { }

  public projectForm = new FormGroup({
    projectName: new FormControl('',[Validators.required]),
    description:new FormControl('',[Validators.required]),
    startDate:new FormControl('',[Validators.required]),
    completedDt:new FormControl('',[Validators.required]),
    managerId:new FormControl('',[Validators.required]),
    projectList:new FormControl('',[Validators.required])
        
  
  });




  public suprVRes: saveEmployeeRq[];
  public prjSRes: projectSaveResp = {
    Success: '',
    StatusCode: '',
    ProjectID: ''

  }
  public projectId: any;
  PrjList: projectList[];
  public url: any;
  public prjUpdate :projectSaveReq;
  public Prj: projectList = {
    id: '',
    name: '',
    description: '',
    managerID: '',
    startDate: '',
    completedDt:'',
    costCenter:'',
    programId:'',
    billable: ''
  }
public envConstants=environment;
  ngOnInit(): void {

    $( ".hamburger" ).click();
    this.getProjectIdList();
    this.getSuperVisor();

    

  }
  submit() {
    this.updateProject();
  }
  updateProject() {

    let apiUrl = this.envConstants.apiUrl + this.envConstants.updateProjectUrl;
    this.Prj.managerID = parseInt(this.Prj.managerID)
    if(this.Prj.startDate>this.Prj.completedDt)
    {
      Swal.fire({
        icon: 'error',
        title: "Completed date cannot be less than start date",
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    this.projListService.updateProject(apiUrl, this.projectId, this.prjUpdate).subscribe((prRes: projectSaveResp) => {
      this.prjSRes = prRes;
      this.Prj=new projectList();
      Swal.fire({
        icon: 'success',
        title: 'Project has been updated successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.projectForm.reset();
    }
    );


  }

  getProjectIdList() {
    
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectListUrl;
    this.projListService.getProjectIdList(apiUrl).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
    }
    );
  }
  getSuperVisor() {
    
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllManagerListDetails;
    
    this.emplservice.getAllEmployeesList(apiUrl).subscribe((SuprVRes: saveEmployeeRq[]) => {
      this.suprVRes = SuprVRes;
    }
    );

  }

  getid() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectById;
   

    this.projListService.getProjetListById(apiUrl, this.projectForm.controls['projectList'].value).subscribe((Prjlist: projectList) => {
      this.Prj.description = Prjlist.description;
      this.Prj.id=Prjlist.id;
      this.Prj.name=Prjlist.name;
      this.Prj.managerID=Prjlist.managerID;
     // this.managerId=Prjlist.managerId;
     let firstDate= new Date(Prjlist.startDate);

     let startMonth=(firstDate.getMonth()+1).toString(),
     startDay=firstDate.getDate().toString()
    if(((firstDate.getMonth().toString()).length)==1)
    {
      startMonth="0"+(firstDate.getMonth()+1).toString()
    }
    if(((firstDate.getDate().toString()).length)==1)
    {
      startDay="0"+(firstDate.getDate()).toString()
    }
    this.Prj.startDate=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
    
   let completedDt=new Date(Prjlist.completedDt);

   let endMonth=(completedDt.getMonth()+1).toString(),
   endDay=completedDt.getDate().toString()
  if(((completedDt.getMonth().toString()).length)==1)
  {
    endMonth="0"+(completedDt.getMonth()+1).toString()
  }
  if(((completedDt.getDate().toString()).length)==1)
  {
    endDay="0"+(completedDt.getDate()).toString()
  }
  this.Prj.completedDt=completedDt.getFullYear()+'-'+ endMonth+'-'+endDay;

  })}
  get projectName()
  {
    return this.projectForm.get('projectName');
  }

  get description()
  {
    return this.projectForm.get('description');
  }
  get startDate()
  {
    return this.projectForm.get('startDate');
  }
  get completedDt()
  {
    return this.projectForm.get('completedDt');
  }
  get managerId()
  {
    return this.projectForm.get('managerId');
  }
  
  get projectList()
  {
    return this.projectForm.get('projectList');
  }
  reset()
  {
    this.projectForm.reset();

  }

  addValues()
  {
    //alert(this.Prj.startDate)
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { projectSaveReq } from '../businessObjects/projectSaveReq';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { projectList } from '../businessObjects/projectList';
import { projectTaskSaveReq } from '../businessObjects/projectTaskSaveReq';
import { projectTaskSaveResp } from '../businessObjects/projectTaskSaveResp';

@Injectable({
  providedIn: 'root'
})
export class projectService {

  constructor(private httpClient: HttpClient) { }

  saveProject(projectSaveUrl: string,
    prjSaveReq: projectSaveReq): Observable<projectSaveResp> {

    return this.httpClient.post<projectSaveResp>(projectSaveUrl,
      prjSaveReq)

  }

  getProjectIdList(projectGetUrl: string): Observable<projectList[]> {
    return this.httpClient.get<projectList[]>(projectGetUrl)

  }

  saveProjectTask(projectTaskSaveUrl: string,
    prjTaskSReq: projectTaskSaveReq): Observable<projectTaskSaveResp> {

    return this.httpClient.post<projectTaskSaveResp>(projectTaskSaveUrl,
      prjTaskSReq)

  }
  getProjetListById(projectGetUrl: string, pId: any): Observable<projectList> {

    return this.httpClient.get<projectList>(projectGetUrl + pId)
  }

  updateProject(projectUpdateUrl: string, projectId: any,
    prjSaveReq: projectSaveReq): Observable<projectSaveResp> {

    return this.httpClient.put<projectSaveResp>(projectUpdateUrl + projectId,
      prjSaveReq)
  }

  getProjetListByManager(projectGetUrl: string, mId: any): Observable<projectList[]> {

    return this.httpClient.get<projectList[]>(projectGetUrl + mId)
  }

  

}

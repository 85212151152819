import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { supDashboardValuesReponse } from '../businessObjects/dashBoardValues';
import { Employees, supervisorDashboardValuesReponse } from '../businessObjects/supervisorDashboardResponse';
import { constants } from '../common/constants';
import { SupervisorActionService } from '../services/supervisorAction.service';
import * as Chart from 'chart.js';
declare var $: any;
@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.css']
})
export class EmployeeDashboardComponent  {

  @ViewChild('pieCanvas') private pieCanvas: ElementRef;
  @ViewChild('barCanvas') private barCanvas: ElementRef;
  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  doughnutChart: any;
  pieChart: any;
  barChart: any;
  public startDate:any;
  public endDate:any;
  public envConstants= environment;
  public appConstants = constants;
  public dashBoardVals:supDashboardValuesReponse;
  public employees:Employees[];
  public employeesProject:Employees[];
  public pageSize:any= 5;
  public page:any= 1;
  collectionSize: number;
  constructor(private supervisorActionService: SupervisorActionService) { }
  public dashBoardBoxes:supervisorDashboardValuesReponse={
    totalEmployees:0,
    approvedRequests:0,
    id:0,
    pendingRequests:0,
    rejectedRequests:0,
    totalHoursSpent:0,
    totalProjects:0
  }
  ngAfterViewInit(): void {
  
  }
  ngOnInit(): void {
    this.setDatesForOneWeekAgo();
    this.getData();
    $( ".hamburger" ).click();
  }

  public showChange()
  {
    this.getData();
  }
 //gets the current date
 getCurrentDate(): string {
  var date = new Date();
  let currentDate = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) +"-"+("0" + date.getDate()).slice(-2);
  return currentDate;
}

  getDateForOneMonthAgo(){
    let date = new Date();
    let prevMonthDate = date.getFullYear() + "-" + ("0"+(date.getMonth())).slice(-2) +"-"+("0" + (date.getDate()-1)).slice(-2);
    return prevMonthDate;
  }

  setDatesForOneWeekAgo(){
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    if(stDate.getDay() != 1){
      for(let i = stDate.getDay(); i != 1 ; i--){
        stDate.setDate(stDate.getDate() - 1);
      }
    }
    let enDt = new Date(stDate);
    enDt.setDate(enDt.getDate() + 6);
    let prevWeekStDate = stDate.getFullYear() + "-" + ("0"+(stDate.getMonth() + 1)).slice(-2) +"-"+("0" + (stDate.getDate())).slice(-2);
    let prevWeekEndDate = enDt.getFullYear() + "-" + ("0"+(enDt.getMonth() + 1)).slice(-2) +"-"+("0" + (enDt.getDate())).slice(-2);
    this.startDate = prevWeekStDate;
    this.endDate = prevWeekEndDate;
  }

  setDatesForLastQuarter(){
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    let lastQuarterStartDate = startFullQuarter.getFullYear() + "-" + ("0"+(startFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + startFullQuarter.getDate()).slice(-2);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    let lastQuarterEndDate = endFullQuarter.getFullYear() + "-" + ("0"+(endFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + endFullQuarter.getDate()).slice(-2);
    this.startDate = lastQuarterStartDate;
    this.endDate = lastQuarterEndDate;
  }

  setDatesForLastMonth(){
    let date = new Date();
    let prevMonthStDate = date.getFullYear() + "-" + ("0"+(date.getMonth())).slice(-2) +"-"+("0" + 1).slice(-2);
    const prevMonthEndDt = new Date(date.getFullYear(), date.getMonth(), 0);
    let prevMonthEndDate = prevMonthEndDt.getFullYear() + "-" + ("0"+(prevMonthEndDt.getMonth() + 1)).slice(-2) +"-"+("0" + prevMonthEndDt.getDate()).slice(-2);
    this.startDate = prevMonthStDate;
    this.endDate = prevMonthEndDate;
  }

  showLastMonth(){
    this.setDatesForLastMonth();
    this.showChange();
  }

  showLastWeek(){
    this.setDatesForOneWeekAgo();
    this.showChange();
  }

  showLastQuarter(){
    this.setDatesForLastQuarter();
    this.showChange();
  }

  public getData()
  {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getUserDashboard;
  let userId=localStorage.getItem("userID");
  this.supervisorActionService.getUserDashboardValues(apiUrl,userId,-1,this.startDate,this.endDate)
        .subscribe((supervsorValues: supDashboardValuesReponse) => {
          this.dashBoardBoxes.approvedRequests=supervsorValues.supervisorDashboard.approvedRequests;
          this.dashBoardBoxes.pendingRequests=supervsorValues.supervisorDashboard.pendingRequests;
          this.dashBoardBoxes.rejectedRequests=supervsorValues.supervisorDashboard.rejectedRequests;
          this.dashBoardBoxes.totalEmployees=supervsorValues.supervisorDashboard.totalEmployees;
          this.dashBoardBoxes.totalHoursSpent=supervsorValues.supervisorDashboard.totalHoursSpent;
          this.dashBoardBoxes.totalProjects=supervsorValues.supervisorDashboard.totalProjects;
          let object:any[]=supervsorValues.pieChart;
          let objectValues:any[]=[];
          let objectParams:any[]=[];
          if(this.pieChart)
          {
            this.pieChart.destroy();
            
          } 
          object.forEach(element => {
            objectValues.push(element.pieCount);
            objectParams.push(element.pieFullName);
          });
          this.pieChartBrowser(objectValues,objectParams);

          object=[];objectValues=[];objectParams=[];
          object=supervsorValues.barChart;
          if(object!=undefined)
          object.forEach(element => {
            objectValues.push(element.name);
            objectParams.push(element.totalHoursSpent);
          });
          if(this.barChart)
          {
            this.barChart.destroy();
            
          } 
          this.barChartMethod(objectParams,objectValues);
          
          this.employees=supervsorValues.employees;
          this.employeesProject=supervsorValues.employeesProject;
          this.collectionSize=this.employees.length;
           });
         
  }

  pieChartBrowser(dataArray:any[],valueArray:any[]): void {
    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
       options: {
        tooltips: {
           mode: 'index',
           intersect: false
        },
        hover: {
           mode: 'index',
           intersect: false
        }
     },
      data: {
        labels:valueArray ,
        datasets: [{
          backgroundColor: [
            '#2ecc71',
            '#3498db',
            '#95a5a6',
            '#9b59b6',
            '#f1c40f',
            '#e74c3c'
          ],
          data: dataArray
        }]
      }
    });
  }

  doughnutChartMethod(dataArray:any[],valueArray:any[]) {
    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: valueArray,
        datasets: [{
          label: '# of Votes',
          data: dataArray,
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)'
          ],
          hoverBackgroundColor: [
            '#FFCE56',
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FF6384'
          ]
        }]
      }
    });
  }
  
  barChartMethod(dataArray:any[],valueArray:any[]) {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: valueArray,
        datasets: [{
          label: '# of Hours',
          data: dataArray,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest',
       },
       hover: {
          mode: 'index',
          intersect: false
       }
      }
    });
  }

}
export class projectSaveReq
{   public id?:any;
    public name?:any;
    public description?:any;
    public managerId?:any;
    public startDate?:any;
    public completedDt?:any;
    public CostCenter :any;
    public ProgramID? :any;
    public billable :any;
    public CreatedBy:any;
}
<br/><br/><br/><br/> 
<h3 class="card-header text-center font-weight-bold text-uppercase py-4">Create Task</h3>
<div class="container" style="margin-top:3%">
    <form [formGroup]="projectForm" (ngSubmit)="submit()">
  
      <div class="form-row ">
        <div class="form-group col-md-5  ">
  
          <label for="projectList">Project Id</label>
          <select id="projectList" class="form-control" formControlName="projectList" [(ngModel)]="prjTaskSReq.projectId" (change)=prjSelChange($event) required>
            <option value="" class="text-primary font-weight-bolder">
              --- Select project ---
            </option>
            <option *ngFor="let prj of PrjList" value={{prj.id}}>
              {{prj.name}}
          </select>
          <span  class="validation-message" style="color:red" *ngIf="projectList &&!projectForm.controls['projectList'].valid && projectForm.controls['projectList'].touched">
            This Field is Required
          </span>
        </div>
        <div class="form-group col-md-5 offset-1 ">
  
            <label for="TaskName">Task Name</label>
            <input type="text" class="form-control" id="TaskName" placeholder="Task Name"
              formControlName="TaskName" [(ngModel)]="prjTaskSReq.Name" required>
              <span  class="validation-message" style="color:red" *ngIf="TaskName &&!projectForm.controls['TaskName'].valid && projectForm.controls['TaskName'].touched">
                This Field is Required
              </span>
          </div>
         
  
      </div>

      <div class="form-row " *ngIf="prjTaskSReq.projectId">
        <div class="form-group col-md-5  ">
          <label for="taskList">Tasks</label>
          <select  class="form-control" multiple style="height: 200px;" formControlName="taskList">
            <option *ngFor="let item of tskList" style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;padding: 2px;" 
            [ngValue]="item.id">{{item.name}}
            </option>
          </select>
        </div>
      </div>
      
  
  
  
     
  
      <div class="container" style="margin-top:30px">
  
        <div class="row">
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-1"></div>
         
  
          <div class="col-1 ">
            <button type="submit" [disabled]="projectForm.invalid"   class="btn btn-success ">Save</button>
          </div>
          <div class="col-1 ">
            <button type="button" class="btn btn-danger " onclick="reset()">Cancel</button>
          </div>
        </div>
  
  
  
  
      </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
    </form>
  
  
  
  
  </div>
<br/><br/><br/><br/> 
<h3 class="card-header text-center font-weight-bold text-uppercase py-4">New Employee</h3>

<div class="container">
  <form [formGroup]="employeeForm" (ngSubmit)="submit()">
    <div class="form-row ">
      <div class="form-group col-md-5  ">
        <label for="ProjectName">Employee Name</label>
        <input type="text" class="form-control" id="fullName" placeholder="Employee Name" formControlName="fullName" 
          [(ngModel)]="saveEmpreq.fullName" (ngModelChange)="saveEmpreq.fullName = $event.toLowerCase()" required>
          <span  class="validation-message" style="color:red" *ngIf="fullName &&!employeeForm.controls['fullName'].valid && employeeForm.controls['fullName'].touched">
            This Field is Required
          </span>
      </div>
      <div class="form-group form-group col-md-5  offset-2 ">
        <label for="department">Department</label>
        <select id="department" class="form-control" formControlName="department" [(ngModel)]="saveEmpreq.departmentId" required>
          <option value="" class="text-primary font-weight-bolder">
            --- Select Department ---
          </option>
          <option *ngFor="let dep of allDepartments" value={{dep.departmentId}}>
            {{dep.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-row " style="margin-top:40px">
      <div class="form-group col-md-5 ">
        <label for="supervisorId"> Supervisor </label>
        <select id="supervisorId" class="form-control" formControlName="supervisorId" [(ngModel)]="saveEmpreq.supervisorId" required>
          <option value="" class="text-primary font-weight-bolder">
            --- Select Supervisor ---
          </option>
          <option *ngFor="let sup of suprVRes" value={{sup.id}}>
            {{sup.fullName}}
          </option>
        </select>
        <span  class="validation-message" style="color:red" *ngIf="supervisorId &&!employeeForm.controls['supervisorId'].valid && employeeForm.controls['supervisorId'].touched">
          This Field is Required
        </span>
      </div>
      <div class="form-group col-md-5 offset-2">
        
        <label for="doj">DOJ</label>
        <input type="date" class="form-control" id="doj"   placeholder="YYYY/MM/DD" formControlName="doj"
          [(ngModel)]="saveEmpreq.doj" required>
          <span  class="validation-message" style="color:red" *ngIf="doj &&!employeeForm.controls['doj'].valid && employeeForm.controls['doj'].touched">
            This Field is Required
          </span>
      </div>

    </div>

    <div class="form-row " style="margin-top:40px">
      <div class="form-group col-md-5  ">
        <label for="UserName">Username</label>
        <input type="text" class="form-control" id="userName" placeholder="Email Address" formControlName="userName" 
          [(ngModel)]="saveEmpreq.userName" (focus)="usernameOnFocusIn()" (focusout)="userNameOnFocusOut()" required>
          <span  class="validation-message" style="color:red" *ngIf="userName &&!employeeForm.controls['userName'].valid && employeeForm.controls['userName'].touched">
            Input valid email address which ends with @purecs.com
          </span>
          <div *ngIf="displayMsg">
            <span  class="access-message" style="color:green" >
              Please ensure this email address is being added to the Active Directory
            </span>
          </div>
      </div>
    </div>
    <div class="form-row " style="margin-top:40px">

      <div class="form-group col-md-5 offset-2">

      </div>


    </div>


    <div class="container" style="margin-top:30px">

      <div class="row">
        <div class="col-10"></div>

        <div class="col-1 ">
          <button type="submit" [disabled]="employeeForm.invalid" class="btn btn-success ">Save</button>
        </div>
        <div class="col-1 ">
          <button type="button"  class="btn btn-danger " onclick="reset()">Cancel</button>
        </div>
      </div>

    </div>
  </form>
  <div class="above-footer"></div>
</div>
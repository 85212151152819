import { daysObject } from "./daysObject";

export class saveEmployeeTimeSheetReq{
    public id:number;
    public startDate: any;
    public endDate: any;
    public projectId:any;
    public tasks?:taskList[];
    public subTaskId:any;
    public status:any;
    public statusName?:any;
    public remarks:any;
    public days:daysObject;
    public dayList?:number[]=[];
    public employeeId?:number;
    public weekId?:any;
    public remarksList:string[]=[];
    public isLocked:boolean;
    public rowStatus?:boolean;
    public isRejected?:boolean;
    public costCenter: any;

    public projectName?:any;
    public subTaskname?:any;
    public isRemovedFromProject?:any;


    public projectStartDT?:any;
    public projectCompletedDT?:any;
}

export class taskList
{
    public id:number;
    public name:any;
}
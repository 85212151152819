import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { programGetResp} from '../businessObjects/program';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProgramAddDialogComponent } from '../program-add-dialog/program-add-dialog.component';
import { ProgramEditDialogComponent } from '../program-edit-dialog/program-edit-dialog.component'
import { ProgramService } from '../services/program.service';
declare var $: any;

@Component({
  selector: 'app-program-update',
  templateUrl: './program-update.component.html',
  styleUrls: ['./program-update.component.css']
})
export class ProgramUpdateComponent implements OnInit {
  dataSource = new MatTableDataSource<programGetResp>([]);
  public envConstants=environment;
  displayedColumns: string[] = ['id', 'name', 'costCenter', 'manager', 'actions'];
  progList :programGetResp[];

  constructor(private dialog: MatDialog, private progService :ProgramService) { }

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  ngOnInit(): void {
    $( ".hamburger" ).click();
    this.getAllProgramDetails();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openAddDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ProgramAddDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res=>{
      if(res.response === "success"){
        Swal.fire({
          icon: 'success',
          title: 'Program has been added successfully',
          showConfirmButton: false,
          timer: 1500
        });
        this.getAllProgramDetails();
      } 
      else{
        Swal.fire({
          icon: 'error',
          title: res.response,
          showConfirmButton: false,
          timer: 1500
        });
      }
    });
  }

  getAllProgramDetails(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProgramDetailsUrl;
    this.progService.getAllPrograms(apiUrl).subscribe((progResp :programGetResp[])=>{
      this.progList = progResp;
      this.dataSource = new MatTableDataSource<programGetResp>(this.progList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openEditDialog(programId: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.getprogramFromId(programId).subscribe((progGetResp: programGetResp[])=>{
      dialogConfig.data = progGetResp;
      const dialogRef = this.dialog.open(ProgramEditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res=>{
        if(res.response === "success"){
          Swal.fire({
            icon: 'success',
            title: 'Program has been updated successfully',
            showConfirmButton: false,
            timer: 1500
          });
          this.getAllProgramDetails();
        } 
        else{
          Swal.fire({
            icon: 'error',
            title: res.response,
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },
    (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Edit Operation Failed',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(err);
    });
  }
  
  getprogramFromId(pId: number){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProgramDetailsUrl;
    return this.progService.getProgram(apiUrl,pId);
  }

}

import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { loginResponse } from '../businessObjects/loginResponse';
import { SharedService } from '../services/shared.service';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';
import { AuthenticationResult } from '@azure/msal-browser';
import decode from 'jwt-decode';
import { MSALInstanceFactory } from '../app.module';
import { constants } from '../common/constants'

declare var $: any;
@Component({
  selector: 'app-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.css']
})
export class LoginsComponent implements OnInit {

  constructor(  private router: Router,
    private renderer: Renderer2,private loginService: LoginService,private sharedService:SharedService,
    private msalService: MsalService) { }


  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;
  public showOtpSuccessAlert: boolean;
  public showOtpErrorAlert: boolean;
 public userName:any;
 public password:any;
  public submitted = false;
  public loginResponse:loginResponse;
  timerOn = true;
  otpTimeToLive: number = 0;
  otpTime: number = 180;
  enablebResendBtn: boolean = false;
  uniqueKey: string;

  ngOnInit(): void {
    if(this.msalService.instance.getActiveAccount() == null){
      $( ".hamburger" ).hide();
      $( ".LoggedInUsername" ).hide();
      localStorage.clear();
    }
    else{
      this.router.navigate(['timesheet']);  
    }
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'login-page');
  }

  async login(): Promise<any> {
    const response = await this.msalService
        .loginPopup(constants.MsalLoginValues)
        .toPromise()
        .catch((reason) => {
            console.log('Failure',
                JSON.stringify(reason))

        });

    if (response) {
      this.msalService.instance.setActiveAccount(response.account)
      //localStorage.clear();
      if(this.msalService.instance.getActiveAccount())
      {
       this.loginService.login(response?.account?.username,this.password).subscribe((loginRes) => {
         this.loginResponse=loginRes;
        let token=this.loginResponse.token;
        let userName:any='';
        userName=response?.account?.username;
        let tokenPayload:any = decode(token);
        if(tokenPayload)
        {
         
         this.router.navigate(['timesheet']);
         localStorage.setItem("roles",tokenPayload.roles);
         localStorage.setItem("userID",this.loginResponse.empId);
         localStorage.setItem("userCode",this.loginResponse.empGuidID);
         localStorage.setItem("userName",userName.toString());
         this.sharedService.onLogin.next(true);
        }
        
       })
      }
    }
        return response;
    }


  




}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, finalize} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LoaderService } from './loader/loader.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  constructor(
    public router: Router,public loaderService:LoaderService
  ) { }

  /**
   * intercept
   * @description 
   * @param req 
   * @param next 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler){

    this.loaderService.isLoading.next(true);
    let uID=localStorage.getItem('userCode');
    let userCode=uID?.toString();
    req = this.addUser(req, userCode?.toString());
    return next.handle(req).pipe(catchError(error => {
      if(error.error.errorCode=="INVLOGUSR001")
      {
         Swal.fire({
          icon: 'error',
          title: 'Invalid user id,Kindly login again',
          showConfirmButton: false,
          timer: 2500
        });
        localStorage.clear();
        return this.router.navigate(['login']);;
      }
      if(error.error.errorCode=="GED0005")
      {
         Swal.fire({
          icon: 'error',
          title: 'Please register yourself with the application',
          showConfirmButton: false,
          timer: 2500
        });
        localStorage.clear();
        return this.router.navigate(['login']);;
      }
      if(error instanceof HttpErrorResponse && error.status === 403){
        //handle 401 error, returns the login page as session invalid 
       Swal.fire({
          icon: 'error',
          title: 'You donot have permission for this action',
          showConfirmButton: false,
          timer: 1500
        })
        localStorage.clear();
        return this.router.navigate(['login']);
      }
      else {
        return throwError(error);
      }     
    }),
    finalize(() =>{
      this.loaderService.isLoading.next(false);
    })  
    );
  
  }

  /**
   * addToken
   * @description clone the request header add adds the aruthorizatio header contains token.
   * @param req 
   * @param token 
   * @returns HttpRequest header 
   */
  private addUser(req: HttpRequest<any>, userCode: string=""){
    
    return req.clone({
      setHeaders: {'UserID': `${userCode}`}
    });
  }
}

<div class="container" style="max-width: 100%;">  
    <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Project List</h3>
    <div class="row" style="width: 100%;"> 
        <table mat-table [dataSource]="dataSource" style="background: inherit"  class="mat-elevation-z12" matSort style="width: 100%;">
            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Project ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Project Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Name</th>
              <td mat-cell *matCellDef="let element"> {{element.name | slice: 0:30}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element"> {{element.description | slice: 0:50}} </td>
            </ng-container>
            
            <!-- Start date Column -->
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                <td mat-cell *matCellDef="let element"> {{ showStartDate(element) }} </td>
            </ng-container>
        
            <!-- End Date Column -->
            <ng-container matColumnDef="completedDt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
                <td mat-cell *matCellDef="let element"> {{ showEndDate(element) }} </td>
            </ng-container>

            <!--Manager Column-->
            <ng-container matColumnDef="ManagerID">
                <th mat-header-cell *matHeaderCellDef > Manager </th>
                <td mat-cell *matCellDef="let element" > {{ getManager(element) }} </td>
            </ng-container>

            <!-- Is active Column -->
            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef > Is Active </th>
                <td mat-cell *matCellDef="let element" > {{ isActive(element) }} </td>
            </ng-container>
            
            <!-- Action Buttons -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="text-align:center;"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        <button type="button"  class="btn btn-success" (click)="openEditDialog(element.id)">Edit</button> &nbsp;
                        <!-- <button type="button"  class="btn btn-danger">Delete</button> -->
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
        </table>
    </div>
    <br>
    <div class="paginator">
        <mat-paginator
            [pageSize]="15"
            [hidePageSize]="true"
            showFirstLastButtons aria-label="Select page">
        </mat-paginator>
    </div>
    <div class="above-footer"></div>
</div>
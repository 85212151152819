import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TimesheetEntryComponent } from './timesheet-entry/timesheet-entry.component';
import { TimesheetSupervisorComponent } from './timesheet-supervisor/timesheet-supervisor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { EmployeetaskhistoryComponent } from './employeetaskhistory/employeetaskhistory.component';
import { SupervisordashboardComponent } from './supervisordashboard/supervisordashboard.component';
import { FormsModule } from '@angular/forms';
import { SharedService } from './services/shared.service';

import { ReactiveFormsModule } from '@angular/forms';
import { projectSaveComponent } from './project-save/project-save.component';
import {CommonModule} from '@angular/common';
import { ProjectUpdateComponent } from './project-update/project-update.component';
import { EmployeeSaveComponent } from './employee-save/employee-save.component';
import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { SelfTaskHistoryComponent } from './selftaskhistory/self-task-history.component';
import { LoginsComponent } from './logins/logins.component';
import { EmployeeProjectAllocationComponent } from './employee-project-allocation/employee-project-allocation.component';
import { ProjectManagerViewComponent } from './project-manager-view/project-manager-view.component';
import { ToastrModule } from 'ngx-toastr';

import { ProjectTaskSaveComponent } from './project-task-save/project-task-save.component';
import { ManagerDashboardComponent } from './manager-dashboard/manager-dashboard.component';
import { MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { TokenInterceptorService } from './token-interceptor.service';
import {MatProgressBar, MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectUpdateTableComponent } from './project-update-table/project-update-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProjectUpdateDialogComponent } from './project-update-dialog/project-update-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortPipe } from './sort.pipe';
import { constants, Constants } from './common/constants';
import { EmployeeUpdateTableComponent } from './employee-update-table/employee-update-table.component';
import { EmployeeUpdateDialogComponent } from './employee-update-dialog/employee-update-dialog.component';
import { ProgramUpdateComponent } from './program-update/program-update.component';
import { ProgramAddDialogComponent } from './program-add-dialog/program-add-dialog.component';
import { ProgramEditDialogComponent } from './program-edit-dialog/program-edit-dialog.component';
import { MatRadioModule } from '@angular/material/radio';

export function MSALInstanceFactory() : IPublicClientApplication {
  return new PublicClientApplication({
    auth:{
      clientId:constants.MsalLoginValues.clientId,
      redirectUri:constants.MsalLoginValues.redirectUri,
      authority:constants.MsalLoginValues.authority,
      postLogoutRedirectUri:constants.MsalLoginValues.postLogoutRedirectUri
    },
    cache: {
              cacheLocation: BrowserCacheLocation.LocalStorage,
              storeAuthStateInCookie: true,
          }
  })
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(constants.MsalLoginValues.api, ['api://8a1ddf7c-8e40-4b46-8a57-f2defba9ac9e/AccessAsUser']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}


@NgModule({
  declarations: [
    AppComponent,
    TimesheetEntryComponent,
    TimesheetSupervisorComponent,
    EmployeetaskhistoryComponent,
    SupervisordashboardComponent,
    SelfTaskHistoryComponent,
    projectSaveComponent,
    ProjectUpdateComponent,
    EmployeeSaveComponent,
    EmployeeUpdateComponent,
    EmployeeDashboardComponent,
    EmployeeUpdateComponent,
    LoginsComponent,
    EmployeeProjectAllocationComponent,
    ProjectManagerViewComponent,
    ProjectTaskSaveComponent,
    ManagerDashboardComponent,
    ProjectListComponent,
    SortPipe,
    ProjectUpdateTableComponent,
    ProjectUpdateDialogComponent,
    EmployeeUpdateTableComponent,
    EmployeeUpdateDialogComponent,
    ProgramUpdateComponent,
    ProgramAddDialogComponent,
    ProgramEditDialogComponent
    
  ],
  imports: [
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MDBBootstrapModule,
    FormsModule ,        
    ReactiveFormsModule,
    CommonModule,
    ToastrModule.forRoot(),
    NgbModule,
    MsalModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule

        
  ],
  providers: [SharedService,
    {
      provide:MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }],
  bootstrap: [AppComponent],
  entryComponents: [ProjectUpdateDialogComponent, EmployeeUpdateDialogComponent, ProgramAddDialogComponent, ProgramEditDialogComponent]
})

export class AppModule { }

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { environment } from 'src/environments/environment';
import { supDashboardValuesReponse } from '../businessObjects/dashBoardValues';
import { projectList } from '../businessObjects/projectList';
import { Employees, supervisorDashboardValuesReponse } from '../businessObjects/supervisorDashboardResponse';
import { employeesInfo } from '../businessObjects/employeeInfo';
import { constants } from '../common/constants';
import { projectService } from '../services/project.service';
import { SupervisorActionService } from '../services/supervisorAction.service';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { TimeEntryService } from '../services/timeentry.service';
import { type } from 'os';
declare var $: any;
@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.css']
})
export class ManagerDashboardComponent {
  @ViewChild('pieCanvas') private pieCanvas: ElementRef;
  @ViewChild('barCanvas') private barCanvas: ElementRef;
  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  doughnutChart: any;
  pieChart: any;
  barChart: any;
  public startDate1:any;
  public endDate1:any;
  public envConstants= environment;
  public appConstants = constants;
  public dashBoardVals:supDashboardValuesReponse;
  public employees:Employees[];
  public employeesProject:Employees[];
  public selectedProjectID:any;
  public pageSize:any= 5;
  public pSize:any= 5;
  public page:any= 1;
  public pg:any = 1;
  collectionSize: number;
  collSize: number;
  PrjList: projectList[]; 
  public projectList:any;
  constructor(private supervisorActionService: SupervisorActionService,private projListService: projectService, private timeEntryService: TimeEntryService) { }
  public dashBoardBoxes:supervisorDashboardValuesReponse={
    totalEmployees:0,
    approvedRequests:0,
    id:0,
    pendingRequests:0,
    rejectedRequests:0,
    totalHoursSpent:0,
    totalProjects:0
  }

  public getTimeSheetRes: getTimeSheetEntryResponse[];
  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID: '',
    endDate: '',
    startDate: '',
    status: '',
    manangerID: ''
  }

  public employeePendingTimesheet: Employees[] = [];
  public empList :employeesInfo[] = [];
  public empPendingTimesheetList :employeesInfo[] = [];

  ngAfterViewInit(): void {
    this.getData();
  }

  ngOnInit(): void {
    this.getProjectIdList();
    this.setDatesForOneWeekAgo();
    this.selectedProjectID=-1;
    this.getData();
    $( ".hamburger" ).click();
  }

  selectProject(filterVal: any) {
    this.selectedProjectID=filterVal.value;
    this.getData();
  }

   //gets the current date
  getCurrentDate(): string {
    var date = new Date();
    let currentDate = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) +"-"+("0" + date.getDate()).slice(-2);
    return currentDate;
  }

  setDatesForOneWeekAgo(){
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    if(stDate.getDay() != 1){
      for(let i = stDate.getDay(); i != 1 ; i--){
        stDate.setDate(stDate.getDate() - 1);
      }
    }
    let enDt = new Date(stDate);
    enDt.setDate(enDt.getDate() + 6);
    let prevWeekStDate = stDate.getFullYear() + "-" + ("0"+(stDate.getMonth() + 1)).slice(-2) +"-"+("0" + (stDate.getDate())).slice(-2);
    let prevWeekEndDate = enDt.getFullYear() + "-" + ("0"+(enDt.getMonth() + 1)).slice(-2) +"-"+("0" + (enDt.getDate())).slice(-2);
    this.startDate1 = prevWeekStDate;
    this.endDate1 = prevWeekEndDate;
  }

  setDatesForLastQuarter(){
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    let lastQuarterStartDate = startFullQuarter.getFullYear() + "-" + ("0"+(startFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + startFullQuarter.getDate()).slice(-2);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    let lastQuarterEndDate = endFullQuarter.getFullYear() + "-" + ("0"+(endFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + endFullQuarter.getDate()).slice(-2);
    this.startDate1 = lastQuarterStartDate;
    this.endDate1 = lastQuarterEndDate;
  }

  setDatesForLastMonth(){
    let date = new Date();
    let prevMonthStDate = date.getFullYear() + "-" + ("0"+(date.getMonth())).slice(-2) +"-"+("0" + 1).slice(-2);
    const prevMonthEndDt = new Date(date.getFullYear(), date.getMonth(), 0);
    let prevMonthEndDate = prevMonthEndDt.getFullYear() + "-" + ("0"+(prevMonthEndDt.getMonth() + 1)).slice(-2) +"-"+("0" + prevMonthEndDt.getDate()).slice(-2);
    this.startDate1 = prevMonthStDate;
    this.endDate1 = prevMonthEndDate;
  }

  showLastMonth(){
    this.setDatesForLastMonth();
    this.showChange();
  }

  showLastWeek(){
    this.setDatesForOneWeekAgo();
    this.showChange();
  }

  showLastQuarter(){
    this.setDatesForLastQuarter();
    this.showChange();
  }  

  public showChange()
  {
    this.getData();
  }

  getProjectIdList() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectByManager;
    let managerID = localStorage.getItem("userID");
    this.projListService.getProjetListByManager(apiUrl,managerID).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
    });
  }
  
  public getData(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getManagerDashboard;
    let userId=localStorage.getItem("userID");
    this.supervisorActionService.getManagerDashboardValues(apiUrl,userId,-1,this.startDate1,this.endDate1,this.selectedProjectID)
      .subscribe((supervsorValues: supDashboardValuesReponse) => { 
        this.dashBoardBoxes.approvedRequests=supervsorValues.supervisorDashboard.approvedRequests;
        this.dashBoardBoxes.pendingRequests=supervsorValues.supervisorDashboard.pendingRequests;
        this.dashBoardBoxes.rejectedRequests=supervsorValues.supervisorDashboard.rejectedRequests;
        this.dashBoardBoxes.totalEmployees=supervsorValues.supervisorDashboard.totalEmployees;
        this.dashBoardBoxes.totalHoursSpent=supervsorValues.supervisorDashboard.totalHoursSpent;
        this.dashBoardBoxes.totalProjects=supervsorValues.supervisorDashboard.totalProjects;
        let object:any[]=supervsorValues.pieChart;
        let objectValues:any[]=[];
        let objectParams:any[]=[];
          
        object.forEach(element => {
          objectValues.push(element.pieCount);
          objectParams.push(element.pieFullName);
        });
          
        if(this.pieChart){
          this.pieChart.destroy();
        } 
        
        this.pieChartBrowser(objectValues,objectParams);
        object=[];objectValues=[];objectParams=[];
        object=supervsorValues.donoughtChart;
        if(object!=undefined){
          object.forEach(element => {
            objectValues.push(element.projectName);
            objectParams.push(element.projectHours);
          });
        }
        if(this.doughnutChart){
           this.doughnutChart.destroy();
        } 
          
        this.doughnutChartMethod(objectValues,objectParams);

        object=[];objectValues=[];objectParams=[];
        object=supervsorValues.barChart;
        if(object!=undefined){
          object.forEach(element => {
            objectValues.push(element.name);
            objectParams.push(element.totalHoursSpent);
          });
        }
        if(this.barChart){
          this.barChart.destroy();
        } 
          
        this.barChartMethod(objectValues,objectParams);
        this.employees=supervsorValues.employees;
        this.pendingTimesheetSubmitions();
        this.collectionSize=this.employees.length;
        this.employeesProject=supervsorValues.employeesProject;
    });
  }

  pieChartBrowser(dataArray:any[],valueArray:any[]): void {
    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
      options: {
        tooltips: {
           mode: 'index',
           intersect: false
        },
        hover: {
           mode: 'index',
           intersect: false
        }
     },
      data: {
        labels:valueArray ,

        datasets: [{
          backgroundColor: [
            '#2ecc71',
            '#3498db',
            '#95a5a6',
            '#9b59b6',
            '#f1c40f',
            '#e74c3c'
          ],
          fill: false,
          lineTension: 0.1,
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataArray,
        }]
      }
    });
  }

  doughnutChartMethod(dataArray:any[],valueArray:any[]) {
    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      options: {
        tooltips: {
           mode: 'index',
           intersect: false
        },
        hover: {
           mode: 'index',
           intersect: false
        }
     },
      data: {
        labels: dataArray,
        datasets: [{
          label: '# of Hours',
          data: valueArray,
          backgroundColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(211, 101, 55, 1)',
            'rgba(101, 101, 55, 1)',
            'rgba(55, 101, 55, 1)',
            'rgba(28, 33, 55, 1)',
            'rgba(89, 43, 4, 1)',
            'rgba(1, 45, 33, 1)',
            'rgba(56, 3, 44, 1)',
          ],
          hoverBackgroundColor: [
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)',
            'rgba(225,225,225,1)'
          ]
        }]
      }
    });
  }
  
  barChartMethod(dataArray:any[],valueArray:any[]) {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: dataArray,
        datasets: [{
          label: '# of Hours',
          data: valueArray,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(211, 101, 55, 0.2)',
            'rgba(101, 101, 55, 0.2)',
            'rgba(55, 101, 55, 0.2)',
            'rgba(28, 33, 55, 0.2)',
            'rgba(89, 43, 4, 0.2)',
            'rgba(1, 45, 33, 0.2)',
            'rgba(56, 3, 44, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest',
       },
       hover: {
          mode: 'index',
          intersect: false
       }
      }
    });
  }

  getDailyTimeSheetEntries(dateStart :any, dateEnd :any) {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
    let errorMessages = this.appConstants.errorMessages;
    this.getTimeSheetReq.employeeID = -1;
    this.getTimeSheetReq.startDate = dateStart;
    this.getTimeSheetReq.endDate = dateEnd;
    //this.getTimeSheetReq.status = constants.status.SentforApproval;
    this.getTimeSheetReq.manangerID = localStorage.getItem("userID");
    console.log(`Calling the ${apiUrl} api...`);
    return this.timeEntryService.getTimeSheetEntries(apiUrl, this.getTimeSheetReq);
  }

  pendingTimesheetSubmitions(){
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    if(stDate.getDay() != 1){
      for(let i = stDate.getDay(); i != 1 ; i--){
        stDate.setDate(stDate.getDate() - 1);
      }
    }
    let enDt = new Date(stDate);
    enDt.setDate(enDt.getDate() + 6);
    let prevWeekStDate = stDate.getFullYear() + "-" + ("0"+(stDate.getMonth() + 1)).slice(-2) +"-"+("0" + (stDate.getDate())).slice(-2);
    let prevWeekEndDate = enDt.getFullYear() + "-" + ("0"+(enDt.getMonth() + 1)).slice(-2) +"-"+("0" + (enDt.getDate())).slice(-2);
    
    this.getDailyTimeSheetEntries(prevWeekStDate,prevWeekEndDate).subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
      this.getTimeSheetRes = getTimeSheetReponse;
      this.employeePendingTimesheet.splice(0,this.employeePendingTimesheet.length);
      if(this.getTimeSheetRes.length){
        this.employees.forEach(element=>{
          let isTimesheetPresent = false;
          this.getTimeSheetRes.forEach(timeentry=>{
            if(element.fullName == timeentry.employeeName && element.description == timeentry.projectName && (timeentry.status == 2 || timeentry.status == 3)){
              isTimesheetPresent = true;
            }
          });
          if(!isTimesheetPresent){
            this.employeePendingTimesheet.push(element);
          }
        });
      }
      else{
        const copyEmp = JSON.parse(JSON.stringify(this.employees));
        this.employeePendingTimesheet = copyEmp;
      }
      this.empList = this.getCommaSeperatedProjects(this.employees, this.empList);
      this.empPendingTimesheetList = this.getCommaSeperatedProjects(this.employeePendingTimesheet, this.empPendingTimesheetList);
      this.collSize = this.empPendingTimesheetList.length;
      this.collectionSize = this.empList.length;
    });
  }

  /**
   * @function getCommaSeperatedProjects
   * @param emp 
   * @description add unique employees and assigned projects...
   */
  getCommaSeperatedProjects(emp :Employees[], commaList :employeesInfo[]){
    commaList = [];
    //#region add unique employees and assigned projects...
    for (let index = 0; index < emp.length; index++) {
      const element = emp[index];

      //check if empl already exist? if yes, add projct name only
      let isempExist: any = commaList.filter((value:any, key:any)=>{
        return value.userName.toString().toLowerCase().trim() === element.userName.toString().toLowerCase().trim()
      });

      if(isempExist.length > 0){
        let desc = isempExist[0].description +', '+ element.description;
        isempExist[0].description = desc;
      }
      else{
        let obj = {
          fullName: element.fullName,
          userName: element.userName,
          description: element.description
        }
        commaList.push(obj);   
      }
    }
    return commaList;
  }

}

<div class="container" style="max-width: 100%;">  
    <br/><br/><br/><br/> 
      <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Approval Request List</h3>
        <div class="row">
          
          <div class="col-md-2">          
            <div class="form-check-input col-md-5 offset-2 ">
              <select id="projectList" [(ngModel)]="projectList" class="form-control" style="width: 222px;margin-top: 14px;">
                <option value="-1" class="text-primary font-weight-bolder">
                  --- Select All ---
                </option>
                <option *ngFor="let sup of PrjList" value={{sup.id}}>
                    {{sup.name}}
              </select>
            </div>
        </div>
       </div>
       <div class="row" style="width: 100%;">  
      <table mat-table [dataSource]="PrjList" style="background: inherit"  class="mat-elevation-z12" matSort style="width: 100%;">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Project ID</th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
      
        <!-- Title Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name | slice: 0:30}} </td>
        </ng-container>
          <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description | slice: 0:30}} </td>
        </ng-container>
        <!-- UserId Column -->
        <ng-container matColumnDef="ManagerID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager </th>
          <td mat-cell *matCellDef="let element" > {{element.ManagerID}} </td>
        </ng-container>
        <!-- Body Column -->
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell *matCellDef="let element"> {{element.startDate | slice: 0:50}} </td>
        </ng-container>
      
        <!-- UserId Column -->
        <ng-container matColumnDef="completedDt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
          <td mat-cell *matCellDef="let element"> {{element.completedDt}} </td>
        </ng-container>
  
       
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align:center;"> Actions </th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex;align-items: center;justify-content: flex-end;">
              <button type="button"  class="btn btn-success">Approve</button> &nbsp;
              <button type="button"  class="btn btn-danger">Reject</button>
            
          </div>
          </td>
        </ng-container>
        
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
       </div>
  <br>
       
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:"https://timesheetapi.purecs.ae/api/",
   //apiUrl:"https://localhost:44335/api/",
  //apiUrl:"http://172.31.2.171/TimeSheetWebAPI/api/",
  saveEmployeeUrl:"Employees/SaveEmployeeDetails/",
  GetAllEmployeesUrl:"Employees/GetEmployeeListDetails",
  updateEmployeeDetailsUrl:'Employees/UpdateEmployeeDetails/',
  saveProject:'Projects/SaveProject/',
  updateProjectUrl:'Projects/updateproject/',
  getProjectListUrl:'Projects/GetProject',
  getProjectById:'Projects/GetProjectDetails/',
  getEmployeeById:'Employees/GetEmployeeDetails/',
  getEmployeeBySupervisorId:'Employees/GetEmployeeBySupervisorId/',
  getAllSuperVisorListDetails:'Employees/GetAllSuperVisorListDetails',
  getAllManagerListDetails:'Employees/GetAllManagerListDetails',
  AddOrRemoveEmployeeToProject:'Admin/AddOrRemoveEmployeeToProject',

  postTimeEntry: 'ResourceEntries/ResourceEntry',
  getTimeSheetEntries:'ResourceEntries/ResourceEntry',
  approveOrRejectTE :'Admin/ApproveOrRejectEntry',
  getProjectByEmpID :'Projects/ProjectListDetails',
  getTaskByProjID :'Projects/GetSubTasksByPid',
  getProjectByManager:'Projects/ProjectListByManager/',
  saveProjectTask:'Projects/SaveProjectTask',
  userExists:'Employees/UserExists',
  getSupervisorDashboard:'Dashboard/GetSupervisorDashboard',
  getManagerDashboard:'Dashboard/GetManagerDashboard',
  getUserDashboard:'Dashboard/GetUserDashboard',
  login:'login',
  deleteTimeSheetEntry:'ResourceEntries/DeleteWeekEntry',
  saveProgramUrl:'Projects/SaveProgram',
  updateProgramUrl:'Projects/UpdateProgram/',
  getProgramDetailsUrl:'Projects/GetProgramDetails/',
  getTimeSheetEntriesSupervisorUrl:'ResourceEntries/GetEmployeesBySupervisor',
  getDepartments:'Employees/GetAllDepartments'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { programSaveReq, programSaveRes, programGetResp } from '../businessObjects/program';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramService } from '../services/program.service';

@Component({
  selector: 'app-program-edit-dialog',
  templateUrl: './program-edit-dialog.component.html',
  styleUrls: ['./program-edit-dialog.component.css']
})
export class ProgramEditDialogComponent implements OnInit {
  public envConstants = environment;
  updateProgramReq :programSaveReq;
  progId: any;
  constructor(private fb :FormBuilder, private dialogRef :MatDialogRef<ProgramEditDialogComponent>,
    private progService :ProgramService,@Inject(MAT_DIALOG_DATA) data:programSaveReq){
      this.updateProgramReq = data;
      this.progId = this.updateProgramReq.programId;
  }

  ngOnInit(): void {
  }

  public programForm = this.fb.group({
    programName: new FormControl('',[Validators.required]),
    manager:new FormControl('',[Validators.required]),
    costCenter:new FormControl('',[Validators.required])
  });


  close(){
    this.dialogRef.close();
  }

  update(){
    this.updateProgram().subscribe((saveResp :programSaveRes)=> this.dialogRef.close({response: "success"}),
    error=>this.dialogRef.close({response: "Failed To Update Program"}));
  }

  updateProgram(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.updateProgramUrl;
    this.progId = parseInt(this.progId);
    this.updateProgramReq.name = this.updateProgramReq.name.toString().toLowerCase().trim();
    this.updateProgramReq.manager = this.updateProgramReq.manager.toString().toLowerCase().trim();
    this.updateProgramReq.costCenter = this.updateProgramReq.costCenter.toString().toLowerCase().trim();
    return this.progService.updateProgram(apiUrl, this.progId, this.updateProgramReq);
  }

}

import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { constants } from '../common/constants';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { environment } from 'src/environments/environment';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { TimeEntryService } from '../services/timeentry.service';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
declare var $: any;
export interface Post {
  id: number;
  title: string;
  body: string;
  userId: number;
}
@Component({
  selector: 'app-employeetaskhistory',
  templateUrl: './employeetaskhistory.component.html',
  styleUrls: ['./employeetaskhistory.component.css']
})
export class EmployeetaskhistoryComponent implements OnInit {

  public startDate:any;
  public selectedWeek:any;
   public dateStart:any;
   public selectedEmpID:any;
   dataSource:any;
   public getTimeSheetRes: getTimeSheetEntryResponse[];
   public suprVRes: superVisorRes[];
   public envConstants= environment;
   public appConstants = constants;
  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID: 0,
    startDate: '',
    endDate: '',
    status:0
  };
  public dateEnd:any;
  displayedColumns: string[] = ['project','task','mon','tue','wed','thu','fri','sat','sun','total','status'];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  recordsExist: boolean;
  constructor(private http: HttpClient,private timeEntryService:TimeEntryService,private emplservice: EmployeeService) { }
  ngOnInit(): void {
    $( ".hamburger" ).click();

    this.getEmployees();
    this.setDefaultWeek();
    var startDate,
    endDate;
    $('#weekpicker1').datepicker({
      autoclose: true,
      format :'mm/dd/yyyy',
      forceParse :false
  }).on("changeDate", (e:any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+7);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
      this.recordsExist=false;
      this.getDailyTimeSheetEntries();
      
  });

    
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngViewAfterInit()
  {
   var startDate,
   endDate;
   
 $('#weekpicker').datepicker({
   autoclose: true,
   format :'mm/dd/yyyy',
   forceParse :false
}).on("changeDate", function(e:any) {
   var date = e.date;
   startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
   endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+7);
   //$('#weekpicker').datepicker("setDate", startDate);
   $('#weekpicker1').datepicker('update', startDate);
   $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
   
});

}
  fetchPosts(): void {
    this.http.get('https://jsonplaceholder.typicode.com/posts').subscribe((data) => {
      this.dataSource.data = data as Post[];
    })

}
onFocusOutEvent()
{
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
     //$('#weekpicker').datepicker('update', startDate); 
     $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());

}
setDefaultWeek() {
  let dateToday = new Date();
  let startDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - (dateToday.getDay()-1));
  let  endDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - dateToday.getDay()+7);
   $('#weekpicker1').datepicker('update', startDate);
   $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
   this.getDailyTimeSheetEntries();
}

getDateValue()
{
  this.selectedWeek=$('#weekpicker1').val();
  var startDt:'';
  var endDt:'';
  if(this.selectedWeek)
  {
    const chars = this.selectedWeek.split('-');
    startDt=chars[0];
    let firstDate = new Date(startDt)
    endDt=chars[1];
    let secondDate = new Date(endDt)
    let startMonth=(firstDate.getMonth()+1).toString(),endMonth=(secondDate.getMonth()+1).toString(),
    startDay=firstDate.getDate().toString(),EndDay=secondDate.getDate().toString();

    if((startMonth.length)==1)
    {
      startMonth="0"+startMonth;
    }
    if((endMonth.length)==1)
    {
      endMonth="0"+endMonth;
    }
    if(startDay.length==1)
    {
      startDay="0"+startDay;
    }
    if(EndDay.length==1)
    {
      EndDay="0"+EndDay;
    }
    this.dateStart=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
    this.dateEnd=secondDate.getFullYear()+'-'+endMonth+'-'+EndDay;
  }
}

getEmployees() {
    
  let apiUrl = this.envConstants.apiUrl + this.envConstants.getEmployeeBySupervisorId;
  let supervisorHistory=localStorage.getItem("userID");
  this.emplservice.getSuperVisorList(apiUrl,supervisorHistory).subscribe((SuprVRes: superVisorRes[]) => {
    this.suprVRes = SuprVRes;
  }
  );

}
changeEmp(event?:any)
{
  this.selectedEmpID=event.target.value;
  this.getDailyTimeSheetEntries();

}
next(val:any)
{
  if(val==1)
  {
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
     startDate.setDate(startDate.getDate()-7)
     endDate.setDate(endDate.getDate()-7)
     //$('#weekpicker').datepicker('update', startDate); 
     $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
     this.getDailyTimeSheetEntries();
  }
  if(val==2)
  {
    let startDate = new Date(this.dateStart);
    let  endDate =new Date(this.dateEnd);
     startDate.setDate(startDate.getDate()+7)
     endDate.setDate(endDate.getDate()+7)
     //$('#weekpicker').datepicker('update', startDate); 
     $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' +  endDate.getFullYear());
     this.getDailyTimeSheetEntries();
  }

}
getDailyTimeSheetEntries() {
  this.getDateValue();
   this.dataSource=this.getTimeSheetRes=[];
  let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
  let errorMessages = this.appConstants.errorMessages;
  this.getTimeSheetReq.employeeID=this.selectedEmpID;
  this.getTimeSheetReq.startDate=this.dateStart;
  this.getTimeSheetReq.endDate=this.dateEnd;
  this.getTimeSheetReq.manangerID=36559;// 36559 value is set to differentiate in API between manager call and employee call;
  console.log(`Calling the ${apiUrl} api...`);
  //api call
  this.timeEntryService.getTimeSheetEntries(apiUrl,this.getTimeSheetReq)
  .subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
  this.getTimeSheetRes=(getTimeSheetReponse);
  if(this.getTimeSheetRes.length>0)
  {
    this.recordsExist=true;
    this.dataSource =new MatTableDataSource(this.getTimeSheetRes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   
  }

});
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}

<div class="container" style="max-width: 100%">
  <br /><br /><br /><br />
  <h3 class="card-header text-center font-weight-bold text-uppercase py-4">
    Supervisor dashboard
  </h3>
  <!-- <div class="row"></div>
  <div class="row"><br /></div> -->
  <div class="row row-cols-5">
    <div class="col  mx-auto">
      <div class="card text-white bg-secondary mb-3" style="max-width: 15rem">
        <div class="card-header">Total Projects</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.totalProjects }}
          </h1>
        </div>
      </div>
    </div>

    <div class="col  mx-auto">
      <div class="card text-white bg-warning mb-3" style="max-width: 15rem">
        <div class="card-header">Total Employees</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.totalEmployees }}
          </h1>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-2">
      <div class="card text-white bg-success mb-3" style="max-width: 15rem">
        <div class="card-header">Total hours spent</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.totalHoursSpent }}
          </h1>
        </div>
      </div>
    </div> -->
    <div class="col  mx-auto">
      <div class="card text-white bg-secondary mb-3" style="max-width: 15rem">
        <div class="card-header">Pending Submissions</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ employeePendingTimesheet.length }}
          </h1>
        </div>
      </div>
    </div>

    <div class="col  mx-auto">
      <div class="card text-white bg-info mb-3" style="max-width: 15rem">
        <div class="card-header">Pending Requests for Approval</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.pendingRequests }}
          </h1>
        </div>
      </div>
    </div>

    <div class="col  mx-auto">
      <div class="card text-white bg-success mb-3" style="max-width: 15rem">
        <div class="card-header">Rejected Requests</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.rejectedRequests }}
          </h1>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-2">
      <div class="card text-white bg-dark mb-3" style="max-width: 15rem">
        <div class="card-header">Approved Requests</div>
        <div class="card-body">
          <h1 class="card-title" style="text-align: center">
            {{ dashBoardBoxes.approvedRequests }}
          </h1>
        </div>
      </div>
    </div> -->
  </div>

  <div class="d-flex justify-content-around">
    <div class="col-lg-2"></div>
    <div class="col-md-1"></div>

    <div class="col-lg-2 mb-3 mb-md-0">
      <label for="startdate" class="h5 mt-md-2">Select Employee</label>
      <select
        id="employeeList"
        [(ngModel)]="employeeList"
        class="form-control"
        style="width: 222px"
        (change)="selectEmployee($event.target)"
      >
        <option value="-1" class="text-primary font-weight-bolder">
          --- Select All ---
        </option>
        <option *ngFor="let sup of suprVRes | order_by:'fullName'" value="{{ sup.id }}">
          {{ sup.fullName | titlecase }}
        </option>
      </select>
    </div>

    <div class="col-lg-2 mb-3 mb-md-0">
      <label for="startdate" class="h5 mt-md-2">Start Date</label>
      <input
        type="date"
        id="startDate2"
        [(ngModel)]="startDate2"
        (ngModelChange)="showChange()"
        class="form-control"
      />
    </div>

    <div class="col-lg-2 mb-3 mb-md-0">
      <label for="firstName" class="h5 mt-md-2">End Date</label>
      <input
        type="date"
        id="endDate2"
        [(ngModel)]="endDate2"
        (ngModelChange)="showChange()"
        class="form-control"
      />
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastWeek()"
        checked="checked"
        type="radio"
        id="lastWeek"
        name="selectDates"
      />
      <label class="form-check-label" for="lastWeek">Last-Week</label>
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastMonth()"
        type="radio"
        id="lastMonth"
        name="selectDates"
      />
      <label class="form-check-label" for="lastMonth">Last-Month</label>
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastQuarter()"
        type="radio"
        id="lastQuarter"
        name="selectDates"
      />
      <label class="form-check-label" for="lastQuarter">Last-Quarter</label>
    </div>

  </div>
  <div class="row"><br /></div>
  <div class="row">
    <div class="col-md-6" style="zoom: 1.35">
      <h1>Pending Requests(Hrs.)</h1>
      <canvas #pieCanvas></canvas>
      <hr color="red" />
    </div>

    <div class="col-md-6" style="zoom: 1.3">
      <h1>Projects worked on(Hrs.)</h1>
      <canvas #barCanvas></canvas>
      <hr color="red" />
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-md-6" style="zoom: 1.33">
        <h1>Total Approved Hours</h1>
        <canvas #doughnutCanvas></canvas>
        <hr color="red" />
      </div>
      <div class="col-md-6 tableEmpPending">
        <h2>Pending Timesheet Submissions</h2>
        <div class="container table-responsive py-1">
          <table
            class="table table-bordered table-hover tablDash"
            style="padding: 0.45rem"
          >
            <thead class="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Username</th>
                <th scope="col">Project Name</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let employee of empPendingTimesheetList
                    | slice: (page - 1) * pageSize:page * pageSize;
                    index as i
                "
              >
                <td>{{ employee.fullName | titlecase }}</td>
                <td>{{ employee.userName }}</td>
                <td>{{ employee.description }}</td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination
            *ngIf="empPendingTimesheetList"
            [collectionSize]="collSize"
            [(page)]="page"
            [maxSize]="10"
            [boundaryLinks]="true"
            [pageSize]="pageSize"
            class="d-flex justify-content-center"
          ></ngb-pagination>
        </div>
        <hr color="red" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 tableEmp">
        <h1>Total Assigned Employees</h1>
        <div class="container table-responsive py-1">
          <table
            class="table table-bordered table-hover tablDash"
            style="padding: 0.45rem"
          >
            <thead class="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">User ID</th>
                <th scope="col">Project Name</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let employee of empList
                    | slice: (pg - 1) * pSize:pg * pSize;
                  index as j
                "
              >
                <td>{{ employee.fullName | titlecase }}</td>
                <td>{{ employee.userName }}</td>
                <td>{{ employee.description }}</td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination
            *ngIf="empList"
            [collectionSize]="collectionSize"
            [(page)]="pg"
            [maxSize]="10"
            [boundaryLinks]="true"
            [pageSize]="pSize"
            class="d-flex justify-content-center"
          ></ngb-pagination>
        </div>
        <br />
        <hr color="red" />
      </div>  
    </div>
  </div>
</div>

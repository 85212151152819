import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { projectList } from '../businessObjects/projectList';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { projectTaskSaveReq } from '../businessObjects/projectTaskSaveReq';
import { projectTaskSaveResp } from '../businessObjects/projectTaskSaveResp';
import { subTaskByProjIDResponse } from '../businessObjects/projectRequest';
import { projectService } from '../services/project.service';
import { ProjectService } from '../services/projects.service';
declare var $: any;
@Component({
  selector: 'app-project-task-save',
  templateUrl: './project-task-save.component.html',
  styleUrls: ['./project-task-save.component.css']
})
export class ProjectTaskSaveComponent implements OnInit {

  constructor(private Pf: FormBuilder,private projListService: projectService,
    private projectTaskService: ProjectService) { }
  PrjList: projectList[];
  tskList: subTaskByProjIDResponse[] | undefined;
  
  public envConstants=environment;
  
  
  public prjTaskSReq:projectTaskSaveReq={
    projectId:'',
    Name:'',
    createdBy:0,
  }
  
  public prjTaskSRes:projectTaskSaveResp={
    errorMessage:'',
    projectSubTaskId:''
  }

  public projectForm = new FormGroup({
    TaskName: new FormControl('',[Validators.required]),
    projectList:new FormControl('',[Validators.required]),
    taskList:new FormControl('',[])  
  
  });

  ngOnInit(): void {
    $( ".hamburger" ).click();
    this.getProjectIdList();
    this.projectForm.controls['taskList'].disabled;
  }

  getProjectIdList() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectListUrl;
    this.projListService.getProjectIdList(apiUrl).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
    });
  }

  getProjectTaskList(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTaskByProjID;
    this.prjTaskSReq.projectId=parseInt(this.prjTaskSReq.projectId);
    this.projectTaskService.getTaskListByProjID(apiUrl, this.prjTaskSReq.projectId).subscribe((taskList: subTaskByProjIDResponse[])=>{
      this.tskList = taskList;
    });
  }

  prjSelChange(event: Event){
    this.getProjectTaskList();
  }
 
  submit(){
    let apiUrl = this.envConstants.apiUrl+this.envConstants.saveProjectTask;
    let user:any=localStorage.getItem("userID");
    this.prjTaskSReq.createdBy=parseInt( user)
    this.prjTaskSReq.projectId=parseInt( this.prjTaskSReq.projectId)
    this.projListService.saveProjectTask(apiUrl, this.prjTaskSReq).subscribe((data) => {
      this.prjTaskSRes = data;

      if (this.prjTaskSRes.projectSubTaskId>0){
        Swal.fire({
          icon: 'success',
          title: this.prjTaskSRes.errorMessage,
          showConfirmButton: false,
          timer: 3000
        })
        this.getProjectTaskList();
        this.projectForm.controls['TaskName'].reset();
      }
      else{
        Swal.fire({
          icon: 'error',
          title: this.prjTaskSRes.errorMessage,
         
          timer: 3000
        })
      }
    }); 
  }

  get projectList(){
    return this.projectForm.get('projectList');
  }

  get TaskName(){
    return this.projectForm.get('TaskName');
  }

  reset(){
    this.projectForm.reset();
    this.tskList?.splice(0,this.tskList.length);
    this.prjSelChange(new Event("onchange"));
  }

}

import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { EmployeeService } from '../services/employee.service';
import { projectList } from '../businessObjects/projectList';
import { projectService } from '../services/project.service';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectUpdateDialogComponent } from '../project-update-dialog/project-update-dialog.component';
import { superVisorRes } from '../businessObjects/superVisorRes';
declare var $: any;

@Component({
  selector: 'app-project-update-table',
  templateUrl: './project-update-table.component.html',
  styleUrls: ['./project-update-table.component.css']
})
export class ProjectUpdateTableComponent implements OnInit {

  dataSource = new MatTableDataSource<projectList>([]);
  public envConstants=environment;
  public projectId: any;
  PrjList: projectList[];
  managerList: superVisorRes[];
  displayedColumns: string[] = ['id', 'name', 'description','startDate','completedDt','ManagerID','isActive','actions'];
  
  public Prj: projectList = {
    id: '',
    name: '',
    description: '',
    managerID: '',
    startDate: '',
    completedDt: '',
    costCenter: '',
    programId: '',
    billable: ''
  }

  public prjSRes: projectSaveResp = {
    Success: '',
    StatusCode: '',
    ProjectID: ''
  }

  constructor(private projListService: projectService, private emplservice: EmployeeService, private dialog: MatDialog) { }

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  ngOnInit(): void {
    $( ".hamburger" ).click();
    this.getProjectIdList();
    this.getAllManagers();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getProjectIdList(){ 
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectListUrl;
    this.projListService.getProjectIdList(apiUrl).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
      this.dataSource = new MatTableDataSource<projectList>(this.PrjList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openEditDialog(projectId: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.getProjectFromId(projectId).subscribe( (prjList: projectList) =>{
      dialogConfig.data = prjList;
      const dialogRef = this.dialog.open(ProjectUpdateDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res=>{
        if(res.response === "success"){
          Swal.fire({
            icon: 'success',
            title: 'Project has been updated successfully',
            showConfirmButton: false,
            timer: 1500
          });
          this.getProjectIdList();
        } 
        else{
          Swal.fire({
            icon: 'error',
            title: res.response,
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    },
    (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Edit Operation Failed',
        showConfirmButton: false,
        timer: 1500
      });
      console.error(err);
    });
  }

  getProjectFromId(pId: number){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectById;
    return this.projListService.getProjetListById(apiUrl, pId);
  }

  getAllManagers(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllManagerListDetails;
    this.emplservice.getManagers(apiUrl).subscribe((managerRes: superVisorRes[])=> this.managerList = managerRes);
  }

  getManager(model: any){
    let found = this.managerList.find(mgr => mgr.id === model.managerID);
    return found?.fullName;
  }
  
  showStartDate(model: any){
    const dt = new Date(model.startDate);
    return dt.toLocaleDateString();
  }

  showEndDate(model: any){
    const dt = new Date(model.completedDt);
    return dt.toLocaleDateString();
  }

  isActive(model: any){
    const startDt = new Date(model.startDate);
    const endDt = new Date(model.completedDt);
    const today = new Date(Date.now());
    if(endDt.getTime() >= today.getTime() && startDt.getTime() <= today.getTime())
      return 'Yes';
    else
      return 'No';
  }

}
<div class="container" style="max-width: 100%;">  
    <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Update Employee</h3>
    <div class="filter-container">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search Employees" #input>
        </mat-form-field>
    </div>
    <div class="table-container mat-elevation-z8" >
        <table mat-table [dataSource]="dataSource" style="background: inherit"  class="mat-elevation-z8" matSort style="width: 100%;">
            <!-- No Data Column -->
            <div *matNoDataRow style="margin-left: 50vw;">
                No Records Found
            </div>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Employee Full Name Column -->
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</th>
                <td mat-cell *matCellDef="let element"> {{ element.fullName | titlecase}} </td>
            </ng-container>

            <!-- Department Column -->
            <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
                <td mat-cell *matCellDef="let element"> {{ showDepartment(element) }} </td>
            </ng-container>

            <!--Supervisor Column-->
            <ng-container matColumnDef="supervisorID">
                <th mat-header-cell *matHeaderCellDef > Supervisor </th>
                <td mat-cell *matCellDef="let element" > {{ showSupervisor(element) | titlecase}} </td>
            </ng-container>

            <!-- Date of Joining Column -->
            <ng-container matColumnDef="doj">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Joining </th>
                <td mat-cell *matCellDef="let element"> {{ showDateOfJoining(element) }} </td>
            </ng-container>

            <!-- Action Buttons -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="text-align:center;"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        <button type="button"  class="btn btn-success" (click)="openEditDialog(element.id)">Edit</button> &nbsp;
                        <!-- <button type="button"  class="btn btn-danger">Delete</button> -->
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="paginator">
        <mat-paginator
            [pageSize]="15"
            [hidePageSize]="true"
            showFirstLastButtons aria-label="Select page">
        </mat-paginator>
    </div>
    <div class="above-footer"></div>
</div>

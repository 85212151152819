import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import{ MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { projectList } from '../businessObjects/projectList';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { programGetResp } from '../businessObjects/program';
import { EmployeeService } from '../services/employee.service';
import { projectService } from '../services/project.service';
import { ProgramService } from '../services/program.service';
import { projectSaveReq } from '../businessObjects/projectSaveReq';


@Component({
  selector: 'app-project-update-dialog',
  templateUrl: './project-update-dialog.component.html',
  styleUrls: ['./project-update-dialog.component.css']
})

export class ProjectUpdateDialogComponent implements OnInit {
  public envConstants=environment;
  project: projectList;
  projectUpdateReq :projectSaveReq={
    id:0,
    name:'',
    description:'',
    managerId: '',
    startDate: '',
    completedDt: '',
    CostCenter: '',
    ProgramID: '',
    billable: '',
    CreatedBy:0
  };
  managerList: superVisorRes[];
  public programList :programGetResp[];
  constructor(private fb: FormBuilder,
    private emplservice: EmployeeService,
    private programService: ProgramService,
    private projService: projectService, 
    private dialogRef: MatDialogRef<ProjectUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data:projectList){
      this.project = data;
      let stDt = new Date(this.project.startDate);
      let endDt = new Date(this.project.completedDt);
      this.project.startDate = stDt.toISOString();
      this.project.completedDt = endDt.toISOString();
  }

  startDateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !==2 && day !== 3 && day !== 4 && day !==5 && day !== 6;
  }
  
  endDateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 1 && day !==2 && day !== 3 && day !== 4 && day !==5 && day !== 6;
  }

  ProjectUpdateForm = this.fb.group({
    projectId: new FormControl('',[Validators.required]),
    projectName: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    manager: new FormControl('',[Validators.required]),
    startDate: new FormControl('',[Validators.required]),
    endDate: new FormControl('',[Validators.required]),
    programID: new FormControl('',[]),
    costcenter: new FormControl('',[Validators.required]),
    billablity: new FormControl('',[Validators.required])
  });

  ngOnInit(): void {
    this.getAllManagers();
    this.getPrograms();
  }

  update(){
    if(!this.validateDates()){
      this.dialogRef.close({response: "Invalid Dates"});
      return;
    }
    this.updateProject().subscribe((updateResp: projectSaveResp) => this.dialogRef.close({response: "success"}),
    (error) => this.dialogRef.close({response: "Failed To Update Project"}));
  }

  close(){
    this.dialogRef.close();
  }

  getAllManagers(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllManagerListDetails;
    this.emplservice.getManagers(apiUrl).subscribe((managerRes: superVisorRes[])=> this.managerList = managerRes);
  }

  updateProject() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.updateProjectUrl;
    this.projectUpdateReq.id=this.project.id;
    this.projectUpdateReq.managerId = parseInt(this.project.managerID);
    this.projectUpdateReq.ProgramID = parseInt(this.project.programId);
    this.projectUpdateReq.name = this.project.name.toString().trim();
    this.projectUpdateReq.description = this.project.description.toString().trim();
    this.projectUpdateReq.CostCenter = this.project.costCenter.toString().trim();
    const stDt = new Date(this.project.startDate);
    const startDt = this.adjustTimezone(stDt);
    const eDt = new Date(this.project.completedDt);
    const compDt = this.adjustTimezone(eDt);
    this.projectUpdateReq.startDate = startDt;
    this.projectUpdateReq.completedDt = compDt;
    this.projectUpdateReq.billable = this.project.billable;
    return this.projService.updateProject(apiUrl, this.project.id, this.projectUpdateReq);
  }

  adjustTimezone(date: Date):Date{
    let dt = new Date(date);
    const timeZoneOffset = dt.getTimezoneOffset();
    if(timeZoneOffset >= 0)
      dt = new Date( dt.getTime() + timeZoneOffset * 60000 );
    else
      dt = new Date( dt.getTime() - timeZoneOffset * 60000 );
    return dt;
  }

  validateDates():boolean{
    const stDt = new Date(this.project.startDate);
    const endDt = new Date(this.project.completedDt);
    if (stDt.getTime() < endDt.getTime() && stDt.getDay() == 1 && endDt.getDay() ==0)
      return true;
    else
      return false;
  }

  getPrograms(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProgramDetailsUrl;
    this.programService.getAllPrograms(apiUrl).subscribe((allPrograms: programGetResp[])=>{
      this.programList = allPrograms;
    });
  }

  getCostCenter(){
    if(this.project.programId){
      const found = this.programList.find(element=> element.programId == this.project.programId);
      this.project.costCenter = found?.costCenter;
    }
  }
}

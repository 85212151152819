import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { projectSaveReq } from '../businessObjects/projectSaveReq';
import { projectSaveResp } from '../businessObjects/projectSaveResp';
import { programGetResp } from '../businessObjects/program';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
import { projectService } from '../services/project.service';
import { ProgramService } from '../services/program.service';
import { MatRadioModule} from '@angular/material/radio';
import { element } from 'protractor';
 

  declare var $: any;
  @Component({
    selector: 'app-project-save',
    templateUrl: './project-save.component.html',
    styleUrls: ['./project-save.component.css']
  })
  export class projectSaveComponent implements OnInit {
    
    public prjSReq:projectSaveReq = {
      name: '',
      description: '',
      managerId:'',
      CostCenter :'',
      ProgramID: '',
      billable: '',
      CreatedBy:0
    }
    public billable:any; 

    public prjSRes:projectSaveResp = {
      
      StatusCode:'',
      pid:0,
      errorMessage:'',
      ProjectID:'',
      Success:''
    
    }  

  public suprVRes:superVisorRes[];
  public programList :programGetResp[];
  public url:any;   
  constructor(private Pf:FormBuilder,private prjService:projectService, 
    private emplservice:EmployeeService, private programService:ProgramService){}

    public projectForm = new FormGroup({
      projectName: new FormControl('',[Validators.required]),
      description:new FormControl('',[Validators.required]),
      startDate:new FormControl('',[Validators.required]),
      managerId:new FormControl('',[Validators.required]),
      completedDt:new FormControl('',[Validators.required]),
      programID: new FormControl('',[]),
      costcenter: new FormControl('',[Validators.required]),
      billablity: new FormControl('',[Validators.required])
    });
    public envConstants = environment;
    
  ngOnInit(): void {
      $( ".hamburger" ).click();
      this.getManagers();
      this.getPrograms();
      let startDate=new Date();
      this.prjSReq.startDate=((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear());
      this.billable = true;
    }

  adjustTimezone(date: Date):Date{
    let dt = new Date(date);
    const timeZoneOffset = dt.getTimezoneOffset();
    if(timeZoneOffset >= 0)
      dt = new Date( dt.getTime() + timeZoneOffset * 60000 );
    else
      dt = new Date( dt.getTime() - timeZoneOffset * 60000 );
    return dt;
  }
    
  submit(){
      let apiUrl = this.envConstants.apiUrl + this.envConstants.saveProject;           
      this.prjSReq.managerId= parseInt(this.prjSReq.managerId);
      this.prjSReq.ProgramID = parseInt(this.prjSReq.ProgramID);
      this.prjSReq.name = this.prjSReq.name.toString().trim();
      this.prjSReq.description = this.prjSReq.description.toString().trim();
      this.prjSReq.CostCenter = this.prjSReq.CostCenter.toString().trim();
      this.prjSReq.billable = this.billable;
      let user:any=localStorage.getItem("userID");
      this.prjSReq.CreatedBy=parseInt(user);
      const stDt = new Date(this.prjSReq.startDate);
      const endDt = new Date(this.prjSReq.completedDt);
      console.log("Billable Value: ", this.billable);
      if(stDt.getDay() !== 1){
        this.showErrorMessage("Project start date can only be on a Sunday");
        return;
      }
      if(endDt.getDay() !== 0){
        this.showErrorMessage("Project end date can only be on a Saturday");
        return;
      }
      this.prjSReq.startDate = this.adjustTimezone(stDt);
      this.prjSReq.completedDt = this.adjustTimezone(endDt);
      if(this.prjSReq.startDate>this.prjSReq.completedDt){
        this.showErrorMessage("Completed date cannot be less than start date");
        return;
      }
      this.prjService.saveProject(apiUrl,this.prjSReq).subscribe((data) => {
        this.prjSReq=new projectSaveReq();
        this.prjSRes.pid = data.pid;
        this.prjSRes.errorMessage = data.errorMessage;
        if (this.prjSRes.pid>0){
          Swal.fire({
            icon: 'success',
            title: "Project has been saved sucessfully",
            showConfirmButton: false,
            timer: 3000
          });
        }
        else{
          this.showErrorMessage(this.prjSRes.errorMessage);
        }
        this.projectForm.reset();
      });
      
    }

    getManagers() {
      let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllManagerListDetails;
      this.emplservice.getManagers(apiUrl).subscribe((SuprVRes: superVisorRes[]) => {
        this.suprVRes = SuprVRes;
      });
    }

    getPrograms(){
      let apiUrl = this.envConstants.apiUrl + this.envConstants.getProgramDetailsUrl;
      this.programService.getAllPrograms(apiUrl).subscribe((allPrograms: programGetResp[])=>{
        this.programList = allPrograms;
      });
    }

    getCostCenter(){
      if(this.prjSReq.ProgramID){
        const found = this.programList.find(element=> element.programId == this.prjSReq.ProgramID);
        this.prjSReq.CostCenter = found?.costCenter;
      }
    }
  
    get projectName()
    {
      return this.projectForm.get('projectName');
    }

    get description()
    {
      return this.projectForm.get('description');
    }
    get startDate()
    {
      return this.projectForm.get('startDate');
    }
    get completedDt()
    {
      return this.projectForm.get('completedDt');
    }
    get managerId()
    {
      return this.projectForm.get('managerId');
    }
    get costcenter(){
      return this.projectForm.get('costcenter');
    }
    get billablity(){
      return this.projectForm.get('billablity');
    }
    reset()
    {
      //this.projectForm.reset();
  
    }
    showErrorMessage(message: any): void{
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000
      });     
    }

  }


<div class="container" style="max-width: 100%">
  <br /><br /><br /><br />
  <h3 class="card-header text-center font-weight-bold text-uppercase py-4">
    My dashboard
  </h3>
  <div class="row row-cols-5 ">
    <!-- <div class="d-flex justify-content-around align-items-center"> -->
      <div class="col  mx-auto ">
        <div class="card mx-auto text-white bg-secondary mb-3" style="max-width: 15rem;">
          <div class="card-header">
            My Projects
          </div>
          <div class="card-body">
            <h1 class="card-title" style="text-align: center;">{{dashBoardBoxes.totalProjects}}</h1>
          </div>
        </div>
      </div>

      <div class="col  mx-auto">
        <div class="card mx-auto text-white bg-success  mb-3" style="max-width: 15rem;">
          <div class="card-header">
            Total Hours Spent
          </div>
          <div class="card-body">
            <h1 class="card-title" style="text-align: center;">{{dashBoardBoxes.totalHoursSpent}}</h1>
          </div>
        </div>
      </div>

      <div class="col  mx-auto">
        <div class="card mx-auto text-white bg-info mb-3" style="max-width: 15rem;">
          <div class="card-header">
            Pending Requests
          </div>
          <div class="card-body">
            <h1 class="card-title" style="text-align: center;">{{dashBoardBoxes.pendingRequests}}</h1>
          </div>
        </div>
      </div>
      
      <div class="col  mx-auto">
        <div class="card mx-auto text-white bg-dark mb-3" style="max-width: 15rem;">
         
          <div class="card-header">
            Rejected Requests
          </div>
          <div class="card-body">
            <h1 class="card-title" style="text-align: center;">{{dashBoardBoxes.rejectedRequests}}</h1>
          </div>
        </div>
      </div>
      
      <div class="col  mx-auto">
        <div class="card mx-auto text-white bg-success mb-3" style="max-width: 15rem;">
          <div class="card-header">
            Approved Requests
          </div>
          <div class="card-body">
            <h1 class="card-title" style="text-align: center;">{{dashBoardBoxes.approvedRequests}}</h1>
          </div>
        </div>
      </div>

  </div>

  <div class="d-flex justify-content-around">
    <div class="col-lg-2"></div>
    <div class="col-lg-2"></div>
    <div class="col-md-1"></div>

    <div class="col-lg-2 mb-3 mb-md-0">
      <label for="startdate" class="h5 mt-md-2">Start Date</label> 
      <input
        type="date"
        id="startDate"
        [(ngModel)]="startDate"
        (ngModelChange)="showChange()"
        class="form-control"
      />
    </div>

    <div class="col-lg-2 mb-3 mb-md-0">
      <label for="endDate" class="h5 mt-md-1">End Date</label>
      <input
        type="date"
        [(ngModel)]="endDate"
        (ngModelChange)="showChange()"
        class="form-control"
      />
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastWeek()"
        checked="checked"
        type="radio"
        id="lastWeek"
        name="selectDates"
      />
      <label class="form-check-label" for="lastWeek">Last-Week</label>
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastMonth()"
        type="radio"
        id="lastMonth"
        name="selectDates"
      />
      <label class="form-check-label" for="lastMonth">Last-Month</label>
    </div>

    <div class="form-check form-check-inline h5 mt-5">
      <input
        class="form-check-input"
        (change)="showLastQuarter()"
        type="radio"
        id="lastQuarter"
        name="selectDates"
      />
      <label class="form-check-label" for="lastQuarter">Last-Quarter</label>
    </div>
    
  </div>
  <div class="row"><br /></div>
  <div class="row">
    <div class="col-md-6">
      <h1>Pending Requests(Hrs.)</h1>
      <canvas #pieCanvas style="zoom: 1.35"></canvas>
      <hr color="red" />
    </div>

    <div class="col-md-6">
      <h1>Projects Worked On(Hrs.)</h1>
      <canvas #barCanvas style="zoom: 1.3"></canvas>
      <hr color="red" />
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-md-6 tableEmp">
        <h1>Total Assigned Projects</h1>
        <div class="container table-responsive py-1">
          <table
            class="table table-bordered table-hover tablDash"
            style="padding: 0.45rem"
          >
            <thead class="thead-dark">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Project Name</th>
                <th scope="col">Date of Assignment</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let employee of employees
                    | slice: (page - 1) * pageSize:page * pageSize;
                  index as i
                "
              >
                <th scope="row">{{ (page - 1) * pageSize + i + 1 }}</th>
                <td>{{ employee.name }}</td>
                <td>{{ employee.assignedOn | date }}</td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination
            *ngIf="employees"
            [collectionSize]="collectionSize"
            [(page)]="page"
            [maxSize]="3"
            [boundaryLinks]="true"
            [pageSize]="pageSize"
            class="d-flex justify-content-center"
          ></ngb-pagination>
        </div>
        <hr color="red" />
      </div>
    </div>
  </div>
</div>

<div class="container" style="max-width: 100%">
  <br /><br /><br /><br />
  <h3 class="card-header text-center font-weight-bold text-uppercase py-4">
    Approval Request List
  </h3>
  <div class="row">
    <div class="col-md-4">
      <span class="icon-block">
        <label class="control-label" for="weekpicker1">Select Week: <span>&nbsp;</span></label>
        <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px" (click)="next(1)"
          data-mdb-ripple-color="dark">
          << 
        </button>
        <input type="text" id="weekpicker1" (focusout)="onFocusOutEvent()"
          (ngModelChange)="getDailyTimeSheetEntries()" [(ngModel)]="selectedWeek"
          class="md-form md-outline input-with-post-icon datepicker" />
        <span class="icon-date"></span>
      </span>
      <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px" (click)="next(2)"
        data-mdb-ripple-color="dark">
        >>
      </button>
      <div class="week-controls col-md-2"></div>
    </div>

    <div class="col-md-2">
      <div class="form-check-input col-md-5 offset-2">
        <select id="projectList" [(ngModel)]="projectList" class="form-control" style="width: 222px; margin-top: 14px"
          (change)="selproj($event.target)">
          <option [ngValue]="undefined" class="text-primary font-weight-bolder">
            --- Select Project ---
          </option>
          <option *ngFor="let sup of PrjList | order_by:'name'" value="{{ sup.id }}">
            {{ sup.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-2" *ngIf="projectList">
      <div class="form-check-input col-md-5 offset-2">
        <select id="empList" [(ngModel)]="selectedEmp" class="form-control" style="width: 222px; margin-top: 14px"
        (change)="selectEmployee($event.target)">
          <option [ngValue]="undefined" class="text-primary font-weight-bolder">
            --- Select Employee ---
          </option>
          <option *ngFor="let emp of EmpList | order_by:'fullName'" value="{{ emp.id }}">
            {{ emp.fullName | titlecase }}
          </option>
        </select>
      </div>
    </div>

    <!-- <div class="col-md-2"></div>
    <div class="col-md-4 text-center">
      <div class="approve-all col-md-5 offset-4" >
        <button type="button" [disabled]="!recordsExist"
                class="btn btn-success" (click)="approveAll()" style="width: 220px; margin-top: 14px">
                Approve All
        </button>
      </div>
    </div> -->
  </div>
  <div class="row" style="width: 100%">
    <table mat-table [dataSource]="dataSource" style="background: inherit" class="mat-elevation-z12" matSort
      style="width: 100%">
      <!-- Id Column -->
      <ng-container matColumnDef="empid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</th>
        <td mat-cell *matCellDef="let element">{{ element.employeeId }}</td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Emp Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.employeeName | titlecase | slice: 0:30 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
        <td mat-cell *matCellDef="let element">
          {{ element.projectName | titlecase | slice: 0:30 }}
        </td>
      </ng-container>

      <!-- Body Column -->
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef>Task</th>
        <td mat-cell *matCellDef="let element">
          {{ element.subTaskName | slice: 0:50 }}
        </td>
      </ng-container>

      <!-- UserId Column -->
      <ng-container matColumnDef="sun">
        <th mat-header-cell *matHeaderCellDef>Sun</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 6, $event)"
          [class.highlightCell]="highlight(element, 6)">
          {{ element.dayList[6] }}
        </td>
      </ng-container>

      <!-- UserId Column -->
      <ng-container matColumnDef="mon">
        <th mat-header-cell *matHeaderCellDef>Mon</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 0, $event)"
          [class.highlightCell]="highlight(element, 0)">
          {{ element.dayList[0] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="tue">
        <th mat-header-cell *matHeaderCellDef>Tue</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 1, $event)"
          [class.highlightCell]="highlight(element, 1)">
          {{ element.dayList[1] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="wed">
        <th mat-header-cell *matHeaderCellDef>Wed</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 2, $event)"
          [class.highlightCell]="highlight(element, 2)">
          {{ element.dayList[2] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="thu">
        <th mat-header-cell *matHeaderCellDef>Thu</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 3, $event)"
          [class.highlightCell]="highlight(element, 3)">
          {{ element.dayList[3] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="fri">
        <th mat-header-cell *matHeaderCellDef>Fri</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 4, $event)"
          [class.highlightCell]="highlight(element, 4)">
          {{ element.dayList[4] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="sat">
        <th mat-header-cell *matHeaderCellDef>Sat</th>
        <td mat-cell *matCellDef="let element" (mouseover)="handleMouseOver(element, 5, $event)"
          [class.highlightCell]="highlight(element, 5)">
          {{ element.dayList[5] }}
        </td>
      </ng-container>
      <!-- UserId Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef style="text-align: center"><b>Total</b></th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <b>{{
            element.dayList[0] +
            element.dayList[1] +
            element.dayList[2] +
            element.dayList[3] +
            element.dayList[4] +
            element.dayList[5] +
            element.dayList[6]
            }}</b>
        </td>
      </ng-container>
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <div style="
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <button type="button" *ngIf="recordsExist" (click)="approve(element.employeeId, element.weekId)"
              class="btn btn-success">
              Approve
            </button>
            &nbsp;
            <button type="button" *ngIf="recordsExist" (click)="reject(element.weekId)" class="btn btn-danger">
              Reject
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <br />

  <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons>
  </mat-paginator>
  <div class="above-footer"></div>
</div>

<div class="container" style="max-width: 100%;">   
   <br/><br/><br/><br/> 
    <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Approval Request List History</h3>
      <div class="row">
        <div class="col-md-4">          
          <span class="icon-block ">
            <label class="control-label" for="weekpicker">Select Week: <span>&nbsp;</span></label>
            <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px;" (click)="next(1)" data-mdb-ripple-color="dark"><<</button> <input type="text" (focusout)="onFocusOutEvent()"  id="weekpicker1" (ngModelChange)="getDailyTimeSheetEntries()"  [(ngModel)]="selectedWeek" class="md-form md-outline input-with-post-icon datepicker">
            <span class="icon-date"></span>
          </span> <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px;" (click)="next(2)" data-mdb-ripple-color="dark">>></button>
          <div class="week-controls col-md-2"></div>
        </div>
      <div class="col-md-2">          
        <div class="form-check-input col-md-5 offset-2 ">
          <select id="supervisor" class="form-control" style="width: 222px;margin-top: 14px;" (change)="changeEmp($event)">
            <option value="" class="text-primary font-weight-bolder">
              --- Select Employee ---
            </option>
            <option *ngFor="let sup of suprVRes | order_by:'fullName'" value={{sup.id}}>
              {{sup.fullName | titlecase}}
          </select>
        </div>
  </div>
     </div>
     <div class="row" style="width: 100%;">  
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z12" matSort style="width: 100%;">
    
      <!-- Title Column -->
        <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
        <td mat-cell *matCellDef="let element"> {{element.projectName | slice: 0:30}} </td>
      </ng-container>
    
      <!-- Body Column -->
      <ng-container matColumnDef="task">
        <th mat-header-cell *matHeaderCellDef> Task </th>
        <td mat-cell *matCellDef="let element"> {{element.subTaskName | slice: 0:50}} </td>
      </ng-container>
    
      <!-- UserId Column -->
      <ng-container matColumnDef="mon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[0]}} </td>
      </ng-container>

       <!-- UserId Column -->
       <ng-container matColumnDef="tue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tue </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[1]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="wed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Wed </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[2]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="thu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Thu </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[3]}} </td>
      </ng-container>
       <!-- UserId Column -->
       <ng-container matColumnDef="fri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fri </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[4]}} </td>
      </ng-container>
         <!-- UserId Column -->
         <ng-container matColumnDef="sat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sat </th>
            <td mat-cell *matCellDef="let element"> {{element.dayList[5]}} </td>
          </ng-container>
             <!-- UserId Column -->
       <ng-container matColumnDef="sun">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sun </th>
        <td mat-cell *matCellDef="let element"> {{element.dayList[6]}} </td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Total</b> </th>
        <td mat-cell *matCellDef="let element"> <b>{{element.dayList[0]+element.dayList[1]+element.dayList[2]+element.dayList[3]+element.dayList[4]+element.dayList[5]+element.dayList[6]}}</b> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.status == 3 ||  element.status == 4" [ngStyle]="{'background-color': element.status == 3 ? '#5ec45c':'#ff6e6e'}">
         <b> {{element.status==3 ? 'Approved':'Rejected'}}</b> </span>
          <span *ngIf="element.status == 1 ||  element.status == 2" [ngStyle]="{'background-color': element.status == 1 ? '#c4905c':'#c1c45c'}">
            <b>{{element.status==1 ? 'Saved':'Sent for Approvals'}} </b></span>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
     </div>
    
  <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons>
  </mat-paginator>
  </div>
export class programSaveReq
{
    programId?: number;
    name: string;
    manager: string;
    costCenter: string;
}

export class programSaveRes{
    programId?: number;
    name?: string;
    manager?: string;
    costCenter?: string;
}


export class programGetResp{
    programId: number;
    name: string;
    manager: string;
    costCenter: string;
}

<br/><br/><br/><br/> 
<h3 class="card-header text-center font-weight-bold text-uppercase py-4">Update Employee</h3>




<div class="container">
<form [formGroup]="employeeForm" (ngSubmit)="submit()" >

    <div class="form-row ">
        <div class="form-group col-md-5 ">
  
          <label for="ProjectName">Employee Id</label>
          <select id="supervisor" class="form-control"  formControlName="empList" [(ngModel)]="empId" (change)="getid()" required>
            <option value="" class="text-primary font-weight-bolder">
              --- Select Employee ---
            </option>
            <option *ngFor="let emp of empList" value={{emp.id}} >
              {{emp.fullName}}
          </select>
          <span  class="validation-message" style="color:red" *ngIf="empList &&!employeeForm.controls['empList'].valid && employeeForm.controls['empList'].touched">
            This Field is Required
          </span>
        </div>
        
  </div>
    <div class="form-row ">
      <div class="form-group col-md-5  ">

        <label for="ProjectName">Employee Name</label>
        <input type="text" class="form-control" id="fullName" placeholder="Employee Name" formControlName="fullName" readonly="true"  [(ngModel)]="empListReq.fullName" required>
       <span  class="validation-message" style="color:red" *ngIf="fullName &&!employeeForm.controls['fullName'].valid && employeeForm.controls['fullName'].touched">
        This Field is Required
      </span>
      </div>
      <div class="form-group form-group col-md-5  offset-2 ">
        <label for="description">Department</label>
        <input type="text" class="form-control" id="department" placeholder="Department"   formControlName="department" [(ngModel)]="empListReq.department" required>
        <span  class="validation-message" style="color:red" *ngIf="department &&!employeeForm.controls['department'].valid && employeeForm.controls['department'].touched">
          This Field is Required
        </span>
      </div>
</div>


   
    <div class="form-row " style="margin-top:40px">
      <div class="form-group col-md-5 " >
        <label for="supervisorId"> Supervisor </label>
        <select id="supervisorId" class="form-control"  formControlName="supervisorId" [(ngModel)]="empListReq.supervisorId" required>
          <option value="" class="text-primary font-weight-bolder">
            --- Select Supervisor ---
          </option>
          <option *ngFor="let sup of suprVRes" value={{sup.id}} >
            {{sup.fullName}}</option>
        </select>
        <span  class="validation-message" style="color:red" *ngIf="supervisorId &&!employeeForm.controls['supervisorId'].valid && employeeForm.controls['supervisorId'].touched">
          This Field is Required
        </span>
      </div>
    <div class="form-group col-md-5 offset-2"  >
        <label for="doj">DOJ</label>
        <input type="date" class="form-control" id="doj5" placeholder="YYYY/MM/DD"  formControlName="doj" [(ngModel)]="empListReq.doj" required>
        <span  class="validation-message" style="color:red" *ngIf="doj &&!employeeForm.controls['doj'].valid && employeeForm.controls['doj'].touched">
          This Field is Required
        </span>
      </div>
      
    </div>
    <div class="form-row " style="margin-top:40px">
    <div class="form-group col-md-5">
        
      <label for="userName">User Name </label>
      <input type="text" class="form-control" id="userName"   placeholder="User Name" formControlName="userName"
        [(ngModel)]="empListReq.userName" (focus)="usernameOnFocusIn()" (focusout)="userNameOnFocusOut()" readonly="true" required>
        <span  class="validation-message" style="color:red" *ngIf="userName &&!employeeForm.controls['userName'].valid && employeeForm.controls['userName'].touched">
          Input employee email address here
        </span>
        <div *ngIf="displayMsg">
          <span  class="access-message" style="color:green" >
            Please ensure that this user has access
          </span>
        </div>
    </div>
    </div>
    <div class="form-row " style="margin-top:40px">
       
       


   
      <div class="form-group col-md-5 offset-2"  >
        
      </div>

      
</div>





<div class="container" style="margin-top:30px">

<div class="row">
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>
  <div class="col-1"></div>

  <div class="col-1 ">
    <button type="submit" [disabled]="employeeForm.invalid" class="btn btn-success ">Update</button>
  </div>
  <div class="col-1 ">
    <button type="button" class="btn btn-danger " onclick="reset()">Cancel</button>
  </div>
</div>
   


  
</div>
</form>


  

</div>






       
      
    
       

    
    
 






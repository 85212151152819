import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeSaveComponent } from './employee-save/employee-save.component';
import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import { EmployeetaskhistoryComponent } from './employeetaskhistory/employeetaskhistory.component';
import { projectSaveComponent } from './project-save/project-save.component';
import { ProjectUpdateComponent } from './project-update/project-update.component';
import { SupervisordashboardComponent } from './supervisordashboard/supervisordashboard.component';
import {TimesheetEntryComponent} from './timesheet-entry/timesheet-entry.component'
import { TimesheetSupervisorComponent } from './timesheet-supervisor/timesheet-supervisor.component';
import { SelfTaskHistoryComponent } from './selftaskhistory/self-task-history.component';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { LoginsComponent } from './logins/logins.component';
import { EmployeeProjectAllocationComponent } from './employee-project-allocation/employee-project-allocation.component';
import { ProjectManagerViewComponent } from './project-manager-view/project-manager-view.component';
import { ProjectTaskSaveComponent } from './project-task-save/project-task-save.component';
import { ManagerDashboardComponent } from './manager-dashboard/manager-dashboard.component';
import { MaslGuard } from './masl.guard';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectUpdateTableComponent } from './project-update-table/project-update-table.component';
import { EmployeeUpdateTableComponent } from './employee-update-table/employee-update-table.component';
import { ProgramUpdateComponent } from './program-update/program-update.component';
const routes: Routes = [ {path: '', redirectTo:'login' , pathMatch: 'full'},
{path: 'timesheet', component: TimesheetEntryComponent, canActivate: [MaslGuard] },
{path: 'supervisorview', component: TimesheetSupervisorComponent, canActivate: [MaslGuard]},
{path: 'supervisordashboard', component: SupervisordashboardComponent, canActivate: [MaslGuard]},
{path:'managerdashboard',component:ManagerDashboardComponent, canActivate: [MaslGuard]},
{path: 'selfTaskList', component: SelfTaskHistoryComponent, canActivate: [MaslGuard]},
{path: 'projectsave', component: projectSaveComponent,canActivate: [MaslGuard]},
{path:'projectupdate', component:ProjectUpdateComponent, canActivate: [MaslGuard]},
{path:'employeesave',component:EmployeeSaveComponent, canActivate: [MaslGuard]},
{path:'employeeupdate',component:EmployeeUpdateComponent, canActivate: [MaslGuard]},
{path:'login',component:LoginsComponent},
{path:'mydashboard',component:EmployeeDashboardComponent, canActivate: [MaslGuard]},
{path:'allocation',component:EmployeeProjectAllocationComponent, canActivate: [MaslGuard]},
{path:'projectmanagerview',component:ProjectManagerViewComponent, canActivate: [MaslGuard]},
{path:'projecttaskview',component:ProjectTaskSaveComponent, canActivate: [MaslGuard]},
{path: 'employeetasklist', component: EmployeetaskhistoryComponent,canActivate: [MaslGuard]},
{path: 'projectList', component:ProjectListComponent,canActivate: [MaslGuard]},
{path: 'projectUpdateList' , component:ProjectUpdateTableComponent,canActivate: [MaslGuard]},
{path: 'employeeUpdateList' , component:EmployeeUpdateTableComponent,canActivate: [MaslGuard]},
{path: 'programUpdate' , component:ProgramUpdateComponent,canActivate: [MaslGuard]},
{path: '**', redirectTo:'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true}),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const RouteComponents = [
  TimesheetEntryComponent,EmployeetaskhistoryComponent,projectSaveComponent,ProjectUpdateComponent,EmployeeSaveComponent,EmployeeUpdateComponent,
  ProjectManagerViewComponent,ProjectListComponent,EmployeeUpdateTableComponent, ProgramUpdateComponent
];


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { employeeUpdateRes } from '../businessObjects/employeeUpdateRes';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { EmployeeService } from '../services/employee.service';
declare var $: any;
@Component({
  selector: 'app-employee-update',
  templateUrl: './employee-update.component.html',
  styleUrls: ['./employee-update.component.css']
})
export class EmployeeUpdateComponent implements OnInit {

  constructor(private Pf: FormBuilder, private emplservice: EmployeeService) { }
  public empListReq: saveEmployeeRq = {
    fullName: '',
    createdBy: '',
    doj: '',
    department: '',
    supervisorId: '',
    userName:'',
    departmentId:''
  }
  emplUpdateRes: employeeUpdateRes ={
    id: ''
  }
  
  displayMsg: boolean;


  public employeeForm = new FormGroup({
    empList: new FormControl('',[Validators.required]),
    fullName:new FormControl('',[Validators.required]),
    department:new FormControl('',[Validators.required]),
    doj:new FormControl('',[Validators.required]),
    supervisorId:new FormControl('',[Validators.required]),
    userName:new FormControl('',[
      Validators.email,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      this.emailDomainValidator
    ])
  });
  public empList: superVisorRes[];
  public empId: any;
  public url: any;
  public suprVRes: superVisorRes[];
  envConstants = environment;

  ngOnInit(): void {
    this.getEmployees();
    this.getSupervisors();
    $( ".hamburger" ).click();

  }
  getid() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getEmployeeById;
    this.emplservice.getEmployeeListById(apiUrl, this.employeeForm.controls['empList'].value).subscribe((EmpListReq: saveEmployeeRq) => {

    this.empListReq = EmpListReq;
    this.empListReq.supervisorId=EmpListReq.supervisorID;
    this.empListReq.role=EmpListReq.role;
    let firstDate= new Date(EmpListReq.doj);

    let startMonth=(firstDate.getMonth()+1).toString(),
    startDay=firstDate.getDate().toString()
   if(((firstDate.getMonth().toString()).length)==1)
   {
     startMonth="0"+(firstDate.getMonth()+1).toString()
   }
   if(((firstDate.getDate().toString()).length)==1)
   {
     startDay="0"+(firstDate.getDate()).toString()
   }
   this.empListReq.doj=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
   
    }
    );
  }

  submit() {
    this.updateEmployee();
  }
  updateEmployee() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.updateEmployeeDetailsUrl;
    this.empListReq.supervisorId = parseInt(this.empListReq.supervisorId)
    this.empListReq.role=this.empListReq.role;
    let createdBy:any;
    createdBy=localStorage.getItem("userID")?.toString();
    this.empListReq.createdBy=parseInt(createdBy);
    this.emplservice.updateEmployee(apiUrl, this.empId, this.empListReq).subscribe((EmplUpdateRes: employeeUpdateRes) => {
    this.emplUpdateRes = EmplUpdateRes;
    if(this.empListReq.doj)
    {
      this.empListReq.doj=this.empListReq.doj.toString().replace('/','-');
    }
    this.empListReq=new saveEmployeeRq();
      Swal.fire({
        icon: 'success',
        title: 'Employee has been updated successfully',
        showConfirmButton: false,
        timer: 1500
      })
      this.reset();
    }
    );

  }

  getEmployees() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;
    this.emplservice.getManagers(apiUrl).subscribe((EmpList: superVisorRes[]) => {
    this.empList = EmpList;
    }
    );

  }

  getSupervisors() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getAllSuperVisorListDetails;
    this.emplservice.getSupervisors(apiUrl).subscribe((SuprVRes: superVisorRes[]) => {
      this.suprVRes = SuprVRes;
    });
  }


  userNameOnFocusOut(){
    this.displayMsg = true;
  }

  usernameOnFocusIn(){
    this.displayMsg = false;
  }

  emailDomainValidator(control: FormControl) {
    let email = control.value;
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if (domain !== "purecs.com") {
        return {
          emailDomain: {
            parsedDomain: domain
          }
        }
      }
    }
    return null;
  }

  get fullName()
  {
    return this.employeeForm.get('fullName');
  }

  get department()
  {
    return this.employeeForm.get('department');
  }
  get supervisorId()
  {
    return this.employeeForm.get('supervisorId');
  }
  get doj()
  {
    return this.employeeForm.get('doj');
  }
  get userName()
  {
    return this.employeeForm.get('userName');
  }
  get role()
  {
    return this.employeeForm.get('role');
  }
  reset()
  {
    this.employeeForm.reset();

  }
}

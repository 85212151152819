import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { environment } from 'src/environments/environment';
import { supDashboardValuesReponse } from '../businessObjects/dashBoardValues';
import { Employees, PieChart, supervisorDashboardValuesReponse } from '../businessObjects/supervisorDashboardResponse';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { constants } from '../common/constants';
import { EmployeeService } from '../services/employee.service';
import { getTimeSheetEntryRequest, getTimesheetEntriesSupervisorReq } from '../businessObjects/getTimeSheetEntryRequest';
import { SupervisorActionService } from '../services/supervisorAction.service';
import { TimeEntryService } from '../services/timeentry.service';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { employeesInfo } from '../businessObjects/employeeInfo';
declare var $: any;
@Component({
  selector: 'app-supervisordashboard',
  templateUrl: './supervisordashboard.component.html',
  styleUrls: ['./supervisordashboard.component.css']
})
export class SupervisordashboardComponent  {
  @ViewChild('pieCanvas') private pieCanvas: ElementRef;
  @ViewChild('barCanvas') private barCanvas: ElementRef;
  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  doughnutChart: any;
  pieChart: any;
  barChart: any;
  public startDate2:any;
  public endDate2:any;
  public weekStDate :any;
  public weekEndDate :any;
  public envConstants= environment;
  public appConstants = constants;
  public pageSize:any= 8;
  public pSize:any= 8;
  public pg:any = 1;
  public page:any= 1;
  collSize: number;
  collectionSize: number;
  public dashBoardVals:supDashboardValuesReponse;
  public employees:Employees[];
  public employeesProject:Employees[];
  public suprVRes: superVisorRes[];
  public employeeList:any;
  constructor(private supervisorActionService: SupervisorActionService,private emplservice: EmployeeService, private timeEntryService:TimeEntryService) { }
  public getTimeSheetRes: getTimeSheetEntryResponse[];
  public dashBoardBoxes:supervisorDashboardValuesReponse={
    totalEmployees:0,
    approvedRequests:0,
    id:0,
    pendingRequests:0,
    rejectedRequests:0,
    totalHoursSpent:0,
    totalProjects:0
  }

  public empList :employeesInfo[] = [];
  public empPendingTimesheetList :employeesInfo[] = [];
  
  public getAllTimesheetReq :getTimesheetEntriesSupervisorReq={
    employeeID: 0,
    startDate: '',
    endDate: '',
    status:0,
    supervisorID:0,
    manangerID:0
  };
  public employeePendingTimesheet: Employees[] = [];
  ngAfterViewInit(): void {
    
  }
  ngOnInit(): void {
    this.setDatesForOneWeekAgo();
    this.getEmployees();
    this.employeeList=-1;
    this.getData();
    $( ".hamburger" ).click();
  }
  getEmployees() {
    
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getEmployeeBySupervisorId;
    let supervisorID=localStorage.getItem("userID");
    this.emplservice.getSuperVisorList(apiUrl,supervisorID)
          .subscribe((SuprVRes: superVisorRes[]) => {
            this.suprVRes = SuprVRes;      
     });
    //this.setDefaultWeek();
  }
  getCurrentDate(): string {
    var date = new Date();
    let currentDate = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) +"-"+("0" + date.getDate()).slice(-2);
    return currentDate;
  }

  setDatesForOneWeekAgo(){
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    if(stDate.getDay() != 1){
      for(let i = stDate.getDay(); i != 1 ; i--){
        stDate.setDate(stDate.getDate() - 1);
      }
    }
    let enDt = new Date(stDate);
    enDt.setDate(enDt.getDate() + 6);
    let prevWeekStDate = stDate.getFullYear() + "-" + ("0"+(stDate.getMonth() + 1)).slice(-2) +"-"+("0" + (stDate.getDate())).slice(-2);
    let prevWeekEndDate = enDt.getFullYear() + "-" + ("0"+(enDt.getMonth() + 1)).slice(-2) +"-"+("0" + (enDt.getDate())).slice(-2);
    this.startDate2 = prevWeekStDate;
    this.endDate2 = prevWeekEndDate;
  }

  setDatesForLastQuarter(){
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    let lastQuarterStartDate = startFullQuarter.getFullYear() + "-" + ("0"+(startFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + startFullQuarter.getDate()).slice(-2);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    let lastQuarterEndDate = endFullQuarter.getFullYear() + "-" + ("0"+(endFullQuarter.getMonth()+1)).slice(-2) +"-"+("0" + endFullQuarter.getDate()).slice(-2);
    this.startDate2 = lastQuarterStartDate;
    this.endDate2 = lastQuarterEndDate;
  }

  setDatesForLastMonth(){
    let date = new Date();
    let prevMonthStDate = date.getFullYear() + "-" + ("0"+(date.getMonth())).slice(-2) +"-"+("0" + 1).slice(-2);
    const prevMonthEndDt = new Date(date.getFullYear(), date.getMonth(), 0);
    let prevMonthEndDate = prevMonthEndDt.getFullYear() + "-" + ("0"+(prevMonthEndDt.getMonth() + 1)).slice(-2) +"-"+("0" + prevMonthEndDt.getDate()).slice(-2);
    this.startDate2 = prevMonthStDate;
    this.endDate2 = prevMonthEndDate;
  }

  showLastMonth(){
    this.setDatesForLastMonth();
    this.showChange();
  }

  showLastWeek(){
    this.setDatesForOneWeekAgo();
    this.showChange();
  }

  showLastQuarter(){
    this.setDatesForLastQuarter();
    this.showChange();
  }

  public showChange()
  {
    this.getData();
  }
  selectEmployee(filterVal: any) {
    this.employeeList=filterVal.value;
    this.getData();
  }
  public getData()
  {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getSupervisorDashboard;
    let userId=localStorage.getItem("userID");
    this.supervisorActionService.getSupervisorDashboardValues(apiUrl,userId,-1,this.startDate2,this.endDate2,this.employeeList)
        .subscribe((supervsorValues: supDashboardValuesReponse) => {
          this.dashBoardBoxes.approvedRequests=supervsorValues.supervisorDashboard.approvedRequests;
          this.dashBoardBoxes.pendingRequests=supervsorValues.supervisorDashboard.pendingRequests;
          this.dashBoardBoxes.rejectedRequests=supervsorValues.supervisorDashboard.rejectedRequests;
          this.dashBoardBoxes.totalEmployees=supervsorValues.supervisorDashboard.totalEmployees;
          this.dashBoardBoxes.totalHoursSpent=supervsorValues.supervisorDashboard.totalHoursSpent;
          this.dashBoardBoxes.totalProjects=supervsorValues.supervisorDashboard.totalProjects;
          let object:any[]=supervsorValues.pieChart;
          let objectValues:any[]=[];
          let objectParams:any[]=[];
          
          object.forEach(element => {
            objectValues.push(element.pieCount);
            objectParams.push(element.pieFullName);
          });
          if(this.pieChart)
          {
            this.pieChart.destroy();
            
          } 
          this.pieChartBrowser(objectValues,objectParams);
          object=[];objectValues=[];objectParams=[];
          object=supervsorValues.donoughtChart;
          if(object!=undefined)
          {
          object.forEach(element => {
            objectValues.push(element.projectName);
            objectParams.push(element.projectHours);
          });
         }
         if(this.doughnutChart)
         {
           this.doughnutChart.destroy();
           
         } 
          this.doughnutChartMethod(objectValues,objectParams);

          object=[];objectValues=[];objectParams=[];
          object=supervsorValues.barChart;
          if(object!=undefined)
          object.forEach(element => {
            objectValues.push(element.name);
            objectParams.push(element.totalHoursSpent);
          });

          if(this.barChart)
          {
            this.barChart.destroy();
            
          }
          this.barChartMethod(objectValues,objectParams);
          
          this.employees=supervsorValues.employees;
          this.collectionSize=this.employees.length;
          this.employeesProject=supervsorValues.employeesProject;
          this.pendingTimesheetSubmitions();
    });
  }

  pieChartBrowser(dataArray:any[],valueArray:any[]): void {
    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
      options: {
        tooltips: {
           mode: 'index',
           intersect: false
        },
        hover: {
           mode: 'index',
           intersect: false
        }
     },
      data: {
        labels:valueArray ,
        datasets: [{
          backgroundColor: [
            '#2ecc71',
            '#3498db',
            '#95a5a6',
            '#9b59b6',
            '#f1c40f',
            '#e74c3c'
          ],
          fill: false,
          lineTension: 0.1,
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataArray
        }]
      }
    });
  }

  doughnutChartMethod(dataArray:any[],valueArray:any[]) {
    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      options: {
        tooltips: {
           mode: 'index',
           intersect: false
        },
        hover: {
           mode: 'index',
           intersect: false
        }
     },
      data: {
        labels: dataArray,
        datasets: [{
          label: '# of Hours',
          data: valueArray,
          backgroundColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(211, 101, 55, 1)',
            'rgba(101, 101, 55, 1)',
            'rgba(55, 101, 55, 1)',
            'rgba(28, 33, 55, 1)',
            'rgba(89, 43, 4, 1)',
            'rgba(1, 45, 33, 1)',
            'rgba(56, 3, 44, 1)',
          ],
          hoverBackgroundColor: [
            'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
             'rgba(225,225,225,1)',
          ]
        }]
      }
    });
  }
  
  barChartMethod(dataArray:any[],valueArray:any[]) {
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: dataArray,
        datasets: [{
          label: '# of Hours',
          data: valueArray,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(211, 101, 55, 0.2)',
            'rgba(101, 101, 55, 0.2)',
            'rgba(55, 101, 55, 0.2)',
            'rgba(28, 33, 55, 0.2)',
            'rgba(89, 43, 4, 0.2)',
            'rgba(1, 45, 33, 0.2)',
            'rgba(56, 3, 44, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest',
       },
       hover: {
          mode: 'index',
          intersect: false
       }
      }
    });
  }
  
  gettimesheetforAllEmployees(dateSt :any, dateEnd:any){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntriesSupervisorUrl;
    this.getAllTimesheetReq.startDate=dateSt;
    this.getAllTimesheetReq.endDate = dateEnd;
    this.getAllTimesheetReq.supervisorID = localStorage.getItem("userID");
    return this.timeEntryService.getTimesheetEntriesSupervisor(apiUrl,this.getAllTimesheetReq);
  }

  pendingTimesheetSubmitions(){
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    if(stDate.getDay() != 1){
      for(let i = stDate.getDay(); i != 1 ; i--){
        stDate.setDate(stDate.getDate() - 1);
      }
    }
    let enDt = new Date(stDate);
    enDt.setDate(enDt.getDate() + 6);
    let prevWeekStDate = stDate.getFullYear() + "-" + ("0"+(stDate.getMonth() + 1)).slice(-2) +"-"+("0" + (stDate.getDate())).slice(-2);
    let prevWeekEndDate = enDt.getFullYear() + "-" + ("0"+(enDt.getMonth() + 1)).slice(-2) +"-"+("0" + (enDt.getDate())).slice(-2);

    this.gettimesheetforAllEmployees(prevWeekStDate, prevWeekEndDate).subscribe((timesheetResp: getTimeSheetEntryResponse[])=>{
      if(timesheetResp.length){
        this.getTimeSheetRes = timesheetResp;
        this.employeePendingTimesheet.splice(0,this.employeePendingTimesheet.length);
        const emps = this.employees.filter(element=> element.name );
        emps.forEach(element=>{
          let isTimesheetPresent = false;
          this.getTimeSheetRes.forEach(timeentry=>{
            if(element.fullName == timeentry.employeeName && element.name == timeentry.projectName && (timeentry.status == 2 || timeentry.status == 3)){
              isTimesheetPresent = true;
            }
          });
          if(!isTimesheetPresent){
            this.employeePendingTimesheet.push(element);
          }
        });
      }
      else{
        const emps = this.employees.filter(element => element.name);
        const copyEmp = JSON.parse(JSON.stringify(emps));
        this.employeePendingTimesheet = copyEmp;
      }
      this.empList = this.getCommaSeperatedProjects(this.employees, this.empList);
      this.empPendingTimesheetList = this.getCommaSeperatedProjects(this.employeePendingTimesheet, this.empPendingTimesheetList);
      this.collectionSize = this.empList.length;
      this.collSize = this.empPendingTimesheetList.length;
    });
  }

  getCommaSeperatedProjects(emp :Employees[], commaList :employeesInfo[]){
    commaList = [];
    //#region add unique employees and assigned projects...
    for (let index = 0; index < emp.length; index++) {
      const element = emp[index];

      //check if empl already exist? if yes, add projct name only
      let isempExist: any = commaList.filter((value:any, key:any)=>{
        return value.userName.toString().toLowerCase().trim() === element.userName.toString().toLowerCase().trim()
      });
      if(isempExist.length > 0){
        if(!isempExist[0].description){
          isempExist[0].description = element.name;
        }
        else{
          let desc = isempExist[0].description +', '+ element.name;
          isempExist[0].description = desc;
        }
      }
      else{
        let obj = {
          fullName: element.fullName,
          userName: element.userName,
          description: element.name
        }
        commaList.push(obj);   
      }
    }
    return commaList;
  }
}
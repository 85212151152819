import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class SharedService {

  onLogin: Subject<any> = new Subject<any>();

  constructor() { }
}

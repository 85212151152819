<header>
  <div class="fixed-header1" >
  <div *ngIf="loaderService.isLoading | async;" class="loader-container_line">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <ng-template #notLoading>Not Loading!</ng-template>
  </div>
  <nav class="navbar navbar-dark bg-primary">
      
      <!--
      <div class="container">
        <div class="col-md-2" style="left: -10px;">         
          <a class="" onclick="location.reload();" ><img style="width:170px;margin-left:-247px;" src="http://purecs.ae/wp-content/uploads/2017/11/PCS-Logo-White.png" 
            alt="Pure Health" title="Pure Health"></a>         
      </div>
    -->
    <!-- <div class="container"> -->
    <div class="navbar-nav mr-5" >         
      <a class="navbar-brand" onclick="" ><img style="width:185px;" src="../assets/images/PCS-Logo-White.png" 
          alt="Pure Health" title="Pure Health"></a>         
    </div>

    <div class = "navbar-nav mr-auto" id="wrapper" *ngIf="true">
      <div class="overlay"  id="overlay"></div>
         
      <!-- Sidebar -->
      <nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top" id="sidebar-wrapper" role="navigation">
        <ul class="nav sidebar-nav">
          <div class="sidebar-header">
            <div class="sidebar-brand">
              <a href="#/timesheet">Daily Time Sheet</a>
            </div>
          </div>
          <li><a href="#/mydashboard"style="font-weight:500;font-size: 17px;"><i class="bi bi-speedometer"></i>  Dashboard</a></li>
          <li><a href="#/timesheet"style="font-weight:500;font-size: 17px;"><i class="bi bi-calendar-week"></i>  Timesheet</a></li>
          <li class="dropdown" *ngIf="isSupervisor">
            <a href="#works" class="dropdown-toggle"  data-toggle="dropdown" style="font-weight:500;font-size: 17px;"><i class="bi bi-diagram-3"></i>  Supervisor View <span class="caret"></span></a>
            <ul class="dropdown-menu animated fadeInLeft" role="menu">
              <div class="dropdown-header"></div>
              <li><a href="#/supervisordashboard" style="font-weight:250;font-size: 14px;"><i class="bi bi-speedometer2"></i> Dashboard</a></li>
              <li><a href="#/employeetasklist" style="font-weight:250;font-size: 14px;"><i class="bi bi-list-task"></i>  Task History</a></li>
            </ul>
          </li>
          <li class="dropdown" *ngIf="isManager">
            <a href="#works" class="dropdown-toggle"  data-toggle="dropdown" style="font-weight:500;font-size: 17px;"><i class="bi bi-briefcase"></i>  Manager View <span class="caret"></span></a>
            <ul class="dropdown-menu animated fadeInLeft" role="menu">
             <div class="dropdown-header"></div>
             <li><a href="#/managerdashboard" style="font-weight:250;font-size: 14px;"><i class="bi bi-speedometer2"></i>  Dashboard</a></li>
             <li><a href="#/projectmanagerview" style="font-weight:250;font-size: 14px;"><i class="bi bi-calendar2-check-fill"></i>  Approvals</a></li>
             <li><a href="#/allocation" style="font-weight:250;font-size: 14px;"><i class="bi bi-file-earmark-person-fill"></i>  Employee Allocation</a></li>
            </ul>
          </li>
          <li class="dropdown" *ngIf="isAdmin">
            <a href="#Admin" class="dropdown-toggle"  data-toggle="dropdown"style="font-weight:500;font-size: 17px;"><i class="bi bi-wrench"></i>  Admin View <span class="caret"></span></a>
            <ul class="dropdown-menu animated fadeInLeft" role="menu">
              <div class="dropdown-header"></div>
              <li><a href="#/employeesave" style="font-weight:250;font-size: 14px;"><i class="bi bi-person-plus-fill"></i>  Add Employee</a></li>
              <!-- <li><a href="#/employeeupdate" style="font-weight:250;font-size: 14px;">Update Employee</a></li> -->
              <li><a href="#/employeeUpdateList" style="font-weight:250;font-size: 14px;"><i class="bi bi-person-check-fill"></i>  Update Employee</a></li>
              <li><a href="#/projectsave" style="font-weight:250;font-size: 14px;"><i class="bi bi-folder-plus"></i> Add Project</a></li>
              <li><a href="#/projectUpdateList" style="font-weight:250;font-size: 14px;"><i class="bi bi-folder-check"></i>  Update Project</a></li>
              <!-- <li><a href="#/allocation" style="font-weight:250;font-size: 14px;"><i class="bi bi-file-earmark-person-fill"></i>  Employee Allocation</a></li> -->
              <li><a href="#/projecttaskview" style="font-weight:250;font-size: 14px;"><i class="bi bi-journal"></i>  Create Task</a></li>
              <li><a href="#/programUpdate" style="font-weight:250;font-size: 14px;"><i class="bi bi-file-ruled-fill"></i>  Program</a></li>
           </ul>
          </li>
          <li><a (click)="logout()" href="#"style="font-weight:500;font-size: 17px;"><i class="bi bi-box-arrow-left"></i>  Logout</a></li>
        </ul>
      </nav>
             <!-- /#sidebar-wrapper -->
     
             <!-- Page Content -->
      <div id="page-content-wrapper">
        <button type="button" class="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
          <span class="hamb-top" ></span>
          <span class="hamb-middle"></span>
          <span class="hamb-bottom"></span>
        </button>
      </div>
    </div>


    <div class="navbar-text ml-auto" >
      <div class="LoggedInUsername mb-0"  style="margin-right: 2em; text-align: center;">
        <span>
          <i class="bi bi-person-fill fa-2x"></i>&nbsp;
        </span>
        <span class="text-center" style="text-align: center; font-size: 16px; font-family: 'Gill Sans', sans-serif;"> 
          {{userName | titlecase}}
        </span>      
        <!-- <label *ngIf="userName" style="max-width: 18em;">Logged in as: &nbsp; <i>{{userName}}</i> </label>-->
      </div>
    </div>
      
    <!-- </div> -->
  </nav>
  </div>  
</header>  
 
<main class="my-3">
  <div class="container-fluid">
    <div *ngIf="loaderService.isLoading | async;" class="loader-container">
        <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
      <mat-spinner style="top:50% ;left:50%;"></mat-spinner>
    </div>
    <router-outlet></router-outlet>
  </div>
</main>

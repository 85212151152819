<h2 mat-dialog-title>Update Employee</h2>

<mat-dialog-content >
    <form [formGroup]="employeeUpdateForm">
        <div>
            <mat-form-field appearance="fill" >
                <mat-label>Employee Id</mat-label>
                <input matInput formControlName="employeeId" [(ngModel)]="employee.id" readonly="true" required> 
            </mat-form-field>
            
            <mat-form-field appearance="fill" class="user-name">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username" [(ngModel)]="employee.userName" readonly="true" required> 
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Employee Name</mat-label>
                <input matInput formControlName="employeeName" [(ngModel)]="employee.fullName" (ngModelChange)="employee.fullName = $event.toLowerCase()" required> 
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Department</mat-label>
                <mat-select formControlName="department" [(ngModel)]="employee.departmentID" required>
                    <mat-option *ngFor="let dep of allDepartments" [value]="dep.departmentId">
                        {{ dep.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="supervisor-field">
                <mat-label>Supervisor</mat-label>
                <mat-select formControlName="supervisor" [(ngModel)]="employee.supervisorId" required>
                    <mat-option *ngFor="let sup of supervisors" [value]="sup.id">
                        {{sup.fullName | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="doj-field">
                <mat-label>DOJ</mat-label>
                <input matInput formControlName="DOJ" [matDatepicker]="doj" [(ngModel)]="employee.doj" required>
                <mat-datepicker-toggle matSuffix [for]="doj"></mat-datepicker-toggle>
                <mat-datepicker #doj [startAt]="employee.doj"></mat-datepicker>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<div>
    <mat-dialog-actions align="end">
        <button type="button" class="mat-raised-button"(click)="close()">Cancel</button>
        <button type="submit" class="mat-raised-button mat-primary" [disabled]="employeeUpdateForm.invalid" (click)="update()"style=" margin-left: 2em">Update</button>
    </mat-dialog-actions>
</div>

import { Component, OnInit,ViewChild  } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { TimeEntryService } from '../services/timeentry.service';
import Swal from 'sweetalert2';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { environment } from "../../environments/environment";
import { Constants, constants } from "../common/constants";
declare var $: any;
export interface Post {
  id: number;
  title: string;
  body: string;
  userId: number;
}
@Component({
  selector: 'app-self-task-history',
  templateUrl: './self-task-history.component.html',
  styleUrls: ['./self-task-history.component.css']
})

export class SelfTaskHistoryComponent implements OnInit {

  public startDate:any;
  displayedColumns: string[] = ['project','task','mon','tue','wed','thu','fri','sat','sun','status'];
  public envConstants= environment;
  public appConstants = constants;
  public selectedWeek:any;
   public dateStart:any;
   public dateEnd:any;
  dataSource = new MatTableDataSource<Post>([]);
  public getTimeSheetRes: getTimeSheetEntryResponse[];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  constructor(private http: HttpClient,private timeEntryService:TimeEntryService) { }
  recordsExist: boolean;

  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID:'',
    endDate:'',
    startDate:''
  }
  fetchPosts(): void {
    this.http.get('https://jsonplaceholder.typicode.com/posts').subscribe((data) => {
      this.dataSource.data = data as Post[];
    })

}

ngOnInit(): void {
  $( ".hamburger" ).click();
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  this.getDailyTimeSheetEntries()

  var startDate,
  endDate;
  $('#weekpicker3').datepicker({
    autoclose: true,
    format :'mm/dd/yyyy',
    forceParse :false
}).on("changeDate", (e:any) => {
    var date = e.date;
    startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+6);
    $('#weekpicker3').datepicker('update', startDate);
    $('#weekpicker3').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
    this.recordsExist=false;
    this.getDailyTimeSheetEntries();
    
});

  
}
ngViewAfterInit()
{
 var startDate,
 endDate;
 
$('#weekpicker3').datepicker({
 autoclose: true,
 format :'mm/dd/yyyy',
 forceParse :false
}).on("changeDate", function(e:any) {
 var date = e.date;
 startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
 endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()+6);
 $('#weekpicker3').datepicker('update', startDate);
 $('#weekpicker3').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' +  startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' +  endDate.getFullYear());
 
});

}

getDateValue()
{
  this.selectedWeek=$('#weekpicker3').val();
  var startDt:'';
  var endDt:'';
  if(this.selectedWeek)
  {
    const chars = this.selectedWeek.split('-');
    startDt=chars[0];
    let firstDate = new Date(startDt)
    endDt=chars[1];
    let secondDate = new Date(endDt)
    let startMonth=(firstDate.getMonth()+1).toString(),endMonth=(secondDate.getMonth()+1).toString()
    ,startDay=firstDate.getDate().toString(),EndDay=secondDate.getDate().toString()
    if(((firstDate.getMonth().toString()).length)==1)
    {
      startMonth="0"+(firstDate.getMonth()+1).toString()
    }
    if(((secondDate.getMonth().toString()).length)==1)
    {
      endMonth="0"+(secondDate.getMonth()+1).toString()
    }
    if(startDay.length==1)
    {
      startDay="0"+(firstDate.getDate()+1).toString()
    }
    if(EndDay.length==1)
    {
      EndDay="0"+(secondDate.getDate()+1).toString()
    }
    this.dateStart=firstDate.getFullYear()+'-'+ startMonth+'-'+startDay;
    this.dateEnd=secondDate.getFullYear()+'-'+endMonth+'-'+EndDay;
  }
}
getDailyTimeSheetEntries() {
  this.getDateValue();
  let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
  let errorMessages = this.appConstants.errorMessages;
  this.getTimeSheetReq.employeeID=94;
  this.getTimeSheetReq.startDate=this.dateStart;
  this.getTimeSheetReq.endDate=this.dateEnd;
  console.log(`Calling the ${apiUrl} api...`);
  //api call
  this.timeEntryService.getTimeSheetEntries(apiUrl,this.getTimeSheetReq)
  .subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
  this.getTimeSheetRes=(getTimeSheetReponse);

});
}


applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}

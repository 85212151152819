import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { getTimeSheetEntryRequest } from '../businessObjects/getTimeSheetEntryRequest';
import { getTimeSheetEntryResponse } from '../businessObjects/getTimeSheetEntryResponse';
import { constants } from '../common/constants';
import Swal from 'sweetalert2';
import { SupervisorActionService } from '../services/supervisorAction.service';
import { TimeEntryService } from '../services/timeentry.service';
import { supervisorActionRequest } from '../businessObjects/supervisorActionRequest';
import { supervisorActionResponse } from '../businessObjects/supervisorActionResponse';
import { EmployeeService } from '../services/employee.service';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { projectList } from '../businessObjects/projectList';
import { projectService } from '../services/project.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
export interface Post {
  id: number;
  title: string;
  body: string;
  userId: number;
}


@Component({
  selector: 'app-project-manager-view',
  templateUrl: './project-manager-view.component.html',
  styleUrls: ['./project-manager-view.component.css']
})
export class ProjectManagerViewComponent implements OnInit {

  PrjList: projectList[];
  prjListAll: projectList[];
  public EmpList: superVisorRes[];
  displayedColumns: string[] = ['empid', 'name', 'project', 'task', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'total', 'actions'];
  dataSource: any;
  public envConstants = environment;
  public appConstants = constants;
  public getTimeSheetRes: getTimeSheetEntryResponse[];
  public getTimeSheetResAll: getTimeSheetEntryResponse[];
  public selectedWeek: any;
  public dateStart: any;
  public toastRef: any;
  public dateEnd: any;
  public recordsExist: boolean;
  public projectList: any;
  public selectedEmp:any;
  public eventhover: any;
  public eventid: any;
  public currentProjectFilter :any;
  public currentEmployeeFilter :any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private http: HttpClient, private supervisorActionService: SupervisorActionService, private timeEntryService: TimeEntryService, private emplservice: EmployeeService, private projListService: projectService, private toastr: ToastrService) { }
  ngOnInit(): void {
    $(".hamburger").click();
    var startDate,
      endDate;
    var startDt: '';
    var endDt: '';

    this.fetchPosts();
    this.setDefaultWeek();

    this.getProjectIdList();
    this.getTimeSheetRes = [

    ];

    var startDate,
      endDate;
    $('#weekpicker1').datepicker({
      autoclose: true,
      format: 'mm/dd/yyyy',
      forceParse: false
    }).on("changeDate", (e: any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear());
      this.recordsExist = false;

      this.getDailyTimeSheetEntries();

    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  setDefaultWeek() {
    let dateToday = new Date();
    let startDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - (dateToday.getDay()-1));
    let endDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() - dateToday.getDay() + 7);
    $('#weekpicker1').datepicker('update', startDate);
    $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear());
    this.getDailyTimeSheetEntries();

  }
  ngViewAfterInit(): void {

    var startDate,
      endDate;
    $('#weekpicker1').datepicker({
      autoclose: true,
      format: 'mm/dd/yyyy',
      forceParse: false
    }).on("changeDate", (e: any) => {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
      $('#weekpicker1').datepicker('update', startDate);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    });
    //test to intialize subtassks

  }
  public approveOrRejectRes: supervisorActionResponse =
    {
      errorCode: '',
      isSuccess: false,
      message: ''
    }
  public approveOrRejectReq: supervisorActionRequest =
    {
      employeeID: 0,
      endDate: '',
      startDate: '',
      remarks: '',
      actionStatus: 0
    }
  public getTimeSheetReq: getTimeSheetEntryRequest = {
    employeeID: '',
    endDate: '',
    startDate: '',
    status: '',
    manangerID: ''
  }

  public getTimeEntryObject: getTimeSheetEntryResponse = {
    employeeId: 0,
    endDate: '',
    taskName: '',
    id: 0,
    message: '',
    projectId: 0,
    remarks: '',
    errorCode: '',
    isSuccess: false,
    startDate: '',
    status: 0,
    subTaskId: 0,
    tasks: 0,
    employeeName: '',
    projectName: '',
    dayList: [],
    remarksList: [],
    costCenter: ''
  }

  fetchPosts(): void {
    this.http.get('https://jsonplaceholder.typicode.com/posts').subscribe((data) => {
      //this.dataSource.data = data as Post[];
    })

    var startDate,
      endDate;

    $('#weekpicker').datepicker({
      autoclose: true,
      format: 'mm/dd/yyyy',
      forceParse: false
    }).on("changeDate", function (e: any) {
      var date = e.date;
      startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()-1));
      endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
      $('#weekpicker').datepicker('update', startDate);
      $('#weekpicker').val((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear());

    });



    //new

  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getDailyTimeSheetEntries();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  next(val: any) {
    if (val == 1) {
      let startDate = new Date(this.dateStart);
      let endDate = new Date(this.dateEnd);
      startDate.setDate(startDate.getDate() - 7);
      endDate.setDate(endDate.getDate() - 7);
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' + endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    }
    if (val == 2) {
      let startDate = new Date(this.dateStart);
      let endDate = new Date(this.dateEnd);
      startDate.setDate(startDate.getDate() + 7);
      endDate.setDate(endDate.getDate() + 7); 
      $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' + endDate.getFullYear());
      this.getDailyTimeSheetEntries();
    }

  }

  handleMouseOver(event: any, day: number, e: any) {
    if (!(this.eventhover == event && this.eventid == day)) {
      if (this.toastRef) {
        this.toastr.clear(this.toastRef.ToastId);
      }
      let value: any = event.remarksList[day];
      if (value) {
        this.toastRef = this.toastr.info(value, ' Comments', { timeOut: 2000 });
        $("#toast-container").css({ position: "absolute", top: '17%', right: '29%' });
      }
    }
    this.eventhover = event;
    this.eventid = day;
  }
  getCordinates() {

  }
  onChangeProject(event: any, id: number) {
    const value = event.target.value;

  }
  getDailyTimeSheetEntries() {
    this.getDateValue();
    this.dataSource = this.getTimeSheetRes = [];
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getTimeSheetEntries;
    let errorMessages = this.appConstants.errorMessages;
    this.getTimeSheetReq.employeeID = -1;
    this.getTimeSheetReq.startDate = this.dateStart;
    this.getTimeSheetReq.endDate = this.dateEnd;
    this.getTimeSheetReq.status = constants.status.SentforApproval;
    this.getTimeSheetReq.manangerID = localStorage.getItem("userID");
    this.recordsExist = false;
    console.log(`Calling the ${apiUrl} api...`);
    //api call
    this.timeEntryService.getTimeSheetEntries(apiUrl, this.getTimeSheetReq)
      .subscribe((getTimeSheetReponse: getTimeSheetEntryResponse[]) => {
        this.getTimeSheetRes = (getTimeSheetReponse);
        if (this.getTimeSheetRes.length > 0) {
          this.getTimeSheetRes.forEach(element =>{
            if(element.status == constants.status.SentforApproval)
              this.recordsExist = true;
          });
          this.dataSource = new MatTableDataSource(this.getTimeSheetRes);
          this.getTimeSheetResAll = this.getTimeSheetRes;

          this.dataSource = new MatTableDataSource(this.getTimeSheetRes = this.getTimeSheetResAll.filter(function (list) {
            return list.status == constants.status.SentforApproval;

          }));
          this.selectProject(this.currentProjectFilter);
          this.dataSource.paginator = this.paginator;
          this.selectEmployee(this.currentEmployeeFilter);
        }
        else{
          this.recordsExist = false;
        }
      });
  }

  getDateValue() {
    this.selectedWeek = $('#weekpicker1').val();
    var startDt: '';
    var endDt: '';
    if (this.selectedWeek) {
      const chars = this.selectedWeek.split('-');
      startDt = chars[0];
      let firstDate = new Date(startDt)
      endDt = chars[1];
      let secondDate = new Date(endDt)
      let startMonth = (firstDate.getMonth() + 1).toString(), endMonth = (secondDate.getMonth() + 1).toString(),
      startDay = firstDate.getDate().toString(), EndDay = secondDate.getDate().toString();

      if ((startMonth.length) == 1) {
        startMonth = "0" + startMonth;
      }
      if ((endMonth.length) == 1) {
        endMonth = "0" + endMonth;
      }
      if (startDay.length == 1) {
        startDay = "0" + startDay;
      }
      if (EndDay.length == 1) {
        EndDay = "0" + EndDay;
      }
      this.dateStart = firstDate.getFullYear() + '-' + startMonth + '-' + startDay;
      this.dateEnd = secondDate.getFullYear() + '-' + endMonth + '-' + EndDay;
    }
  }

  getProjectIdList() {
    let apiUrl = this.envConstants.apiUrl + this.envConstants.getProjectByManager;
    let managerID = localStorage.getItem("userID");
    this.projListService.getProjetListByManager(apiUrl, managerID).subscribe((prjList: projectList[]) => {
      this.PrjList = prjList;
      this.prjListAll = prjList;
    });
  }

  getEmpIdList(){
    let apiUrl = this.envConstants.apiUrl + this.envConstants.GetAllEmployeesUrl;
    let projectId = parseInt(this.projectList);
    this.emplservice.getEmployeeListByProjectId(apiUrl, projectId).subscribe((data:superVisorRes[])=>{
      this.EmpList=data;
    });
  }

  approve(taskId: any, weeekId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to approve this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let apiName = this.envConstants.approveOrRejectTE;
        let apiUrl = this.envConstants.apiUrl + apiName;
        this.approveOrRejectReq.employeeID = localStorage.getItem("userID");;
        this.approveOrRejectReq.actionStatus = constants.status.Approved;
        this.approveOrRejectReq.weekId = weeekId;
        let errorMessages = this.appConstants.errorMessages;
        console.log(`Calling the ${apiUrl} api... with payload: ${this.approveOrRejectReq} `);

        //api call
        this.supervisorActionService.updateSupervisorAction(apiUrl, this.approveOrRejectReq)
          .subscribe((supervisorActionResponse) => {
            if (supervisorActionResponse.isSuccess) {
              Swal.fire({
                icon: 'success',
                title: 'Time sheet has been approved successfully',
                showConfirmButton: false,
                timer: 1500
              })
              this.getDailyTimeSheetEntries();
            }

          },
            (error) => {

              console.error("An error has occurred in approve timesheet api call: ", error);
              alert(error.error.errorDesc);
              return
            });

      }
    })

  }

  approveAll(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do You Want To Approve All Requests!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!'
    }).then((result) => {
      console.log(this.getTimeSheetRes);
      this.getTimeSheetRes.forEach(element =>{
        //this.approve(element.employeeId, element.weekId);
        console.log(element.employeeId,element.weekId);
      });
    });
  }

  onFocusOutEvent() {
    let startDate = new Date(this.dateStart);
    let endDate = new Date(this.dateEnd);
    $('#weekpicker1').val((startDate.getMonth() + 1) + '/' + (startDate.getDate()) + '/' + startDate.getFullYear() + ' - ' + (endDate.getMonth() + 1) + '/' + (endDate.getDate()) + '/' + endDate.getFullYear());

  }
  async reject(weekID: any) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Rejection Comments *',
      inputPlaceholder: 'Type your comments here...',
      preConfirm: (value) => {
        if (value == '') {
          Swal.showValidationMessage(`Please enter the remarks`)
        }
      },
      inputAttributes: {
        'aria-label': 'Type your comments here'
      },
      showCancelButton: true
    })

    if (text) {
      let apiName = this.envConstants.approveOrRejectTE;
      let apiUrl = this.envConstants.apiUrl + apiName;
      this.approveOrRejectReq.employeeID = localStorage.getItem("userID");;
      this.approveOrRejectReq.weekId = weekID;
      this.approveOrRejectReq.remarks = text;
      this.approveOrRejectReq.actionStatus = constants.status.Rejected;
      let errorMessages = this.appConstants.errorMessages;
      console.log(`Calling the ${apiUrl} api... with payload: ${this.approveOrRejectReq} `);

      //api call
      this.supervisorActionService.updateSupervisorAction(apiUrl, this.approveOrRejectReq)
        .subscribe((supervisorActionResponse) => {
          if (supervisorActionResponse.isSuccess) {
            Swal.fire({
              icon: 'success',
              title: 'Time sheet has been rejected sucessfully',
              showConfirmButton: false,
              timer: 1500
            })
            this.getDailyTimeSheetEntries();
          }
        },
          (error) => {

            console.error("An error has occurred in rejection timesheet api call: ", error);
            alert(error.error.errorDesc);
            return
          });
    }
  }

  selproj(filtVal :any){
    this.selectedEmp = undefined;
    this.currentEmployeeFilter = undefined;
    this.selectProject(filtVal);
    this.getEmpIdList();
  }
  selectProject(filterVal: any) {
    this.currentProjectFilter = filterVal;
    if(this.getTimeSheetResAll){
      this.dataSource = this.getTimeSheetRes = this.getTimeSheetResAll.filter(function (list) {
        return list.status == constants.status.SentforApproval;
      });
      if (filterVal.value > 0) {
        this.dataSource = this.getTimeSheetRes = this.getTimeSheetRes.filter(function (list) {
          return list.projectId == filterVal.value && list.status == constants.status.SentforApproval
        });
      }
    }
  }

  selectEmployee(filterVal: any){
    this.selectedEmp = filterVal.value;
    this.currentEmployeeFilter = filterVal;
    if(filterVal.value > 0){
      this.dataSource = this.getTimeSheetRes;
      this.dataSource = this.getTimeSheetRes.filter(list=>{
        return list.employeeId == this.currentEmployeeFilter.value;
      });
    }
  }

  highlight(event: any, day: number): boolean {
    let value: any = event.remarksList[day]
    if (value)
      return true;
    else
      return false;
  }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { employeeAllocationReq } from '../businessObjects/employeeAllocationReq';
import { employeeAllocationRes } from '../businessObjects/employeeAllocationResp';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAllocationService {

  constructor(private httpClient: HttpClient) { }

  saveEmployeeAllocation(employeeAllocationSaveUrl: string,
    empAllocationReq: employeeAllocationReq): Observable<employeeAllocationRes> {

    return this.httpClient.put<employeeAllocationRes>(employeeAllocationSaveUrl,
      empAllocationReq)


    }

}

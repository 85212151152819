import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { employeeUpdateRes } from '../businessObjects/employeeUpdateRes';
import { saveEmployeeRq } from '../businessObjects/saveEmployeeRq';
import { saveEmployeeRs } from '../businessObjects/saveEmployeeRs';
import { superVisorRes } from '../businessObjects/superVisorRes';
import { validateUserRes } from '../businessObjects/validateUserRes';
import { getDepartment } from '../businessObjects/getAllDepartments'


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private httpClient: HttpClient) { }

  updateEmployee(employeetUpdateUrl: string, eId: any,
    empUpdateReq: saveEmployeeRq): Observable<employeeUpdateRes> {

    return this.httpClient.put<employeeUpdateRes>(employeetUpdateUrl + eId,
      empUpdateReq)
  }

  getEmployeeListById(EmployeeGetUrl: string, eId: any): Observable<saveEmployeeRq> {
    return this.httpClient.get<saveEmployeeRq>(EmployeeGetUrl + eId)

  }

  getEmployeeRoles(getRoleUrl: string): Observable<saveEmployeeRq[]> {

    return this.httpClient.get<saveEmployeeRq[]>(getRoleUrl)
  }

  getAllEmployeesList(getEmployeeUrl: string): Observable<saveEmployeeRq[]> {

    return this.httpClient.get<saveEmployeeRq[]>(getEmployeeUrl)
  }

  getSuperVisorList(getEmployeeUrl: string,supervisorId:any): Observable<superVisorRes[]> {
    let httpParams = new HttpParams()
    .set('supr_Id', supervisorId);
    return this.httpClient.get<superVisorRes[]>(getEmployeeUrl+supervisorId)
  }

  getManagers(getManagerUrl: string): Observable<superVisorRes[]> {

    return this.httpClient.get<superVisorRes[]>(getManagerUrl)
  }
  getSupervisors(getSupervisorUrl: string): Observable<superVisorRes[]> {

    return this.httpClient.get<superVisorRes[]>(getSupervisorUrl)
  }


  saveEmployee(employeeSaveUrl: string,
    empSaveReq: saveEmployeeRq): Observable<saveEmployeeRs> {

    return this.httpClient.post<saveEmployeeRs>(employeeSaveUrl,
      empSaveReq)



  }
  getEmployeeListByProjectId(EmployeeGetUrl: string, pId: any): Observable<superVisorRes[]> {
    return this.httpClient.get<superVisorRes[]>(EmployeeGetUrl+"/?Prj_Id=" + pId)

  }
  userExists(EmployeeGetUrl: string, userName: any): Observable<validateUserRes> {
    return this.httpClient.get<validateUserRes>(EmployeeGetUrl +'?'+ userName)

  }

  getAllDepartments(deptGetUrl: string): Observable<getDepartment[]>{
    return this.httpClient.get<getDepartment[]>(deptGetUrl);
  }
  
}






import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { programSaveReq, programSaveRes, programGetResp } from '../businessObjects/program';

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    constructor(private httpClient: HttpClient) { }
    
    updateProgram(employeetUpdateUrl :string, pId :number, programUpdateReq :programSaveReq): Observable<programSaveRes>{

        return this.httpClient.put<programSaveRes>(employeetUpdateUrl + pId, programUpdateReq);
    }

    getProgram(programGetUrl :string, pId :number): Observable<programGetResp[]>{

        return this.httpClient.get<programGetResp[]>(programGetUrl + pId);
    }

    saveProgram(programSaveUrl :string, progSaveReq :programSaveReq) :Observable<programSaveRes>{

        return this.httpClient.post<programSaveRes>(programSaveUrl,progSaveReq);
    }

    getAllPrograms(programGetUrl :string) :Observable<programGetResp[]>{

        return this.httpClient.get<programGetResp[]>(programGetUrl + -1);
    }

}
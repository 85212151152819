<!-- Editable table -->
<div class="card" style="zoom: 0.92;">
  <br/><br/><br/><br/> 
  <h3 class="card-header text-center font-weight-bold text-uppercase py-4">Weekly Time Sheet</h3>
  <div class="card-body">
    <div id="table" class="table-editable">
      <span class="table-add float-right mb-12 mr-12" *ngIf="!isDisabled">
        <a class="text-success" [class.disabled]="false" (click)="add()">
          <mdb-icon fas icon="plus"  size="3x"></mdb-icon>
        </a>
      </span>  
      <div class="col-md-4">          
        <span class="icon-block ">
          <label class="control-label" for="weekpicker">Select Week: <span>&nbsp;</span></label>
          <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px;" (click)="next(1)" data-mdb-ripple-color="dark"><<</button> <input type="text"  id="weekpicker" (focusout)="onFocusOutEvent()" (ngModelChange)="getDailyTimeSheetEntries()"  [(ngModel)]="selectedWeek" class="md-form md-outline input-with-post-icon datepicker">
          <span class="icon-date"></span>
        </span> <button type="button" class="btn btn-outline-primary btn-rounded" style="height: 31px;" (click)="next(2)" data-mdb-ripple-color="dark">>></button>
        <div class="week-controls col-md-2"></div>
      </div>
      <table class="table table-bordered table-responsive-md table-striped text-center">
        <tr>
          <th class="text-center">Project</th>
          <th class="text-center">Task</th>
          <th class="text-center">Monday</th>
          <th class="text-center">Tuesday</th>
          <th class="text-center">Wednesday</th>
          <th class="text-center">Thursday</th>
          <th class="text-center">Friday</th>
          <th class="text-center">Saturday</th>
          <th class="text-center">Sunday</th>
          <th class="text-center">Total</th>
          <th class="text-center">Status</th>
          <th class="text-center" *ngIf="showManagerComments">Manager Comments</th>
          <th class="text-center">Action</th>
        </tr>
        <tr *ngFor="let timeEntry of timeEntryList; let id = index;">
          <td>
            <select class="form-control" (change)='onChangeProject($event,id)' *ngIf="(timeEntryList[id].status!=3 && !timeEntryList[id].isRemovedFromProject)" [(ngModel)]="timeEntryList[id].projectId" >        
              <option class="language-select" [disabled]="timeEntryList[id].isLocked || !isProjectEditable[id]" *ngFor='let item of projects' [value]="item.project_Id" >{{item.project_Name}}</option>    
            </select> 
            <input type="text" class="form-control" *ngIf="(timeEntryList[id].status==3) || timeEntryList[id].isRemovedFromProject" [value]="timeEntry.projectName" readonly>
          </td>
          <td>   
              <select class="form-control"  (change)='onChangeTask($event,id)' *ngIf="(timeEntryList[id].status!=3 && !timeEntryList[id].isRemovedFromProject)" [(ngModel)]="timeEntryList[id].subTaskId"  name="langChange" >        
                <option class="language-select" [disabled]="timeEntryList[id].isLocked || !isProjectEditable[id]" *ngFor='let item of timeEntryList[id].tasks' [value]="item.id"> {{item.name}}</option>    
              </select>   
              <input type="text" class="form-control" *ngIf="(timeEntryList[id].status==3 && !timeEntryList[id].isRemovedFromProject) || timeEntryList[id].isRemovedFromProject" [value]="timeEntry.subTaskname" readonly> 
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber1" [ngStyle]="{'background-color': getBoolVal(id,0) ? 'Cornsilk' : null}" (contextmenu)="onRightClick($event,id,1)" [disabled]="timeEntryList[id].isLocked"  [(ngModel)]="timeEntryList[id].days.mon" (change)='onChangeValue("mon")'   class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber2" [ngStyle]="{'background-color': getBoolVal(id,1) ? 'Cornsilk' : null}"  (contextmenu)="onRightClick($event,id,2)" [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.tue"  (change)='onChangeValue("tue")'  class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber3" [ngStyle]="{'background-color': getBoolVal(id,2) ? 'Cornsilk' : null}" (contextmenu)="onRightClick($event,id,3)" [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.wed"  (change)='onChangeValue("wed")'   class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber4" [ngStyle]="{'background-color': getBoolVal(id,3) ? 'Cornsilk' : null}" (contextmenu)="onRightClick($event,id,4)"  [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.thu"  (change)='onChangeValue("thu")' class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber5" [ngStyle]="{'background-color': getBoolVal(id,4) ? 'Cornsilk' : null}" (contextmenu)="onRightClick($event,id,5)" [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.fri"  (change)='onChangeValue("fri")'  class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber6" [ngStyle]="{'background-color': getBoolVal(id,5) ? 'Cornsilk' : null}" (contextmenu)="onRightClick($event,id,6)" [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.sat"  (change)='onChangeValue("sat")'  class="form-control number" />
          </td>
          <td>
            <input type="number" onkeypress="return /[0-9]/i.test(event.key)" min="0" id="typeNumber7" [ngStyle]="{'background-color': getBoolVal(id,6) ? 'Cornsilk' : null}"  (contextmenu)="onRightClick($event,id,7)" [disabled]="timeEntryList[id].isLocked" [(ngModel)]="timeEntryList[id].days.sun" (change)='onChangeValue("sun")'  class="form-control number" />        
          </td>
          <td>
            <input type="number"  id="typeNumber8" value="0"  disabled="true" [(ngModel)]="totalDaysHoriz[id]"  class="form-control number" />
          </td>
          <td>
            <input type="text" disabled="true" style="width:'120px';"  [(ngModel)]="timeEntryList[id].statusName" name="txtStatus" class="form-control"/>
          </td>
          <td *ngIf="showManagerComments">
            <textarea id="txtRemarks" [readonly]="true"  style="border:ridge;" name="txtRemarks" rows="3" cols="30" [(ngModel)]="timeEntryList[id].remarks"></textarea>
          </td>
          <td>
            <div class="row justify-content-center">
              <div class="col-12 col-md-4">  
                <a class="text-danger" [class.disabled]="timeEntryList[id].isLocked || timeEntryList[id].rowStatus ? true: false" (click)="remove(id,timeEntryList[id].weekId)">
                  <button type="button"   [disabled]="timeEntryList[id].isLocked || timeEntryList[id].rowStatus " class="btn btn-danger"><i class="fas fa-trash"></i></button>
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>  
          <td>
            
          </td>
          <td>     
            <span contenteditable="true">Total:</span>
          </td>
          <td>
            <input type="number" id="typeNumber"  value="0" [(ngModel)]="totalMon" disabled="true" class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber"  value="0" [(ngModel)]="totalTue"  disabled="true" class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber" value="0" [(ngModel)]="totalWed" disabled="true"  class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber" value="0" [(ngModel)]="totalThu"  disabled="true"  class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber" value="0" [(ngModel)]="totalFri"  disabled="true"  class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber"  value="0" [(ngModel)]="totalSat" disabled="true" class="form-control number" />
          </td>
          <td>
            <input type="number" id="typeNumber" value="0"  [(ngModel)]="totalSun"  disabled="true"  class="form-control number" />        
          </td>
          <td>
            <input type="number" id="typeNumber"  value="0" [(ngModel)]="totalNumVal"  disabled="true"  class="form-control number"  />
          </td>
          <td></td>
          <td *ngIf="showManagerComments"></td>
          <td>
            <div class="container">
              <div class="row justify-content-center">
            <div class="col-md-12">
              <form>
                <a class="text-success"  >
                  <button type="button" [disabled]="isDisabled" [hidden]="isGet" (click)="saveDailyEntry(1)"  class="btn btn-success" style="margin:5px;"><i class="fas fa-save"></i></button>          
                  <button type="button" [hidden]="!isGet || !showSave" (click)="updateDailyEntry(1)"  class="btn btn-success" style="margin:5px;">Save</button> &nbsp;
                  <button type="button" [disabled]="!showRecall"  [hidden]="!isGet || !showRecall" (click)="recallDailyEntry(1)"  class="btn btn-success" style="margin:5px;">Recall</button> &nbsp;
                  <button type="button" [hidden]="!isGet || !showSend" (click)="sendDailyEntry(2)" class="btn btn-warning" style="margin:5px;">Submit</button>
                </a>
              </form>
           </div>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>

  <div id="dose2-findModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Comments Section </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group form-group col-md-5  offset-2 ">
            <textarea class="form-control"  [(ngModel)]="commentsDone" rows="3" cols="30" style="width: 353px;border:ridge;" id="department" placeholder="Mention your comments here"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="updateComments()" class="btn btn-primary" data-dismiss="modal">Update</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="display-text"> 
  <div class="h6 ml-4">
    <div><span style="color: red;">*</span> You can fill maximum 8 hours a day.</div>
  </div> 
  <div class="h6 ml-4"> 
    <div><span style="color: red;">*</span> You can add a comment by right clicking on the timesheet entry.</div> 
  </div>
  <div class="h6 ml-4"> 
    <div><span style="color: red;">*</span> You can add multiple projects including Admin tasks like Leaves and Trainings by clicking <b>+</b>.</div> 
  </div>
</div>